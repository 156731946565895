<template>
  <div class="w-full h-40px shrink-0 flex items-center gap-2 overflow-x-auto">
    <div
      :class="[
        'whitespace-nowrap px-2 py-2px text-15px font-semibold rounded-full cursor-pointer',
        {
          'bg-ld-brand-100 text-ld-brand-500': props.selectedTagKey == null,
        },
      ]"
      @click="emit('select', undefined)"
    >
      {{ _t('package.tag_all') }}
    </div>

    <div
      v-for="tag in props.tags"
      :key="tag.tag.key"
      :class="[
        'whitespace-nowrap px-2 py-2px text-15px font-semibold rounded-full cursor-pointer',
        {
          'bg-ld-brand-100 text-ld-brand-500':
            props.selectedTagKey === tag.tag.key,
        },
      ]"
      @click="emit('select', tag.tag.key)"
    >
      {{ tag.tag.name }}
    </div>
  </div>
</template>
<script lang="ts" setup>
import type { ContentTagWithParent } from '@/api/package-source'

const props = defineProps<{
  selectedTagKey?: string
  tags: ContentTagWithParent[]
}>()

const emit = defineEmits<{
  select: [tagKey?: string]
}>()
</script>
<style scoped></style>
