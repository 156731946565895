<template>
  <div class="h-full flex flex-col px-4 bg-ld-background g-safe-area">
    <RatioSpacedContainer class="flex-1 g-body-width">
      <div
        class="text-25px mb-60px flex items-center justify-center color-[var(--yellow-500)] font-bold space-x-4"
      >
        <Icon
          name="wheat-left"
          class="w-16px"
        />
        <span>{{ _t('learn.checkin_msg5') }}</span>
        <Icon
          name="wheat-right"
          class="w-16px"
        />
      </div>

      <div class="flex flex-col w-full gap-4 mt-11 mb-4 overflow-y-auto">
        <Button
          v-for="(item, index) of TARGETS"
          :key="index"
          :scene="selectedTarget === index ? 'choiceSelected' : 'choice'"
          class="w-full"
          @click="selectedTarget = index"
        >
          <div class="flex-1 flex items-center justify-between">
            <span class="font-semibold">{{ item.title }}</span>
            <span>{{ item.label }}</span>
          </div>
        </Button>
      </div>
    </RatioSpacedContainer>

    <div class="g-footer-width mb-4">
      <div class="flex items-end mb-52px">
        <Img
          name="ld-look"
          class="g-flip-x w-77px mr-2 shrink-0"
        />

        <div
          v-if="selectedTarget == null"
          ref="chatBox"
          class="chat empty"
          @animationend="onChatBoxEmptyAnimationEnd"
        ></div>

        <div
          v-else
          ref="chatBox"
          class="chat target"
          @animationend="onChatBoxTargetAnimationEnd"
        ></div>
      </div>

      <Button
        :class="['w-full']"
        :disabled="selectedTarget == null"
        :label="_t('learn.checkin_msg6')"
        @click="onNext"
      ></Button>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref } from 'vue'
import Typewriter from 'typewriter-effect/dist/core'
import { FeedbackType, postFeedback } from '@/api/user'

const emit = defineEmits<{
  next: []
}>()

const TARGETS = [
  {
    title: _t('learn.checkin_target_title', { day: 7 }),
    label: _t('learn.checkin_target_label1'),
  },
  {
    title: _t('learn.checkin_target_title', { day: 14 }),
    label: _t('learn.checkin_target_label2'),
  },
  {
    title: _t('learn.checkin_target_title', { day: 30 }),
    label: _t('learn.checkin_target_label3'),
  },
  {
    title: _t('learn.checkin_target_title', { day: 60 }),
    label: _t('learn.checkin_target_label4'),
  },
]

const selectedTarget = ref<number>()
const chatBox = ref<HTMLDivElement>()

function onChatBoxEmptyAnimationEnd() {
  const typewriter = new Typewriter(chatBox.value, {
    delay: 15,
    cursor: '',
  })

  typewriter.typeString(_t('learn.checkin_msg7')).pauseFor(200).start()
}

function onChatBoxTargetAnimationEnd() {
  const typewriter = new Typewriter(chatBox.value, {
    delay: 15,
    cursor: '',
  })

  typewriter.typeString(_t('learn.checkin_msg8')).pauseFor(200).start()
}

function onNext() {
  if (selectedTarget.value == null) return

  const target = TARGETS[selectedTarget.value]!
  postFeedback({
    type: FeedbackType.CheckInTarget,
    content: `${target.title} ${target.label}`,
  })
  emit('next')
}
</script>
<style scoped>
.chat {
  background-color: white;
  border-radius: 18px;
  padding: 12px 16px;
  font-size: 17px;
  line-height: 22px;
  height: fit-content;
  animation-fill-mode: forwards;
  white-space: nowrap;
}

.chat.empty {
  animation: chatScaleEmpty 0.15s;
}

.chat.target {
  animation: chatScaleTarget 0.15s;
}

@keyframes chatScaleEmpty {
  0% {
    width: 0px;
    height: 0px;
  }

  100% {
    width: 220px;
  }
}

@keyframes chatScaleTarget {
  0% {
    width: 0px;
    height: 0px;
  }

  100% {
    width: 260px;
  }
}
</style>
