<template>
  <SafeAreaTopSpacer v-if="_global.isMobileMode" />

  <AppBar
    title="商店"
    @back="emit('done')"
  >
    <template #back>
      <Icon
        name="close"
        class="w-20px"
      ></Icon>
    </template>

    <template #actions>
      <DiamondCount />
    </template>
  </AppBar>

  <Loading
    v-if="loading"
    class="w-full h-full"
  />

  <div
    v-else
    class="px-4 pb-4 mt-4 flex flex-col gap-6"
  >
    <div class="vip-box">
      <div class="relative py-3">
        <Icon
          name="vip-medal"
          class="w-122px absolute top-0 right-0"
        />

        <div
          class="text-25px leading-[1em] font-semibold mb-15px text-ld-premium-800"
        >
          {{ _t('vip.name') }}
        </div>

        <div
          class="text-17px leading-[1.4] font-semibold text-ld-premium-800 flex flex-col gap-1"
        >
          <div class="flex items-center gap-1">
            <Icon
              name="vip-check"
              class="w-20px text-ld-premium-500"
            />
            {{ _t('vip.tip1') }}
          </div>
          <div class="flex items-center gap-1">
            <Icon
              name="vip-check"
              class="w-20px text-ld-premium-500"
            />
            {{
              _t('energy.increase_limit_count', {
                count: config?.vipPrivilegeResponse.addEnergyLimit,
              })
            }}
          </div>
          <div class="flex items-center gap-1">
            <Icon
              name="vip-check"
              class="w-20px text-ld-premium-500"
            />
            {{
              _t(
                `每天 ${config?.vipPrivilegeResponse.freeEnergyPerDay} 次免费购买面包`
              )
            }}
          </div>
          <div class="flex items-center gap-1">
            <Icon
              name="vip-check"
              class="w-20px text-ld-premium-500"
            />
            {{ _t('vip.tip2') }}
            <Tooltip trigger="click">
              <div class="text-17px">
                {{ _t('vip.tip3') }}
              </div>
              <div class="text-17px">
                {{ _t('vip.tip4') }}
              </div>
              <template #trigger>
                <Icon
                  name="action-help"
                  class="w-20px shrink-0 text-ld-label-secondary cursor-pointer"
                />
              </template>
            </Tooltip>
          </div>
          <div class="flex items-center gap-1">
            <Icon
              name="vip-check"
              class="w-20px text-ld-premium-500"
            />
            {{ _t('vip.tip5') }}
          </div>
        </div>
      </div>

      <div
        v-if="store.isVipValid"
        class="flex items-center"
      >
        <div
          class="flex flex-col text-17px leading-[1.4] text-ld-label-secondary font-semibold"
        >
          <span>{{ _t('vip.valid_tip2') }}</span>
          <span class="nowrap">
            {{
              _t('vip.expire_time', {
                date: $f.date(store.vip!.expireAt, 'YYYY-MM-DD'),
              })
            }}
          </span>
        </div>

        <Button
          class="ml-4 flex-1 overflow-hidden"
          scene="vip"
          :label="_t('vip.renew')"
          @click="onVipBuy"
        ></Button>
      </div>

      <Button
        v-else
        class="w-full overflow-hidden"
        scene="vip"
        :label="
          _t('vip.buy_tip2', {
            currency: $f.currency(defaultVip!.currency),
            price: fenToYuan(defaultVip!.pricePerMonth),
          })
        "
        @click="onVipBuy"
      ></Button>
    </div>

    <div class="flex flex-col gap-4">
      <div
        v-if="!isIOS"
        class="text-21px leading-[1em] font-semibold"
      >
        {{ _t('diamond.get2') }}
      </div>

      <div class="flex flex-col gap-3">
        <div
          v-if="store.showAlphaGift"
          class="diamond-card-box relative"
        >
          <div
            class="text-25px leading-[1em] font-semibold text-ld-brand-600 mb-15px"
          >
            {{ _t('diamond.alpha_gift') }}
          </div>

          <div
            class="text-17px leading-[1.4] font-semibold text-ld-brand-500 flex flex-col gap-1"
          >
            <div class="absolute top-2 right-4">
              <Img
                name="ld-look"
                class="w-97px"
              />
            </div>

            <div class="flex items-center gap-1">
              <Icon
                name="diamond"
                class="w-20px"
              />

              {{ _t('diamond.get_immediately', { count: alphaGiftDiamond }) }}
            </div>
            <div class="flex items-center gap-1">
              <Icon
                name="diamond"
                class="w-20px"
              />
              {{ _t('diamond.alpha_gift_tip') }}
            </div>

            <DotBadge class="mt-15px">
              <Button
                class="w-full"
                scene="secondary"
                @click="onClaimAlphaGift"
              >
                <div
                  class="text-center flex-1 flex text-17px text-ld-brand-600 justify-center items-center font-600"
                >
                  {{
                    _global.isInsideApp
                      ? _t('diamond.alpha_gift_tip2')
                      : _t('diamond.alpha_gift_tip3')
                  }}

                  <Icon
                    name="diamond"
                    class="w-20px"
                  />
                  x{{ alphaGiftDiamond }}
                </div>
              </Button>
            </DotBadge>
          </div>
        </div>

        <div class="diamond-card-box relative">
          <Icon
            name="diamonds"
            class="w-97px absolute top-2 right-4"
          />

          <div
            class="text-25px leading-[1em] font-semibold text-ld-brand-600 mb-15px"
          >
            {{ _t('diamond.card_name') }}
          </div>

          <div
            class="text-17px leading-[1.4] font-semibold text-ld-brand-500 flex flex-col gap-1"
          >
            <div class="flex items-center gap-1">
              <Icon
                name="diamond"
                class="w-20px"
              />
              {{
                _t('diamond.card_tip6', {
                  count: config?.diamondCard?.immediateDiamonds,
                })
              }}
            </div>
            <div class="flex items-center gap-1">
              <Icon
                name="diamond"
                class="w-20px"
              />
              {{
                _t('diamond.card_tip7', {
                  count: config?.diamondCard?.diamondsPerDay,
                })
              }}
            </div>
          </div>

          <Button
            v-if="store.diamondCard == null"
            class="w-full mt-15px"
            :loading="diamondCardBuyLoading"
            @click="onDiamondCardBuy"
          >
            <div class="text-center flex-1 text-17px font-semibold">
              <span
                v-if="diamondCardOriginalPrice > 0"
                class="text-gray line-through mr-1"
              >
                {{
                  _t(
                    `${$f.currency(
                      diamondCardCurrency
                    )}${diamondCardOriginalPrice}`
                  )
                }}
              </span>
              {{
                _t(
                  `${$f.currency(diamondCardCurrency)}${diamondCardPrice} 购买`
                )
              }}
            </div>
          </Button>

          <Button
            v-else-if="store.diamondCard.receivedToday"
            class="w-full mt-15px"
            scene="secondary"
            :disabled="true"
          >
            <div class="text-center flex-1 text-17px text-ld-brand-500">
              {{ _t('diamond.card_tip1') }}
            </div>
          </Button>

          <DotBadge
            v-else
            class="mt-15px"
          >
            <Button
              class="w-full"
              scene="secondary"
              :loading="isDiamondCardClaimingToday"
              @click="onDiamondCardReceiveDaily"
            >
              <div
                class="flex items-center justify-center flex-1 text-17px font-semibold text-ld-brand-500"
              >
                {{ _t('diamond.card_tip2') }}
                <Icon
                  name="clean-diamond"
                  class="w-20px"
                />
                <span>x{{ store.diamondCard.diamondPerDay }}</span>
              </div>
            </Button>
          </DotBadge>
        </div>

        <div
          v-if="config && !isIOS"
          class="grid grid-cols-3 gap-3 text-ld-brand-500"
        >
          <div
            v-for="item in config.diamondList"
            :key="item.id"
            class="diamond-sku"
            :loading="diamondBuyLoading[item.id]"
            @click="onDiamondBuy(item)"
          >
            <Icon
              name="clean-diamond"
              class="w-52px"
            />

            <div
              class="text-17px leading-[1em] font-semibold mt-1 mb-3 whitespace-nowrap"
            >
              {{ item.amount }} {{ _t('钻石') }}
            </div>

            <Button
              class="w-full"
              size="small"
            >
              <div class="text-center flex-1 font-semibold whitespace-nowrap">
                {{ `${$f.currency(item.currency)} ${fenToYuan(item.price)}` }}
              </div>
            </Button>
          </div>
        </div>
      </div>

      <div class="text-ld-label-secondary text-12px leading-normal">
        <span class="font-semibold">
          {{ _t('diamond.card_note') }}
        </span>
        <ul class="m-0 p-l-4">
          <li v-if="isIOS">
            {{ _t('diamond.card_tip3') }}
          </li>
          <template v-else>
            <li>
              {{ _t('diamond.card_tip4') }}
            </li>
            <li>
              {{ _t('diamond.card_tip5') }}
            </li>
          </template>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import AppBar from '@/mobile/components/AppBar.vue'
import VipSelector from './VipSelector.vue'
import { useCommonStore } from '@/stores'
import { computed, onUnmounted, ref } from 'vue'
import {
  type StoreConfig,
  type DiamondSku,
  receiveDiamondCardDailyDiamonds,
} from '@/api/product'
import { fenToYuan, warn } from '@/utils'
import { RewardType } from '@/api/task'
import DotBadge from '@/components/DotBadge.vue'
import {
  OrderStatus,
  createDiamondCardOrder,
  createDiamondOrder,
} from '@/api/order'
import { payByEnv } from '@/shared/pay'
import DiamondCount from '@/components/DiamondCount.vue'
import { claimAlphaGift } from '@/api/user'
import { Platform } from '@/utils/bridge'
import BindEmailForm from '@/pages/AccountSetting/BindEmailForm.vue'
import { useRouter } from 'vue-router'

const props = withDefaults(
  defineProps<{
    exitAfterVipBought?: boolean
  }>(),
  {
    exitAfterVipBought: false,
  }
)

const emit = defineEmits<{
  done: []
  vipBought: []
  alphaGiftClaimed: []
}>()

const store = useCommonStore()
const router = useRouter()

const config = ref<StoreConfig>()
const loading = ref(true)
const diamondCardBuyLoading = ref(false)

const defaultVip = computed(() => {
  if (config.value == null) return null
  const vip = config.value.vipList.find(item => item.defaultSelected)

  return vip ?? config.value.vipList[0]
})

const diamondCardPrice = computed(() => {
  if (config.value == null) return 0
  return fenToYuan(config.value.diamondCard.price)
})

const diamondCardOriginalPrice = computed(() => {
  if (config.value == null) return 0
  return fenToYuan(config.value.diamondCard.originalPrice ?? 0)
})

const diamondCardCurrency = computed(() => {
  if (config.value == null) return 'CNY'

  return config.value.diamondCard.currency
})

const isIOS = computed(() => {
  return _global.isInsideApp && _store.appInfo?.platform === Platform.IOS
})

// 新人福利钻石数量
const alphaGiftDiamond = computed(() => {
  return config.value?.alphaGift?.diamond
})
onInit(() => {
  store.fetchStoreConfig().then(res => {
    // 在 iOS 上需要过滤没有 appleProductId 的商品
    if (isIOS.value) {
      res.vipList = res.vipList.filter(item => item.appleProductId)
      res.diamondList = res.diamondList.filter(item => item.appleProductId)
    }

    config.value = res
    loading.value = false
  })
})

function onVipBuy() {
  if (store.user?.guest) {
    onGuestBuy()
    return
  }
  if (config.value == null) return

  _presentContent(VipSelector, {
    props: {
      initialSkuId: defaultVip.value!.id,
      skuList: config.value.vipList,
      onBought() {
        _message.success('购买成功')
        emit('vipBought')
        if (props.exitAfterVipBought) {
          emit('done')
        }
      },
    },
    dialog: {
      pt: {
        content: {
          style: 'min-width: 360px; padding: 0px;',
        },
      },
    },
  })
}

const isDiamondCardClaimingToday = ref(false)
function onDiamondCardReceiveDaily() {
  if (isDiamondCardClaimingToday.value) return

  isDiamondCardClaimingToday.value = true
  receiveDiamondCardDailyDiamonds()
    .then(() => {
      store.receiveDiamondCardToday()
      _showRewards([
        {
          type: RewardType.DIAMOND,
          value: store.diamondCard!.diamondPerDay,
        },
      ])
    })
    .finally(() => {
      isDiamondCardClaimingToday.value = false
    })
}

let cancelPollList: VoidFunction[] = []

// 取消所有轮询 (支付) 不是很准确 订单状态是后端维护的 前端只是展示 不用太精确
function cancelPoll() {
  cancelPollList.forEach(fn => fn())
  cancelPollList = []
}

onUnmounted(() => {
  cancelPoll()
})

async function onDiamondCardBuy() {
  if (store.user?.guest) {
    onGuestBuy()
    return
  }

  if (config.value == null) return

  try {
    diamondCardBuyLoading.value = true
    const res = await createDiamondCardOrder(config.value.diamondCard.id)

    if (res.code !== 0) {
      diamondCardBuyLoading.value = false
      _message.info(res.message)
      return
    }

    // 0 元订单，下单时就完成了
    if (res.data.status === OrderStatus.DELIVERED) {
      diamondCardBuyLoading.value = false
      _message.success('订单支付成功（0元订单）')
      onDiamondCardBuySuccess()
      return
    }

    const payData = await payByEnv(
      res.data.orderId,
      onDiamondCardBuySuccess,
      config.value.diamondCard.appleProductId
    )
    cancelPollList.push(payData.cancelPoll)
    payData.payPromise
      .catch(e => {
        _message.info(e.message)
      })
      .finally(() => {
        diamondCardBuyLoading.value = false
      })
  } catch (_err) {
    warn(_err)
    diamondCardBuyLoading.value = false
  }
}

function onDiamondCardBuySuccess() {
  cancelPoll()
  _showRewards([
    {
      type: RewardType.DIAMOND,
      value: config.value!.diamondCard.immediateDiamonds,
    },
  ])
  store.fetchUserDiamondCard()
}

const diamondBuyLoading = ref<Record<number, boolean>>({})
async function onDiamondBuy(sku: DiamondSku) {
  if (store.user?.guest) {
    onGuestBuy()
    return
  }
  try {
    diamondBuyLoading.value[sku.id] = true
    const res = await createDiamondOrder(sku.id)

    if (res.code !== 0) {
      diamondBuyLoading.value[sku.id] = false
      _message.info(res.message)
      return
    }

    // 0 元订单，下单时就完成了
    if (res.data.status === OrderStatus.DELIVERED) {
      diamondBuyLoading.value[sku.id] = false
      _message.success('订单支付成功（0元订单）')
      onDiamondBuySuccess(sku)
      return
    }

    const payData = await payByEnv(
      res.data.orderId,
      () => onDiamondBuySuccess(sku),
      sku.appleProductId
    )
    cancelPollList.push(payData.cancelPoll)
    payData.payPromise
      .catch(e => {
        _message.info(e.message)
      })
      .finally(() => {
        diamondBuyLoading.value[sku.id] = false
      })
  } catch (_err) {
    warn(_err)
    diamondBuyLoading.value[sku.id] = false
  }
}

function onDiamondBuySuccess(sku: DiamondSku) {
  cancelPoll?.()
  _showRewards([
    {
      type: RewardType.DIAMOND,
      value: sku.amount,
    },
  ])
  store.fetchUserAssets()
}

async function onClaimAlphaGift() {
  if (!_global.isInsideApp) {
    _confirm({
      scene: 'warn',
      icon: {
        type: 'svg',
        name: 'ld-avatar',
      },
      content: _t('diamond.alpha_gift_tip4'),
      primaryText: 'diamond.alpha_gift_tip5',
      secondaryText: 'diamond.alpha_gift_tip6',
      async onPrimaryClick(resolve) {
        router.push({ name: 'app-download' })
        resolve(true)
      },
    })
    return
  }

  const res = await claimAlphaGift()

  if (res.code !== 0) {
    _message.info(res.message)
    return
  }

  emit('alphaGiftClaimed')
  _showRewards([
    {
      type: RewardType.DIAMOND,
      value: alphaGiftDiamond.value!,
    },
  ])
  if (store.user) store.user.claimedGift = true
  store.fetchUserAssets()
}

// 游客模式购买需要跳转到绑定页面
function onGuestBuy() {
  _confirm({
    content: _t('diamond.guest_tip'),
    primaryText: 'auth.bind',
    secondaryText: 'common.not_yet',
    scene: 'confirm',
    icon: {
      name: 'ld-look',
      type: 'img',
    },
    onPrimaryClick(resolve) {
      onBindEmail()

      resolve(true)
    },
  })
}
function onBindEmail() {
  _openDialog(BindEmailForm, {
    title: _t('auth.bind_email'),
  })
}
</script>

<style scoped>
.vip-box {
  padding: 16px;
  border-radius: 12px;
  background: linear-gradient(
    114.77deg,
    var(--ld-premium-100) 0%,
    var(--ld-premium-200) 100%
  );
  border: 1px solid var(--ld-premium-200);
}

.diamond-card-box {
  padding: 16px;
  border-radius: 12px;
  background: linear-gradient(
    114.77deg,
    var(--ld-brand-100) 0%,
    var(--ld-brand-200) 100%
  );

  border: 1px solid var(--ld-brand-200);
}

.diamond-sku {
  border: 1px solid var(--ld-brand-200);
  background-color: var(--ld-brand-100);
  padding: 9px 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
}
</style>
