import type { AIInput } from '@/utils/aiInput'
import api from './base'

import type { CommonResponse } from './base'
import { type ContentTag, type IdentityTag } from '@/types/core'

export enum CardTypeName {
  CLOZE = 'CLOZE',
  WORD = 'WORD',
  MCQ = 'MCQ',
}

export enum PackageColor {
  Pink = 'pink',
  Rose = 'rose',
  Orange = 'orange',
  Amber = 'amber',
  Lime = 'lime',
  Emerald = 'emerald',
  Cyan = 'cyan',
  Sky = 'sky',
  Indigo = 'indigo',
  Purple = 'purple',
}

export type PackageIconEmoji = { type: 'emoji'; content: string }
export type PackageIconImage = { type: 'img'; content: string }

export type PackageStyle = {
  themeColor: PackageColor
  icon: PackageIconEmoji | PackageIconImage
}

export enum PackageAccess {
  Author = 'AUTHOR',
  Vip = 'VIP',
  Unlock = 'UNLOCK',
}

export interface PackageBasic {
  id: number
  name: string

  style: PackageStyle

  contentTags: ContentTagWithParent[]

  // 卡包简介
  description: string

  hashId: string

  authorId: string

  cardCount: number
  createdAt: string
  updatedAt: string

  firstReleasedAt?: string
  lastSnapshotAt?: string

  packageAccess: PackageAccess

  trailStatus?: {
    // 目前试学了几次
    trailCount: number

    // 一共允许试学几次
    trailLimit: number
  }

  // 解锁需要多少钻石
  unlockDiamondRequirement: number

  owned?: {
    defaultCardType: CardTypeName
    shareStatus: ShareStatus
  }

  shelf?: {
    addedShelfAt: string
  }

  previewImgs?: string[]

  // 卡包的卡片类型
  cardType?: CardTypeName
}

export interface Package extends PackageBasic {
  chapterCardsCount: {
    [k in string]: number
  }
  chapters: {
    [k in string]: ChapterItem
  }
  chapterCardIds: {
    [k in string]: number[]
  }
  cardsContentHash: {
    [k in string]: string
  }
}

export interface CardResponse {
  id: number
  content: string
  contentHash: number
  createdType: CardCreatedType
  updatedType: CardUpdatedType
  createdAt: string
  updatedAt: string
  authorId: string
}

export interface Template {
  id: number
  name: string
  content: string
  createdAt: string
  updatedAt: string
}

export interface ChapterItem {
  id: number
  title: string
  orderKey: number
  parentId: number | null
}

export type Chapters = Package['chapters']

export function fetchPackages() {
  return api.get<
    never,
    {
      packages: PackageBasic[]
      followedContentTags: ContentTagWithParent[]
    }
  >('/v1/package_source/packages')
}

export function fetchPackageById(id: number) {
  return api.get<never, CommonResponse<Package>>(
    `/v1/package_source/packages/${id}`
  )
}

export function deletePackageById(id: string | number) {
  return api.delete<never, CommonResponse<never>>(
    `/v1/package_source/packages/${id}`
  )
}

export enum ShareStatus {
  OFF = 'OFF',
  COMMUNITY = 'COMMUNITY',
  SHARED = 'SHARED',
}

export async function updatePackageShare(
  id: string | number,
  shareStatus: ShareStatus,
  tags?: { key: string; rootKey: string }[],
  description?: string,
  previewImgAssetIds?: string[]
) {
  return api.put<never, CommonResponse<PackageBasic>>(
    `/v1/package_source/packages/${id}/share`,
    {
      shareStatus,
      tags,
      description,
      previewImgAssetIds,
    }
  )
}

// root 表示根章节
export function fetchCards(pkgId: number, chapterId: string) {
  return api.get<never, CommonResponse<{ cards: CardResponse[] }>>(
    `/v1/package_source/cards/${pkgId}/${chapterId}`
  )
}

export function fetchTemplateById(id: number) {
  return api.get<never, Template>(`/v1/templates/${id}`)
}

export function createPackage(
  name: string,
  style: PackageStyle,
  tags: { key: string; rootKey: string }[],
  cardType: CardTypeName
) {
  return api.post<never, CommonResponse<PackageBasic>>(
    '/v1/package_source/packages',
    {
      name,
      style,
      tags,
      cardType,
    }
  )
}

export function updatePackage(
  id: string | number,
  payload: {
    name?: string
    tags?: {
      key: string
      rootKey: string
    }[]
    style?: PackageStyle
    defaultCardType?: CardTypeName
  }
) {
  return api.patch<never, CommonResponse<PackageBasic>>(
    `/v1/package_source/packages/${id}`,
    payload
  )
}

export enum CardCreatedType {
  EXCEL_IMPORT = 'EXCEL_IMPORT',
  DUPLICATE = 'DUPLICATE',
  NORMAL = 'NORMAL',
  AIGC = 'AIGC',
}

export enum CardUpdatedType {
  EXCEL_IMPORT = 'EXCEL_IMPORT',
  NORMAL = 'NORMAL',
}

export function createCard(payload: {
  packageId: number
  chapterId: string
  content: object
  createdType?: CardCreatedType
  afterCardId?: number
  beforeCardId?: number
}) {
  payload.createdType = payload.createdType ?? CardCreatedType.NORMAL

  return api.post<never, CommonResponse<CardResponse>>(
    '/v1/package_source/cards',
    payload
  )
}

export function updateCard(
  cardId: number,
  content: object,
  updatedType: CardUpdatedType | undefined = CardUpdatedType.NORMAL
) {
  return api.put<never, CommonResponse<CardResponse>>(
    `/v1/package_source/cards/${cardId}`,
    {
      content,
      updatedType,
    }
  )
}

export function deleteCard(
  packageId: number,
  chapterId: string,
  cardId: number
) {
  return api.delete<never, CommonResponse<never>>(
    `/v1/package_source/cards/${packageId}/${chapterId}/${cardId}`
  )
}

export function createChapter(payload: {
  packageId: number
  title: string
  parentId: string
}) {
  return api.post<never, CommonResponse<ChapterItem>>(
    '/v1/package_source/packages/chapters',
    payload
  )
}

export function updateChapter(
  packageId: number,
  chapterId: string,
  payload: {
    title?: string
    parentId?: string
    cardIds?: number[]
  }
) {
  return api.put<never, CommonResponse<ChapterItem>>(
    `/v1/package_source/packages/chapters/${packageId}/${chapterId}`,
    payload
  )
}

export function deleteChapter(packageId: number, chapterId: string) {
  return api.delete<never, CommonResponse<never>>(
    `/v1/package_source/packages/chapters/${packageId}/${chapterId}`
  )
}

export function fetchContentTags(rootKey: string) {
  return api.get<never, { tree: ContentTag }>(`/v1/tags/content/${rootKey}`)
}

export function fetchIdentityTags(rootKey: string) {
  return api.get<never, { tree: IdentityTag }>(`/v1/tags/identity/${rootKey}`)
}

export function fetchFrequentlyContentTags(rootKey: string) {
  return api.get<never, { contentTags: { key: string; name: string }[] }>(
    `/v1/tags/content/frequently_top`,
    {
      params: {
        rootKey,
      },
    }
  )
}

export function fetchContentTagsByIdentityKeys(identityKeys: string[]) {
  return api.post<never, { contentTags: ContentTagWithParent[] }>(
    `v1/tags/content/by_identity_keys`,
    { identityKeys: identityKeys }
  )
}

export interface PublicPackage {
  id: number
  title: string
  chapters: Chapters
  chapterCardsCount: Package['chapterCardsCount']
  cardCount: Package['cardCount']
  author: {
    nickname: string
    avatar: string
    id: string
  }
  tags: string[]
  price: number
  obtained: boolean
  addedShelf: boolean
  updatedAt: string
  style: PackageStyle
  packageAccess: PackageAccess

  unlockDiamondRequirement: number

  trailStatus?: {
    // 目前试学了几次
    trailCount: number

    // 一共允许试学几次
    trailLimit: number
  }
  description?: string
  previewImgs?: string[]
}

export function fetchPublicPackage(hashId: string) {
  return api.get<never, { pkg: PublicPackage }>(
    `/public/package_source/packages/${hashId}`
  )
}

export function fetchPreivewCards(hashId: string) {
  return api.get<never, { cardResponses: { content: string }[] }>(
    `/public/package_source/packages/${hashId}/cards/preview`
  )
}

export function obtainPackage(packageId: number) {
  return api.post<never, CommonResponse<never>>(
    `/v1/users/users/obtain-package`,
    {
      packageId,
    }
  )
}

export function releasePackage(
  packageId: number,
  payload: {
    tags: { key: string; rootKey: string }[]
    description: string
    previewImgAssetIds?: string[]
  }
) {
  return api.post<never, CommonResponse<PackageBasic>>(
    `/v1/package_source/packages/${packageId}/release`,
    payload
  )
}

// 上传封面图片
export function uploadPreviewImg(packageId: number, file: File) {
  const formData = new FormData()
  formData.append('file', file)

  const params = {
    pkgId: packageId,
  }
  return api.post<unknown, CommonResponse<{ assetId: string }>>(
    '/v1/package_source/preview-img/upload',
    formData,
    {
      params,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )
}

export function fetchPackageSnapshot(packageId: number) {
  return api.get<never, { pkg?: Package }>(
    `/v1/package_source/packages/${packageId}/latest_snapshot`
  )
}

export interface ContentTagWithParent {
  tag: {
    key: string
    name: string
  }
  parentTag?: {
    key: string
    name: string
  }
}
export interface SearchedPackage {
  id: number
  hashId: string
  title: string
  authorInfo: {
    nickname: string
    id: string
    avatar: string
  }
  style: PackageStyle
  firstReleasedAt?: string
  lastSnapshotAt?: string
  cardCount: number
  previewImgs?: string[]
  // 卡册评分
  rankScore: number
  contentTags: ContentTagWithParent[]
}

export function searchPackages(params: {
  keyword: string
  offset?: number
  limit?: number
  contentTagKey?: string
}) {
  return api.get<
    never,
    {
      offset: number
      limit: number
      total: number
      packages: SearchedPackage[]
    }
  >(`/v1/package_source/packages/search`, {
    params,
  })
}

export function fetchSearchKeywordLogs() {
  return api.get<never, { logs: { keyword: string }[] }>(
    `/v1/package_source/search_keyword_log`
  )
}

export function clearSearchKeywordLogs() {
  return api.delete<never, CommonResponse<never>>(
    `/v1/package_source/search_keyword_log/clear`
  )
}

export function moveCard(payload: {
  sourcePkgId: number
  cardId: number
  beforeCardId?: number
  sourceChapterId: string
  targetChapterId: string
}) {
  return api.post<never, CommonResponse<never>>(
    `/v1/package_source/packages/move-card`,
    payload
  )
}

export interface MoveChapterRequest {
  sourcePkgId: number
  chapterId: string
  parentChapterId: string
  beforeChapterId?: string
}

export function moveChapter(payload: MoveChapterRequest) {
  return api.post<never, CommonResponse<never>>(
    `/v1/package_source/packages/move-chapter`,
    payload
  )
}

export interface PackageSnapshot {
  id: number
  packageId: number
  name: string
  chapters: Package['chapters']
  chapterCardIds: Package['chapterCardIds']
  cardsContentHash: Package['cardsContentHash']
  createdAt: string
}

export function fetchPackageSnapshotHistory(hashId: string) {
  return api.get<never, { snapshots: PackageSnapshot[] }>(
    `/public/package_source/packages/${hashId}/snapshot/history`
  )
}

export function fetchRecommendPackages({
  offset,
  limit = 50,
  contentTagKey,
}: {
  offset: number
  limit?: number
  contentTagKey?: string
}) {
  return api.get<
    never,
    {
      packages: SearchedPackage[]
      offset: number
      limit: number
      total: number
    }
  >(`/v1/package_source/packages/recommend`, {
    params: {
      offset,
      limit,
      contentTagKey,
    },
  })
}

export function fetchRecommendPackagesByTags(identityTags: string[]) {
  return api.post<never, { packages: SearchedPackage[] }>(
    `/public/package_source/packages/recommend`,
    {
      identityTags,
    }
  )
}

export function fetchGiftPackages() {
  return api.get<never, { packages: SearchedPackage[] }>(
    `/v1/package_source/packages/gift`
  )
}

export function unlockPackageByDiamonds(pkgId: number) {
  return api.post<never, CommonResponse<void>>(
    `/v1/package_source/packages/${pkgId}/unlock-pkg`
  )
}

export async function genAiClozeCards(
  payload: {
    content: string
    sessionId: string
    knownKeypoints?: AIInput[]
  },
  signal?: AbortSignal,
  isSuccess?: (res: CommonResponse<{ keypoints: AIInput[] }>) => boolean
) {
  const request = function () {
    return api
      .post<never, CommonResponse<{ keypoints: AIInput[] }>>(
        `/v1/package_source/gen-keypoint`,
        payload,
        {
          signal,
          _custom429Msg: _t('api.429_ai'),
        }
      )
      .catch(err => {
        // 如果是超时的报错，则不用上报给 sentry
        if (err?.message?.includes('timeout')) {
          err.skipSentry = true
        }

        throw err
      })
  }

  const res = await request()

  // 如果上层业务认为此次 AI 生成失败，则再尝试重试一次
  if (isSuccess != null && !isSuccess(res)) {
    return request()
  }

  return res
}

export type AiEnWordTerm = {
  term: string
  definition: string
}

export async function genAiEnWordCards(
  payload: {
    content: string
    sessionId: string
  },
  signal?: AbortSignal
) {
  return api
    .post<
      never,
      CommonResponse<{
        terms: AiEnWordTerm[]
      }>
    >(`/v1/package_source/gen-enwords`, payload, {
      signal,
      _custom429Msg: _t('api.429_ai'),
    })
    .catch(err => {
      // 如果是超时的报错，则不用上报给 sentry
      if (err?.message?.includes('timeout')) {
        err.skipSentry = true
      }

      throw err
    })
}

export function batchCreateCards(payload: {
  packageId: number
  chapterId: string
  cards: object[]
  createdType: CardCreatedType
  aigc?: {
    outputs: AIInput[]
    sessionId: string
  }
}) {
  return api.post<never, CommonResponse<{ cards: CardResponse[] }>>(
    '/v1/package_source/cards/batch',
    {
      packageId: payload.packageId,
      chapterId: payload.chapterId,
      createdType: payload.createdType,
      contents: payload.cards,
      aigc: payload.aigc,
    }
  )
}

export async function genAiDistractors(
  payload: {
    content: string
    sessionId: string
  },
  signal?: AbortSignal
) {
  return api
    .post<never, CommonResponse<{ distractors: string[] }>>(
      `/v1/package_source/gen-distractor`,
      payload,
      {
        signal,
        _custom429Msg: _t('api.429_ai'),
      }
    )
    .catch(err => {
      // 如果是超时的报错，则不用上报给 sentry
      if (err?.message?.includes('timeout')) {
        err.skipSentry = true
      }

      throw err
    })
}

export async function fetchCardsStats(cardIds: number[]) {
  return api.post<
    never,
    { cardStatsMap: Record<number, { learnTimes: number }> }
  >(`/v1/stats/cards`, {
    cardIds,
  })
}

export function searchPackageTags(keyword: string) {
  return api.get<never, { contentTags: ContentTagWithParent[] }>(
    `/v1/package_source/packages/search-tags`,
    {
      params: {
        keyword,
      },
    }
  )
}

export function fetchRecommendTags() {
  return api.get<never, { contentTags: ContentTagWithParent[] }>(
    `/v1/package_source/packages/recommend-tags`
  )
}
