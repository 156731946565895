import { RankType, type Rank } from '@/types/rank'

export function getRankName(type: RankType) {
  return {
    [RankType.Bronze]: _t('rank.bronze'),
    [RankType.Silver]: _t('rank.silver'),
    [RankType.Gold]: _t('rank.gold'),
    [RankType.Platinum]: _t('rank.platinum'),
    [RankType.Emerald]: _t('rank.emerald'),
    [RankType.Diamond]: _t('rank.diamond'),
    [RankType.Legendary]: _t('rank.legendary'),
  }[type]
}

export function getRankTitle(rank: Rank) {
  const rankName = getRankName(rank.type)

  if (rank.type === RankType.Legendary && rank.star! > 1) {
    return `${rankName}⭐️${rank.star}`
  }

  return rankName
}

export function parseRank(rankString: string): Rank {
  const reg = /^([A-Z]+)(\_\d+)?$/

  const match = rankString.match(reg)

  if (match == null) {
    throw 'invalid rank string'
  }

  const rank: Rank = {
    type: match[1] as RankType,
  }

  if (match[2] != null) {
    rank.star = parseInt(match[2].slice(1))
  }

  return rank
}
