<template>
  <div class="min-w-[320px] min-h-[480px]">
    <Loading v-if="loading" />
    <Empty
      v-else-if="snapshotHistory.length === 0"
      :text="_t('package.snapshot_empty')"
    />

    <div
      v-else
      class="flex flex-col gap-4"
    >
      <div
        v-for="(item, i) of snapshotHistory"
        :key="item.id"
        class="w-full"
      >
        <div class="flex items-center">
          <span class="font-bold mr-auto">
            {{
              _t('package.snapshot_version', {
                version: snapshotHistory.length - i,
              })
            }}
          </span>

          <DebugLabel
            :text="`${formatDate(item.createdAt)} (id: ${item.id})`"
          />
          <span class="text-sm text-[var(--text-color-secondary)] ml-2">
            {{ formatDateRelative(item.createdAt) }}
          </span>
        </div>

        <div class="mt-2 whitespace-pre-line">{{ getDiffInfo(item, i) }}</div>
        <Divider />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref } from 'vue'
import { fetchPackageSnapshotHistory } from '@/api/package-source'
import Empty from '../Empty.vue'
import { formatDate, formatDateRelative } from '@/utils'
import { diffPackage } from '@/utils/package'

import type { PackageSnapshot } from '@/api/package-source'

const props = defineProps<{
  packageHashId: string
}>()

const snapshotHistory = ref<PackageSnapshot[]>([])
const loading = ref(true)

function getDiffInfo(item: PackageSnapshot, i: number) {
  if (i === snapshotHistory.value.length - 1) {
    return _t('package.first_publish')
  }

  const lastSnapshot = snapshotHistory.value[i + 1]
  const diff = diffPackage(item, lastSnapshot)
  let cardDiffText = ''
  let chapterDiffText = ''

  if (diff.cardAdd) {
    cardDiffText += _t('package.diff_card_added', { count: diff.cardAdd })
  }

  if (diff.cardUpdate) {
    cardDiffText += _t('package.diff_card_updated', { count: diff.cardUpdate })
  }

  if (diff.cardDelete) {
    cardDiffText += _t('package.diff_card_deleted', { count: diff.cardDelete })
  }

  if (diff.chapterAdd) {
    chapterDiffText += _t('package.diff_chapter_added', {
      count: diff.chapterAdd,
    })
  }

  if (diff.chapterUpdate) {
    chapterDiffText += _t('package.diff_chapter_updated', {
      count: diff.chapterUpdate,
    })
  }

  if (diff.chapterDelete) {
    chapterDiffText += _t('package.diff_chapter_deleted', {
      count: diff.chapterDelete,
    })
  }

  return [cardDiffText, chapterDiffText]
    .filter(text => text.length > 0)
    .join('\n')
}

fetchPackageSnapshotHistory(props.packageHashId)
  .then(res => {
    snapshotHistory.value = res.snapshots.sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    )
  })
  .finally(() => {
    loading.value = false
  })
</script>
<style scoped></style>
