<template>
  <div
    ref="padEl"
    class="pad g-ld-shadow"
  >
    <div class="text-15px text-[var(--text-color-secondary)] mb-2">
      {{
        isNeverLearned
          ? _t('cardview.never_learned')
          : _t('cardview.learn_count', { count: props.learnCount })
      }}
    </div>

    <CardEssence :card="card" />
  </div>
</template>
<script setup lang="ts">
import type { CardResponse } from '@/api/package-source'

import { type Card } from '@/types/core'
import { computed, ref } from 'vue'
import CardEssence from '@/components/CardEssence/CardEssence.vue'

const props = defineProps<{
  cardResponse?: CardResponse
  card?: string
  learnCount?: number
}>()

const padEl = ref<HTMLDivElement>()

const card = computed(() => {
  if (props.cardResponse) {
    return JSON.parse(props.cardResponse.content) as Card
  }
  if (props.card) {
    return JSON.parse(props.card) as Card
  }

  return {} as Card
})

const isNeverLearned = computed(
  () => props.learnCount == null || props.learnCount === 0
)
</script>
<style scoped>
.pad {
  border-radius: 8px;
  width: 100%;
  min-height: 44px;
  padding: 12px 16px;
  border: 1px solid var(--ld-border);
  transition: all 0.3s;
  background-color: white;
}
</style>
