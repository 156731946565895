<template>
  <FirstStep
    v-if="step === 1"
    @next="onNext"
  />

  <SecondStep
    v-if="step === 2"
    :checkData="props.checkData"
    @done="onNext"
  />

  <ThirdStep
    v-if="step === 3"
    @next="onNext"
  />
</template>
<script lang="ts" setup>
import { ref } from 'vue'
import FirstStep from './FirstStep.vue'
import SecondStep from '../CheckInView.vue'
import ThirdStep from './ThirdStep.vue'
import type { CheckInCtRes, CheckInStatsItem } from '@/api/user'

const props = defineProps<{
  checkData: {
    checkInCtRes: CheckInCtRes
    stats: CheckInStatsItem[]
  }
}>()

const emit = defineEmits<{
  done: []
}>()

const step = ref<1 | 2 | 3>(1)

function onNext() {
  if (step.value < 3) {
    step.value++
  } else {
    emit('done')
  }
}
</script>
<style scoped></style>
