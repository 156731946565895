import NotificationUserGuide from '@/components/NotificationUserGuide.vue'
import { randomPick } from '.'
import dayjs from 'dayjs'
import { range } from 'lodash-es'
import { Platform, type LocalNotification } from './bridge'
import { createOrUpdatePush, deletePush } from '@/api/user'

const NOTIFICATION_ID_START = {
  DAILY_REMINDER: 1000,
}
const DAILY_REMINDER_LENGTH = 7

// App 是否支持 notification 的功能
export async function checkNotificationAvaiable() {
  if (!_global.isInsideApp) return false

  // 如果 bridge 直接返回 null，则表示这个接口没有，认为不支持通知功能
  return (await _bridge.checkNotificationStatus()) != null
}

function iosClearDailyReminders() {
  const startId = NOTIFICATION_ID_START.DAILY_REMINDER

  // 先移除所有的定时提醒
  const ids = range(startId, startId + DAILY_REMINDER_LENGTH)
  _bridge.clearLocalNotifications(ids)
}

// 每次登录以后，重置本地通知
export function resetLocalNotifications() {
  iosResetDailyReminder()
}

// 【通知总开关】打开
// 此时根据 notiConfig 中的子开关的配置，设置具体的项目
export function onNotificationsEnabled() {
  _store.notiConfig.enabled = true

  // 本地通知，目前只有 iOS 平台的【每日学习提醒】
  iosResetDailyReminder()

  // 远程通知，更新后端表记录
  updatePushConfig()
}

// 【通知总开关】关闭
export function onNotificationsDisabled() {
  _store.notiConfig.enabled = false

  // 清除所有本地通知
  _bridge.clearAllLocalNotifications()

  // 通知后端清除推送记录
  deletePushConfig()
}

// 【每日定时提醒】通知配置发生了变化
export function handleDailyReminderChange() {
  if (_store.appInfo?.platform === Platform.IOS) {
    iosResetDailyReminder()
  }

  // 后端记录一并修改一下，虽然对于 iOS 来说，【每日定时提醒】后端不会进行推送
  updatePushConfig()
}

// 【排位赛状态更新】通知配置发生了变化
export function handleRankChange() {
  updatePushConfig()
}

// 重置每日定时提醒，设置 7 天内通知
// ios only，调用系统的本地通知接口，不走极光
async function iosResetDailyReminder() {
  if (_store.appInfo?.platform !== Platform.IOS) {
    return
  }

  iosClearDailyReminders()

  // 功能未开启
  const notiConfig = _store.notiConfig
  if (!notiConfig.enabled || !notiConfig.dailyReminder.enabled) {
    return
  }

  // 推送文案 {title,content } 从数组中随机选择
  const pushTexts = [
    {
      title: _t('notification.text1_title'),
      content: _t('notification.text1_content'),
    },
    {
      title: _t('notification.text2_title'),
      content: _t('notification.text2_content'),
    },
    {
      title: _t('notification.text3_title'),
      content: _t('notification.text3_content'),
    },
    {
      title: _t('notification.text4_title'),
      content: _t('notification.text4_content'),
    },
    {
      title: _t('notification.text5_title'),
      content: _t('notification.text5_content'),
    },
    {
      title: _t('notification.text6_title'),
      content: _t('notification.text6_content'),
    },
  ]

  const startId = NOTIFICATION_ID_START.DAILY_REMINDER

  const time = notiConfig.dailyReminder.time

  const notifications: LocalNotification[] = []

  for (let index = 0; index < DAILY_REMINDER_LENGTH; index++) {
    const hour = time.hour
    const minute = time.minute
    const now = new Date()
    const fireTime = dayjs(now)
      .set('hour', hour)
      .set('minute', minute)
      .set('second', 0)
      .add(index, 'day')
      .valueOf()
    const pushText = randomPick(pushTexts)
    notifications.push({
      id: index + startId,
      title: pushText.title,
      content: pushText.content,
      fireTime: fireTime,
    })
  }
  _bridge.sendLocalNotifications(notifications)
}

// 尝试开启通知权限 并且轮询检查通知权限状态
export async function tryToEnableNotification(
  abortSignal?: AbortSignal
): Promise<'enabled' | 'disabled'> {
  // 如果没有传入 abortSignal 则创建一个。 10s 超时
  if (!abortSignal) {
    const abortController = new AbortController()
    abortSignal = abortController.signal
    setTimeout(() => {
      abortController.abort()
    }, 10000)
  }

  return new Promise(async (resolve, reject) => {
    const initStatus = await _bridge.checkNotificationStatus()
    if (initStatus === 'enabled') {
      resolve('enabled')
      return
    }

    let timer = 0

    // 轮询 获取通知开关状态
    // ios
    //    如果开始是 'unset' 则不是'unset'时停止轮询
    //    如果开始是 'disabled' 则是'enabled'时停止轮询
    // android
    //    'enabled'时停止轮询
    async function pollNotificationStatus() {
      if (abortSignal?.aborted) {
        clearTimeout(timer)
        reject('timeout')
        return
      }

      const stopStatus =
        _store.appInfo?.platform === Platform.Android
          ? ['enabled']
          : initStatus === 'unset'
            ? ['enabled', 'disabled']
            : ['enabled']

      const status = await _bridge.checkNotificationStatus()
      if (stopStatus.includes(status)) {
        resolve(status as any)
        return
      }

      timer = setTimeout(pollNotificationStatus, 1000)
    }

    // 检查通知权限状态
    pollNotificationStatus()

    await _bridge.tryToEnableNotification()
  })
}

// 引导通知
// 这里的引导通知，有多种，优先级从高到低依次是
// 完成任意 1 次闯关后的引导
// 累计完成 3 关后的引导
// 累计完成 10 关后的引导
export async function checkNotificationGuide() {
  let guide = false
  if (_store.appInfo?.platform === Platform.IOS) {
    // ios 判断通知权限是否是 unset
    const status = await _bridge.checkNotificationStatus()
    guide = status === 'unset'
  } else {
    // android 判断通知设置中是否关闭了通知
    guide = _store.notiConfig.enabled === false
  }

  if (guide) {
    await _store.fetchStatsOverview()
    const stageCount = _store.statsOverview?.stageCount ?? 0

    if (stageCount > 0) {
      // 完成任意 1 次闯关后的引导
      if (!_store.notiConfig.guideCompleted.dailyReminderAnyStage) {
        showNotificationGuide()
        _store.notiConfig.guideCompleted.dailyReminderAnyStage = true

        return
      }
      // 累计完成 3 关后的引导
      if (
        stageCount == 3 &&
        !_store.notiConfig.guideCompleted.dailyReminderThreeStages
      ) {
        showNotificationGuide()
        _store.notiConfig.guideCompleted.dailyReminderThreeStages = true
        return
      }
      // 累计完成 10 关后的引导
      if (
        stageCount == 10 &&
        !_store.notiConfig.guideCompleted.dailyReminderTenStages
      ) {
        showNotificationGuide()
        _store.notiConfig.guideCompleted.dailyReminderTenStages = true
        return
      }
    }
  }
}

async function showNotificationGuide() {
  // 默认替用户设置的时间 最近学习的开始时间(如没有最近学习时间，取当前时间)
  // 时间往前，取 quarter 整数倍时间
  // eg：最近学习是 19:57 分开始的，则默认设为 19:45 分
  // 使用 24 小时制的时间格式
  const nowDate = new Date()
  const m = nowDate.getMinutes()
  const quarter = Math.floor(m / 15)
  nowDate.setMinutes(quarter * 15)

  const time = await _presentContent<{
    hour: number
    minute: number
  }>(NotificationUserGuide, {
    rootClass: 'min-w-400px max-w-600px',
    props: {
      initialTime: {
        hour: nowDate.getHours(),
        minute: nowDate.getMinutes(),
      },
    },
  })
  if (time) {
    _store.notiConfig.dailyReminder.time = time

    const status = await tryToEnableNotification()
    if (status === 'enabled') {
      _store.notiConfig.enabled = true

      // 开启每日定时提醒
      _store.notiConfig.dailyReminder.enabled = true

      iosResetDailyReminder()

      updatePushConfig()
    }
  }
}

// 更新推送配置
export async function updatePushConfig() {
  const rid = await _bridge.getRegistrationID()
  if (!rid) {
    return
  }
  const deviceInfo = (await _bridge.deviceInfo()) ?? ''

  const notiConfig = _store.notiConfig

  if (!notiConfig.enabled) {
    return
  }

  createOrUpdatePush({
    rid,
    clientChannel: _store.appInfo?.channel ?? '',
    deviceInfo,
    dailyReminder: {
      on: notiConfig.dailyReminder.enabled,
      hour: notiConfig.dailyReminder.time.hour,
      minute: notiConfig.dailyReminder.time.minute,
    },
    rank: {
      on: notiConfig.rank.enabled,
    },
  })
}

async function deletePushConfig() {
  const rid = await _bridge.getRegistrationID()
  if (!rid) {
    return
  }
  deletePush(rid)
}
