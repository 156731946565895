<template>
  <HuajiPkgCardList
    :packages="props.packages"
    @pkg-click="emit('pkgClick', $event)"
  />
</template>
<script lang="ts" setup>
import type { SearchedPackage } from '@/api/package-source'
import HuajiPkgCardList from './HuajiPkgCardList.vue'

const props = defineProps<{
  packages: SearchedPackage[]
  highlight?: boolean
}>()

const emit = defineEmits<{
  pkgClick: [SearchedPackage]
}>()
</script>
<style scoped></style>
