<template>
  <div class="h-full g-safe-area px-4 flex flex-col bg-ld-background">
    <RatioSpacedContainer
      class="flex-1 g-body-width"
      :top="1"
      :bottom="1"
    >
      <Img
        name="ld-laugh"
        class="w-77px"
      />

      <div class="h-86px">
        <div
          ref="chatBox"
          class="chat"
          @animationend="onChatBoxAnimationEnd"
        ></div>
      </div>
    </RatioSpacedContainer>

    <div class="g-footer-width mb-4">
      <Button
        :class="['w-full', showButton ? 'fade-in' : 'opacity-0']"
        label="继续"
        @click="emit('next')"
      ></Button>
    </div>
  </div>
</template>

<script setup lang="ts">
import Typewriter from 'typewriter-effect/dist/core'
import { ref } from 'vue'

const emit = defineEmits<{
  next: []
}>()

const showButton = ref(false)
const chatBox = ref<HTMLDivElement>()

function onChatBoxAnimationEnd() {
  const typewriter = new Typewriter(chatBox.value, {
    delay: 15,
    cursor: '',
  })

  typewriter
    .typeString(_t('learn.checkin_msg1'))
    .pauseFor(200)
    .start()
    .callFunction(() => {
      setTimeout(() => {
        showButton.value = true
      }, 200)
    })
}
</script>

<style scoped>
.chat {
  background-color: white;
  border-radius: 18px;
  padding: 12px;
  font-size: 22px;
  height: fit-content;
  animation: chatScale 0.15s;
  animation-fill-mode: forwards;
  letter-spacing: 0.5px;
}

.fade-in {
  opacity: 0;
  animation: g-fade-in 0.2s;
  animation-fill-mode: forwards;
}

@keyframes chatScale {
  0% {
    width: 0px;
    height: 0px;
  }

  100% {
    height: 86px;
    width: 320px;
  }
}
</style>
