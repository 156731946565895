<template>
  <div class="p-4">
    <div class="mb-3 text-15px">{{ _t('cardview.analysis') }}</div>

    <Textarea
      v-model="analysis"
      class="w-full shadow-none bg-white"
      rows="1"
      autoResize
      :style="analysisInputStyle"
      :placeholder="_t('cardview.analysis_placeholer')"
      @focus="emit('focus')"
      @update:modelValue="onAnalysisUpdate($event)"
    />
  </div>
</template>
<script lang="ts" setup>
import type { MCQCard } from '@/types/model'
import { computed, ref, type CSSProperties } from 'vue'

const props = defineProps<{
  card: MCQCard
  cardId: number
}>()

const emit = defineEmits<{
  focus: []
  update: [cardId: number, MCQCard]
}>()

const analysis = ref(props.card.analysis)

const analysisInputStyle = computed<CSSProperties>(() => {
  // PC端：
  // 默认高度：5 行半
  // 最大高度：10 行半
  // 移动端：
  // 默认高度：1 行
  // 最大高度：5 行半
  if (_global.isPcMode) {
    return {
      minHeight: '142px',
      maxHeight: '262px',
    }
  }
  return {
    maxHeight: '142px',
  }
})

function onAnalysisUpdate(analysis: string) {
  emit('update', props.cardId, {
    ...props.card,
    analysis,
  })
}
</script>
<style scoped></style>
