<template>
  <div v-if="parsed.error">
    {{ _t('cardview.parse_failed') }}
  </div>

  <template v-else>
    <div class="bg-white px-4 py-3 rounded-8px">
      <Img
        v-if="card.illustration"
        :assetId="card.illustration"
        :class="[
          'rounded-4px mb-2 cursor-pointer transition-width select-none',
          showLargeIllustration ? 'w-full' : 'w-100px',
        ]"
        @click.stop="toggleIllustrationSize"
      />

      <div class="text-17px font-semibold mb-2">
        {{ parsed.card.question }}
      </div>

      <div class="flex flex-col gap-2px">
        <div
          v-for="item in options"
          :key="item.index"
          :class="[
            'flex items-start ml--4',
            {
              'text-ld-brand-500': item.isAnswer,
            },
          ]"
        >
          <div class="h-26px flex items-center gap-1 px-1">
            <Icon
              name="mcq-option-check"
              :class="[
                'w-16px shrink-0 ml-1',
                {
                  'opacity-0': !item.isAnswer,
                },
              ]"
            />

            <span
              class="w-18px font-semibold g-monospace-font text-17px leading-none text-right"
            >
              {{ item.index }}.
            </span>
          </div>

          <span class="leading-[1.5] text-17px font-medium">
            {{ item.text }}
          </span>
        </div>
      </div>
    </div>

    <div
      v-if="!isCardAnalysisEmpty"
      class="bg-white p-3 rounded-8px mt-4"
    >
      <div
        class="text-gray-600 bg-gray-200 rounded-4px text-13px leading-[1.5] px-2 w-fit mb-2"
      >
        {{ _t('cardview.analysis') }}
      </div>

      {{ parsed.card.analysis }}
    </div>
  </template>
</template>
<script setup lang="ts">
import { parseMCQCard } from '@/utils/card'
import { computed, ref } from 'vue'

import type { MCQCard } from '@/types/core'
import { genOptions } from '../ConcreteCard/MCQCardFace/common'

const props = defineProps<{
  card: MCQCard
}>()

const showLargeIllustration = ref(false)

const parsed = parseMCQCard(props.card)

const options = genOptions(parsed.card.options, false, false)

const isCardAnalysisEmpty = computed(() => {
  return (parsed.card.analysis ?? '').trim().length === 0
})

function toggleIllustrationSize() {
  showLargeIllustration.value = !showLargeIllustration.value
}
</script>
<style scoped></style>
