<template>
  <div class="h-full px-4 pb-4 flex flex-col bg-ld-background">
    <AppBar
      :title="_t('terminate.apply')"
      class="px-0"
      @back="onBack"
    />

    <div class="overflow-y-auto mb-4">
      <div class="px-2 mb-18px flex flex-col gap-3">
        <div class="text-21px leading-22px font-semibold mt-9">
          {{ _t('terminate.tip1') }}
        </div>
        <div class="text-17px leading-[1.8]">
          {{ _t('terminate.tip2') }}
        </div>
      </div>

      <div class="bg-white rounded-18px py-4 px-3">
        <ol class="pl-5 list-disc list-outside text-17px leading-[1.8]">
          <li>{{ _t('terminate.tip3') }}</li>
          <li>
            {{ _t('terminate.tip4') }}
          </li>
          <li>
            {{ _t('terminate.tip5') }}
          </li>
          <li>
            {{ _t('terminate.tip6') }}
          </li>
          <li>
            {{ _t('terminate.tip7') }}
          </li>
          <li>
            {{ _t('terminate.tip8') }}
          </li>
        </ol>
      </div>
    </div>

    <Button
      :label="buttonLabel"
      :disabled="disabled"
      scene="danger"
      class="mt-auto"
      @click="onTerminateSubmit"
    />
  </div>
</template>
<script setup lang="ts">
import { Code } from '@/api/code'
import { applyTermination } from '@/api/user'
import AppBar from '@/mobile/components/AppBar.vue'
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'

const emit = defineEmits<{
  back: []
}>()

const router = useRouter()

const freezeSeconds = ref(10)

const disabled = computed(() => freezeSeconds.value > 0)
const buttonLabel = computed(() => {
  if (freezeSeconds.value > 0)
    return _t('terminate.apply_cd', { count: freezeSeconds.value })

  return _t('terminate.apply')
})
let timer: number

function startTimer() {
  timer = setInterval(function () {
    freezeSeconds.value--

    if (freezeSeconds.value <= 0) {
      clearInterval(timer)
    }
  }, 1000)
}

function onTerminateSubmit() {
  _confirm({
    scene: 'warn',
    icon: {
      name: 'ld-cry',
      type: 'img',
    },
    content: _t('terminate.tip9'),
    primaryText: 'terminate.tip10',
    secondaryText: 'terminate.tip11',
    onSecondaryClick(resolve) {
      applyTermination().then(res => {
        if (res.code !== Code.Ok) {
          resolve(false)
          _message.info(res.message)
          return
        }

        resolve(true)
        router.replace({
          name: 'terminating',
          query: {
            willTerminateAt: res.data.willTerminateAt,
          },
        })
      })
    },
  })
}

function onBack() {
  emit('back')
}

onInit(startTimer)
</script>
<style scoped></style>
