<template>
  <div
    ref="boxRef"
    :class="containerClass"
  >
    <div
      v-for="pkg in props.packages"
      :key="pkg.id"
      class="rounded-12px border-solid border-1px border-ld-border shadow-sm max-w-750px bg-white cursor-pointer"
      @click="emit('pkgClick', pkg)"
    >
      <div class="w-full p-3 h-96px flex items-center">
        <div class="flex flex-col flex-1 h-full overflow-hidden">
          <div
            class="text-15px font-semibold title line-clamp-2"
            v-html="pkg.title"
          ></div>

          <div class="flex items-center mt-auto">
            <Avatar
              :avatar="pkg.authorInfo.avatar"
              imgStyle="xs"
              class="mr-1 h-4 w-4"
            />
            <span class="text-gray-400 text-13px truncate leading-none">
              {{ pkg.authorInfo.nickname }}
            </span>
          </div>
        </div>

        <div
          v-if="pkg.previewImgs && pkg.previewImgs.length"
          class="w-72px h-72px rounded-4 overflow-hidden shrink-0"
        >
          <Img
            :assetId="pkg.previewImgs[0]"
            imgStyle="l"
            class="w-full h-full"
          />
        </div>
      </div>

      <DebugLabel class="p-3">
        <div>{{ `author id: ${pkg.authorInfo.id}` }}</div>
        <div class="text-xs">id：{{ pkg.id }}</div>
        <div
          v-if="pkg.firstReleasedAt"
          class="text-xs"
        >
          首次发布：{{ $f.date(pkg.firstReleasedAt) }}
        </div>
        <div
          v-if="pkg.lastSnapshotAt"
          class="text-xs"
        >
          最近快照：{{ $f.date(pkg.lastSnapshotAt) }}
        </div>
        <div class="text-xs">卡册评分：{{ pkg.rankScore }}</div>
        <div class="text-xs">标签：{{ getTagsLabel(pkg) }}</div>
      </DebugLabel>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { SearchedPackage } from '@/api/package-source'
import { computed, onMounted, onUnmounted, ref } from 'vue'

const props = defineProps<{
  packages: SearchedPackage[]
  highlight?: boolean
}>()

const emit = defineEmits<{
  pkgClick: [SearchedPackage]
}>()

const boxRef = ref<HTMLElement | null>(null)
const containerWidth = ref<number>()

const containerClass = computed(() => {
  const singleColumn = 'flex flex-col gap-3'

  if (containerWidth.value == null) return singleColumn

  if (containerWidth.value > 750) {
    return 'grid grid-cols-2 gap-3'
  }
  return singleColumn
})

function getTagsLabel(pkg: SearchedPackage) {
  return pkg.contentTags
    .map(({ tag, parentTag }) => {
      if (parentTag?.name) {
        return `${parentTag.name}/${tag.name}`
      }
      return tag.name
    })
    .join(',')
}

onMounted(() => {
  autoLayout()
  // 监听窗口大小变化
  window.addEventListener('resize', autoLayout)
})
onUnmounted(() => {
  window.removeEventListener('resize', autoLayout)
})

function autoLayout() {
  if (boxRef.value) {
    containerWidth.value = boxRef.value.clientWidth
  }
}
</script>

<style scoped>
.title :deep(em) {
  color: var(--primary-color);
  font-style: unset;
}
</style>
