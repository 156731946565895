<template>
  <EnWordCardLayout :hide-role-image="props.face.style.hideRoleImage">
    <template #content>
      <DebutCardBadge
        v-if="face.isFirstLearn"
        class="absolute top-0 left-0"
      />
      <WrongCardBadge
        v-if="face.isWrongCard"
        class="absolute top-0 left-0"
      />
      <span class="text-19px">
        {{ props.face.card.definition }}
      </span>
    </template>

    <template #center>
      <SparkleText
        class="spelling relative"
        :tag="data.checked"
      >
        <div>
          {{ props.face.card.word }}

          <div
            :class="[
              'absolute top-0 left-0 px-2 w-full h-full',
              isSpellCompleted ? 'text-green' : 'text-ld-brand-500',
            ]"
          >
            {{ input }}
          </div>
        </div>
      </SparkleText>
    </template>

    <template #options>
      <transition
        name="options-slide"
        mode="out-in"
        class="overflow-hidden h-140px"
      >
        <div :key="data.charIndex">
          <div
            class="w-fit mx-auto flex justify-between gap-3 options-row first"
          >
            <Button
              v-for="(item, i) of data.options"
              :key="i"
              size="small"
              :scene="getOptionScene(i)"
              :class="['option', { wrong: data.selectedWrongOptions.has(i) }]"
              @click="onOptionClick(i)"
            >
              <SparkleText
                class="flex flex-col justify-center items-start w-full text-start"
                :tag="data.selectedCorrectOptions.has(i)"
              >
                <div class="w-full flex text-25px justify-center">
                  {{ item }}
                </div>
              </SparkleText>
            </Button>
          </div>

          <div
            class="w-fit mx-auto flex justify-between gap-3 mt-3 options-row second"
          >
            <Button
              v-for="(item, i) of data.nextOptions"
              :key="i"
              size="small"
              disabled
              scene="choice"
              class="option"
            >
              <div class="w-full text-25px text-center">
                {{ item }}
              </div>
            </Button>
          </div>
        </div>
      </transition>
    </template>
  </EnWordCardLayout>
</template>
<script setup lang="ts">
import { UnitEventType, type SpellEnWordFace } from '@/types/core'
import { reactive, inject, onMounted, computed } from 'vue'
import { useHotKey } from '@/hooks'
import { FeedbackStar } from '../../common/feedback'
import SparkleText from '@/components/SparkleText.vue'
import EnWordCardLayout from '../../layout/EnWordCardLayout.vue'
import { randomPick, vibrate, VibrateType, wait } from '@/utils'
import DebutCardBadge from '@/components/ConcreteCard/common/DebutCardBadge.vue'
import { showCardDetail } from '../../common'
import { shuffle, uniq } from 'lodash-es'
import WrongCardBadge from '../../common/WrongCardBadge.vue'

// 每一行有多少个选项
const OPTIONS_IN_ROW = 4

useHotKey('1,2,3,4,5,6,7,8,9,0', (evt: KeyboardEvent) => {
  const key = evt.key
  const index = (key === '0' ? 10 : Number(key)) - 1

  if (index > OPTIONS_IN_ROW) return

  onOptionClick(index)
})

const onNext = inject<VoidFunction>('onNext')
const onEvent = inject<(event: UnitEventType) => void>('onEvent')
const onStar = inject<(star: FeedbackStar) => void>('onStar')

const props = defineProps<{
  face: SpellEnWordFace
}>()

const data = reactive({
  checked: false,
  tipTimes: 0,
  star: FeedbackStar.One,

  selectedCorrectOptions: new Set<number>(),
  selectedWrongOptions: new Set<number>(),

  // 当前输入到哪个字母
  charIndex: 0,
  // 当前正在选的字母选项
  options: [] as string[],
  // 下一一个字母的字母选项
  nextOptions: [] as string[],
})

const input = computed(() => props.face.card.word.slice(0, data.charIndex))
const isSpellCompleted = computed(() => props.face.card.word === input.value)

async function onAutoNext(beforeNext: Promise<void>) {
  // 先等待按钮动画结束
  await wait(700)

  // 如果是新知识且设置的展示卡片详情，此时会先弹出卡片详情
  if (props.face.isFirstLearn && props.face.style.showCardDetail) {
    showCardDetail(props.face.card, props.face.isFirstLearn, onNext)
    return
  }

  try {
    await beforeNext
  } finally {
    onNext?.()
  }
}

function getOptionScene(
  index: number
): 'choice' | 'choiceCorrect' | 'choiceWrong' {
  const correct = data.selectedCorrectOptions.has(index)
  const wrong = data.selectedWrongOptions.has(index)

  if (correct) {
    return 'choiceCorrect'
  } else if (wrong) {
    return 'choiceWrong'
  }

  return 'choice'
}

function animeOptionCorrect(index: number) {
  data.selectedCorrectOptions.add(index)

  setTimeout(() => {
    data.selectedCorrectOptions.delete(index)
  }, 400)
}

function animeOptionWrong(index: number): Promise<void> {
  data.selectedWrongOptions.add(index)

  return new Promise(resolve => {
    setTimeout(() => {
      resolve(undefined)
      data.selectedWrongOptions.delete(index)
    }, 400)
  })
}

// 生成单词中指定位置的字母，输出四个选项
const alphabetArray = Array.from({ length: 26 }, (_, index) =>
  String.fromCharCode(97 + index)
)

function generateOptionsByIndex(index: number): string[] {
  const word = props.face.card.word

  if (index < 0 || index >= word.length) return []

  const char = word[index]

  // 先从单词的剩余字母中挑选干扰项
  const distractors = uniq([...word].filter(item => item !== char))

  // 如果干扰项不够，则再从 26 个字母中挑选
  while (distractors.length < OPTIONS_IN_ROW - 1) {
    const filteredAlphabetArray = alphabetArray.filter(
      item => item !== char && !distractors.includes(item)
    )

    distractors.push(randomPick(filteredAlphabetArray))
  }

  const selectedDistractors = shuffle(distractors).slice(0, OPTIONS_IN_ROW - 1)

  return shuffle(selectedDistractors.concat(char))
}

onInit(() => {
  data.options = generateOptionsByIndex(data.charIndex)
  data.nextOptions = generateOptionsByIndex(data.charIndex + 1)
})

async function onOptionClick(index: number) {
  if (data.checked) return

  const inputChar = data.options[index]
  const correctChar = props.face.card.word[data.charIndex]

  if (inputChar !== correctChar) {
    data.tipTimes++
    vibrate(VibrateType.Warning)
    animeOptionWrong(index)
    onEvent?.(UnitEventType.WRONG)
    return
  }

  await animeOptionCorrect(index)
  data.charIndex++
  data.options = data.nextOptions
  data.nextOptions = generateOptionsByIndex(data.charIndex + 1)

  // 如果 index 等于长度，则表示做完了
  if (data.charIndex === props.face.card.word.length) {
    data.checked = true
    data.star = data.tipTimes > 0 ? FeedbackStar.Two : FeedbackStar.Three
    onStar?.(data.star)
    onEvent?.(UnitEventType.CORRECT)
    onAutoNext(_am.playPron(props.face.card.word))
    vibrate(VibrateType.Success)
  }
}

onMounted(async () => {
  await _am.preload([{ type: 'pron', text: props.face.card.word }])
})
</script>

<style scoped>
.spelling {
  width: fit-content;
  margin: 0px auto;
  border-bottom: 1px solid black;
  color: transparent;
  font-size: 36px;
  font-weight: 600;
  letter-spacing: 2%;
  padding: 0px 8px;
}

.option {
  width: 64px;
  height: 64px;
  position: relative;
}
.option.wrong {
  animation: shake 0.2s linear 0.2s;
}

.options-slide-enter-active,
.options-slide-enter-active .options-row,
.options-slide-leave-active .options-row,
.options-slide-leave-active {
  transition: all 100ms; /* 过渡效果的时间 */
}

.options-slide-enter-from .options-row.second {
  opacity: 0;
  transform: translateY(100%);
}
.options-slide-enter-to .options-row.second {
  opacity: 1;
  transform: translateY(0%);
}

.options-slide-leave-from .options-row.first {
  opacity: 1;
  transform: translateY(0%);
}
.options-slide-leave-to .options-row.first {
  opacity: 0;
  transform: translateY(-100%); /* 最终位置 */
}

.options-slide-leave-from .options-row.second {
  transform: translateY(0%);
}
.options-slide-leave-to .options-row.second {
  transform: translateY(calc(-100% - 12px)); /* 最终位置 */
}
</style>
