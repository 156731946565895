<template>
  <div class="bg-ld-background pb-4 overflow-x-hidden">
    <div
      v-if="store.energy"
      class="flex flex-col items-center w-full pt-20px px-4 relative h-190px"
    >
      <div class="circle-bg"></div>

      <Icon
        name="close-circle"
        class="cursor-pointer absolute right-2 top-2 z-1 w-25px"
        @click="emit('done')"
      ></Icon>

      <div
        class="rounded-full w-81px h-81px relative overflow-hidden flex items-center justify-center"
      >
        <div class="absolute w-full h-full top-0 left-0 bg-orange-100"></div>

        <Icon
          name="energy"
          class="w-56px heartbeat relative top-1"
        />
      </div>

      <ProgressBar
        :percent="energyPercent"
        color="linear-gradient(269.98deg, var(--yellow-600) 0.01%, var(--yellow-500) 99.98%)"
        :highlight="false"
        :lottie="false"
        :text="`${store.energy.currentEnergy}/${store.energy.energyLimit}`"
        :border-radius="50"
        class="progress-container"
      />

      <span
        class="text-19px leading-[1.4] text-yellow-700 font-semibold relative"
      >
        <template v-if="energyTimerLabel">
          {{ _t('energy.add_timer', { label: energyTimerLabel }) }}
        </template>
        <template v-else>{{ _t('energy.full_tip') }}</template>
      </span>
    </div>

    <div
      v-if="props.showFreeEnergy && !freeEnergyUsed"
      class="flex flex-col items-center justify-center px-4 mt-56px"
    >
      <Img
        name="ld-free-energy"
        class="w-135px"
      />

      <div class="mt-3 mb-2 text-21px font-semibold">
        {{ _t('energy.not_enough_tip') }}
      </div>

      <div class="text-19px text-center mb-52px">
        <div>{{ _t('energy.not_enough_tip2') }}</div>
        <div>{{ _t('energy.not_enough_tip3') }}</div>
        <div>{{ _t('energy.not_enough_tip4') }}</div>
      </div>

      <Button
        class="whitespace-nowrap w-full"
        scene="vip"
        :label="_t('energy.receive')"
        @click="onClaimFreeEnergy()"
      ></Button>
    </div>

    <template v-else>
      <div class="flex flex-col gap-2 px-4 bg-ld-background">
        <div class="text-17px font-semibold text-ld-label-secondary">
          {{ _t('energy.replenish') }}
        </div>

        <div class="w-full energy-buy">
          <template v-if="config != null">
            <div class="flex flex-col items-center">
              <Icon
                name="energy-add"
                class="w-50px"
              />
              <span
                class="text-17px font-semibold text-yellow-900 leading-[1.4]"
              >
                {{ _t('energy.add', { count: config.energy }) }}
              </span>
            </div>

            <div class="ml-auto flex gap-6px">
              <template v-if="!store.isVipValid">
                <Button
                  :loading="diamondExchangeLoading"
                  @click="onEnergyExchangeByDiamonds(config)"
                >
                  <Icon
                    name="diamond"
                    class="w-24px mr-1"
                  />
                  <span class="font-semibold">
                    {{ config.diamond }}
                  </span>
                </Button>

                <Button
                  scene="vip"
                  @click="onVipBuy"
                >
                  {{ _t('vip.learn_tip') }}
                </Button>
              </template>

              <template v-else-if="store.vipEnergyFreeTimes > 0">
                <div class="flex flex-col items-center">
                  <div
                    class="text-[var(--text-color-secondary)] text-15px leading-[1em] font-semibold mb-2"
                  >
                    {{
                      _t('vip.free_energy_times', {
                        count: store.vipEnergyFreeTimes,
                      })
                    }}
                  </div>
                  <Button
                    scene="vip"
                    :loading="vipFreeExchangeLoading"
                    @click="onEnergyExchangeByVipFree"
                  >
                    {{ _t('energy.add_free') }}
                  </Button>
                </div>
              </template>
              <template v-else>
                <div class="flex flex-col items-center">
                  <div
                    class="text-[var(--text-color-secondary)] text-15px leading-[1em] font-semibold mb-2"
                  >
                    {{ _t('vip.free_energy_done') }}
                  </div>

                  <Button
                    :loading="diamondExchangeLoading"
                    @click="onEnergyExchangeByDiamonds(config)"
                  >
                    <Icon
                      name="diamond"
                      class="w-24px mr-1"
                    />
                    <span class="font-semibold">
                      {{ config.diamond }}
                    </span>
                  </Button>
                </div>
              </template>
            </div>
          </template>
        </div>
      </div>

      <div class="flex flex-col gap-2 px-4 bg-ld-background mt-6">
        <div class="text-17px font-semibold text-ld-label-secondary">
          {{ _t('energy.increase_limit') }}
        </div>

        <div
          v-if="energyLimitConfig"
          class="box bg-cyan-100 text-cyan-800"
        >
          <template v-if="!isEnergyLimitAddedFull">
            <div class="text-19px font-semibold mb-1">
              {{ _t('energy.increase_limit_friend') }}
            </div>
            <div class="text-15px font-semibold mb-3">
              {{
                _t('energy.increase_limit_max', {
                  max: energyLimitConfig.maxEnergyLimitCapacity,
                })
              }}
            </div>

            <div class="w-full flex items-center gap-6px">
              <Button
                class="flex-1 invite-btn"
                scene="invite"
                :label="_t('energy.invite_friend')"
                @click="onInviteFirend"
              ></Button>
            </div>
          </template>

          <div
            v-else
            class="w-full flex items-center justify-between leading-[1.8]"
          >
            <div class="flex items-center">
              <Icon
                name="vip-check"
                class="w-20px text-cyan-600"
              />
              <div class="ml-1 text-15px font-semibold text-cyan-600">
                {{ _t('energy.increase_limit_full') }}
              </div>
            </div>

            <div class="text-19px font-semibold text-cyan-800">
              {{
                _t('energy.increase_limit_count', {
                  count: energyLimitConfig.addedEnergyLimit,
                })
              }}
            </div>
          </div>
        </div>

        <div class="box bg-yellow-100 text-yellow-800">
          <template v-if="!store.isVipValid">
            <div class="text-21px font-semibold mb-6px">
              {{
                _t('energy.increase_limit_tip2', {
                  count: vipEnergyLimitIncrease,
                })
              }}
            </div>

            <Button
              class="w-full mt-4"
              :label="_t('vip.buy_immediately')"
              scene="vip"
              @click="onVipBuy"
            />
          </template>

          <div
            v-else
            class="w-full flex items-center justify-between leading-[1.8]"
          >
            <div class="flex items-center">
              <Icon
                name="vip-check"
                class="w-20px text-ld-premium-600"
              />
              <div class="ml-1 text-15px font-semibold text-ld-premium-600">
                {{ _t('vip.valid_tip') }}
              </div>
            </div>

            <div class="text-19px font-semibold text-ld-premium-800">
              {{
                _t('energy.increase_limit_count', {
                  count: vipEnergyLimitIncrease,
                })
              }}
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script setup lang="ts">
import {
  fetchDiamondExchangeConfigs,
  type DiamondExchangeConfig,
  exchangeEnergy,
} from '@/api/order'
import { useCommonStore } from '@/stores'
import { computed, ref } from 'vue'
import { Code } from '@/api/code'
import {
  claimFreeEnergy,
  UserFlag,
  vipExchangeEnergyFree,
  type EnergyResponse,
} from '@/api/user'
import ProgressBar from './ProgressBar.vue'
import { openStore } from '@/shared'
import {
  fetchEnergyLimitExchangeConfig,
  type EnergyLimitExchangeConfig,
} from '@/api/product'
import type { CommonResponse } from '@/api/base'
import RcodeInvitationDialog from './RcodeInvitationDialog.vue'

const store = useCommonStore()
const diamondConfigs = ref<DiamondExchangeConfig[]>([])
const energyLimitConfig = ref<EnergyLimitExchangeConfig>()
const diamondExchangeLoading = ref(false)
const vipFreeExchangeLoading = ref(false)

const props = withDefaults(
  defineProps<{
    // 是否展示首次的免费体力领取
    showFreeEnergy?: boolean
  }>(),
  {
    showFreeEnergy: false,
  }
)

const emit = defineEmits<{
  done: []
}>()

const config = computed(() => diamondConfigs.value[0])

const freeEnergyUsed = computed(
  () => store.user!.flag?.[UserFlag.FreeEnergyUsed] ?? false
)

const energyTimerLabel = computed(() => {
  if (store.energyCountDown == null) return ''

  const minsLabel = Math.floor(store.energyCountDown / 60).toString()
  const secondsLabel = (store.energyCountDown % 60).toString()
  return `${minsLabel.padStart(2, '0')}:${secondsLabel.padStart(2, '0')}`
})

const energyPercent = computed(() => {
  if (store.energy == null) return 0

  return Math.min(
    (store.energy.currentEnergy / store.energy.energyLimit) * 100,
    100
  )
})

const isEnergyLimitAddedFull = computed(() => {
  if (energyLimitConfig.value == null) return false

  return (
    energyLimitConfig.value.addedEnergyLimit >=
    energyLimitConfig.value.maxEnergyLimitCapacity
  )
})

const vipEnergyLimitIncrease = computed(() => {
  return store.storeConfig?.vipPrivilegeResponse.addEnergyLimit ?? 0
})

function fetchConfigs() {
  store.fetchVipLeftFreeTimes()
  fetchDiamondExchangeConfigs().then(res => {
    diamondConfigs.value = res.items
  })
  fetchEnergyLimitExchangeConfig().then(res => {
    energyLimitConfig.value = res
  })
}

function onInviteFirend() {
  _openDialog(RcodeInvitationDialog, {
    rootClass: 'g-dialog',
    dialog: {
      showHeader: false,
      pt: {
        content: { class: 'p-3 !bg-white' },
      },
    },
  })
}

async function onVipBuy() {
  openStore()
}

function onEnergyExchangeByVipFree() {
  if (vipFreeExchangeLoading.value) return

  vipFreeExchangeLoading.value = true
  vipExchangeEnergyFree()
    .then(res => {
      if (res.code !== Code.Ok) {
        _message.info(res.message)
        return
      }

      onEnergyUpdate(res)
      store.costVipEnergyFreeTimes()
      store.setEnergyStatus(res.data)
    })
    .finally(() => {
      vipFreeExchangeLoading.value = false
    })
}

async function onEnergyExchangeByDiamonds(config: DiamondExchangeConfig) {
  if (diamondExchangeLoading.value) return

  diamondExchangeLoading.value = true
  exchangeEnergy(config.configId)
    .then(onEnergyUpdate)
    .finally(() => {
      diamondExchangeLoading.value = false
    })
}

function onEnergyUpdate(res: CommonResponse<EnergyResponse>) {
  if (res.code !== Code.Ok) {
    if (res.code === Code.NotEnoughDiamonds) {
      _openDiamondGetDialog()
    }

    _message.info(res.message)
    return
  }

  _am.playAudio('energy_gain')

  store.setEnergyStatus(res.data)
  store.fetchUserAssets()
}

function onClaimFreeEnergy() {
  claimFreeEnergy().then(res => {
    if (res.code !== Code.Ok) {
      _message.info(res.message)
      return
    }

    store.setEnergyStatus(res.data.currentEnergy)
    _message.info(_t('energy.add', { count: res.data.addedEnergy }))

    store.user!.flag = {
      ...store.user!.flag,
      [UserFlag.FreeEnergyUsed]: true,
    } as any
    _am.playAudio('energy_gain')
  })
}

onInit(fetchConfigs)
</script>

<style scoped>
.circle-bg {
  position: absolute;
  top: 0px;
  left: -50%;
  width: 200%;
  height: 100%;
  background-color: var(--orange-50);
  border-bottom-left-radius: 100%;
  border-bottom-right-radius: 100%;
}
.progress-container {
  width: 142px;
  height: 33px;
  border-radius: 50px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid white;
  top: -4px;
}

.progress-container :deep(span) {
  font-family: DIN;
  font-weight: 700;
  font-size: 19px;
}

.energy-count {
  font-size: 19px;
  font-weight: 700;
  line-height: 1;
  color: white;
  font-family: DIN;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: var(--red-600);
}

.energy-buy {
  height: 90px;
  display: flex;
  align-items: center;
  padding: 8px 12px;
  background: white;
  border-radius: 12px;
  box-shadow:
    1px 1px 2px 0px var(--slate-300) inset,
    -1px -1px 2px 0px var(--slate-300) inset;
}

.box {
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;
  box-shadow: 0px 2px 4px 0px var(--slate-300);
  border-bottom: 2px solid var(--ld-border);
}

.heartbeat {
  animation: heartbeat 2s infinite;
}

@keyframes heartbeat {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
</style>
