<template>
  <div
    class="flex items-center bg-ld-brand-200 p-3 justify-between cursor-pointer"
    @click="onPackageUnlock"
  >
    <div class="text-ld-brand-600 text-15px flex-1 mr-26px">
      {{ _t('package.changed_tip') }}
    </div>

    <Button
      :label="_t('package.publish_change')"
      :size="isPcMode ? 'normal' : 'small'"
    ></Button>
  </div>
</template>
<script setup lang="ts">
import type { PackageBasic } from '@/api/package-source'
import ShareForm from './PkgList/ShareForm.vue'

const isPcMode = _global.isPcMode

const props = defineProps<{
  package: PackageBasic
}>()

const emit = defineEmits<{
  onUpdate: [PackageBasic]
}>()

function onPackageUnlock() {
  const pkg = props.package

  _openDialog(ShareForm, {
    title: '共享',
    rootClass: 'p-0 g-dialog',
    dialog: {
      showHeader: false,
      contentClass: 'px-4 py-3',
    },
    props: {
      package: pkg,
      onUpdate: (pkgRes: PackageBasic) => {
        emit('onUpdate', pkgRes)
      },
    },
  })
}
</script>
<style scoped></style>
