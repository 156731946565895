<template>
  <div class="bg-ld-background flex flex-col mx-auto h-full">
    <SafeAreaTopSpacer v-if="!isPcMode" />

    <div
      v-if="isPcMode"
      class="flex items-baseline m-x-4"
    >
      <div class="text-28px font-bold mr-auto">消息</div>

      <div
        :class="[
          'cursor-pointer text-15px',
          data != null && data.msgs.every(msg => msg.readAt)
            ? 'text-[var(--text-color-secondary)]'
            : 'text-primary',
        ]"
        @click="readAllMsg"
      >
        全部已读
      </div>
    </div>
    <AppBar
      v-else
      title="消息"
      @back="clickBack"
    >
      <template #actions>
        <div
          :class="[
            'cursor-pointer text-15px',
            data != null && data.msgs.every(msg => msg.readAt)
              ? 'text-[var(--text-color-secondary)]'
              : 'text-primary',
          ]"
          @click="readAllMsg"
        >
          全部已读
        </div>
      </template>
    </AppBar>

    <Loading
      v-if="!data"
      class="h-full"
    />

    <RatioSpacedContainer
      v-else-if="data.msgs.length === 0"
      class="h-full"
    >
      <Empty text="暂无消息" />
    </RatioSpacedContainer>

    <div
      v-else
      class="flex flex-col h-full p-4 space-y-4 overflow-y-auto"
    >
      <div
        v-for="msg in data.msgs"
        :key="msg.id"
        class="msg-item relative"
        @click="clickMsg(msg)"
      >
        <Badge
          v-if="!msg.readAt"
          severity="danger"
          class="absolute top-2 right-2 !w-8px !min-w-8px !h-8px"
        />
        <MessageContent
          :content="msg.content"
          :class="{ read: msg.readAt }"
        />
        <div class="time">
          {{ formatTime(msg.createdAt) }}
        </div>
      </div>

      <TextButton
        v-if="data != null && data.total > data.msgs.length"
        label="加载更多"
        @click="loadMore"
      ></TextButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  fetchMsgsPage,
  readAllMsgs,
  readMsg,
  type MsgResponse,
  type PageMsgResponse,
} from '@/api/msg'
import Empty from '@/components/Empty.vue'
import Loading from '@/components/Loading.vue'
import MessageContent from '@/components/MessageContent.vue'
import AppBar from '@/mobile/components/AppBar.vue'
import { useCommonStore } from '@/stores'
import { formatDate } from '@/utils'
import Badge from 'primevue/badge'
import { ref } from 'vue'
import { useRouter } from 'vue-router'
const router = useRouter()

const data = ref<PageMsgResponse>()
const store = useCommonStore()

const isPcMode = _global.isPcMode

async function loadNew() {
  data.value = undefined
  store.fetchUnreadMsgCount()
  fetchMsgsPage(0).then(res => {
    data.value = res
  })
}

loadNew()

async function loadMore() {
  const oldData = data.value
  if (oldData == null) {
    return
  }
  const res = await fetchMsgsPage(oldData.offset + oldData.limit)
  data.value = {
    ...res,
    msgs: [...oldData.msgs, ...res.msgs],
  }
}

// 时间显示
const formatTime = (time: string) => {
  return formatDate(time)
}

function clickBack() {
  if (router.canGoBack) {
    router.back()
  } else {
    router.replace({ name: 'mine' })
  }
}
async function readAllMsg() {
  data.value?.msgs.forEach(msg => {
    if (!msg.readAt) {
      msg.readAt = new Date().toISOString()
    }
  })
  await readAllMsgs()
  store.clearUnreadMsgCount()
}
async function clickMsg(msg: MsgResponse) {
  if (!msg.readAt) {
    // 乐观更新
    msg.readAt = new Date().toISOString()
    await readMsg(msg.id, msg.type)
    store.decreaseUnreadMsgCount()
  }
}
</script>

<style scoped>
.msg-item {
  padding: 12px;
  border-radius: 8px;
  background-color: white;
}

.time {
  font-size: 14px;
  color: var(--text-color-secondary);
}

.read {
  color: var(--text-color-secondary);
}
</style>
