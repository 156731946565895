<template>
  <div class="h-full flex flex-col bg-ld-background">
    <AppBar
      :title="_t('notification.title')"
      @back="onBack"
    />
    <div class="flex flex-col p-4 gap-6">
      <div>
        <ListCell
          :title="_t('notification.title')"
          :arrow="false"
        >
          <template #action>
            <InputSwitch
              :model-value="store.notiConfig.enabled"
              @update:modelValue="onNotificationSwitch"
            />
          </template>
        </ListCell>
      </div>

      <div v-if="store.notiConfig.enabled">
        <ListCell
          :title="_t('notification.daily')"
          :arrow="false"
        >
          <template #action>
            <InputSwitch
              :model-value="store.notiConfig.dailyReminder.enabled"
              @update:modelValue="onSwitchDailyReminder"
            />
          </template>
        </ListCell>

        <ListCell
          :title="_t('notification.time')"
          :arrow="false"
          @click="onSetDailyReminderTime"
        >
          <template #action>
            <div class="flex items-center justify-center">
              <div>{{ dailyReminderTime }}</div>
              <i class="pi pi-angle-right text-20px"></i>
            </div>
          </template>
        </ListCell>
      </div>
      <div v-if="showRank">
        <ListCell
          :title="_t('notification.rank')"
          :arrow="false"
        >
          <template #action>
            <InputSwitch
              :model-value="store.notiConfig.rank.enabled"
              @update:modelValue="onSwitchRank"
            />
          </template>
        </ListCell>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import ListCell from '@/components/ListCell/ListCell.vue'
import AppBar from '@/mobile/components/AppBar.vue'
import { useCommonStore } from '@/stores'
import { computed, onUnmounted } from 'vue'
import TimePickerSheet from '@/components/TimePickerSheet.vue'
import {
  tryToEnableNotification,
  handleRankChange,
  handleDailyReminderChange,
  onNotificationsEnabled,
  onNotificationsDisabled,
} from '@/utils/notification'
import { formatDate } from '@/utils'
import { ref } from 'vue'

const store = useCommonStore()

const abortController = new AbortController()

onUnmounted(() => {
  abortController.abort()
})

// 提醒时间显示 HH:mm
const dailyReminderTime = computed(() => {
  const { hour, minute } = store.notiConfig.dailyReminder.time
  const date = new Date()
  date.setHours(hour)
  date.setMinutes(minute)

  return formatDate(date, 'HH:mm')
})

const hasDeviceInfo = ref(false)

const showRank = computed(() => {
  return store.notiConfig.enabled && hasDeviceInfo.value
})

onInit(() => {
  _bridge.checkNotificationStatus().then(status => {
    // 如果通知未开启，则关闭所有通知
    if (status !== 'enabled') {
      store.notiConfig.enabled = false
    }
  })
  _bridge.has('deviceInfo').then(has => {
    hasDeviceInfo.value = has
  })
})

async function onNotificationSwitch(enabled: boolean) {
  if (enabled) {
    const status = await _bridge.checkNotificationStatus()

    if (status === 'enabled') {
      onNotificationsEnabled()
    } else {
      tryToEnableNotification(abortController.signal)
        .then(status => {
          if (status === 'enabled') {
            onNotificationsEnabled()
          }
        })
        .catch(err => {
          _reportError(
            new Error('failed to enable notification', { cause: err })
          )
        })
    }
  } else {
    onNotificationsDisabled()
  }
}

// 【每日定时提醒】
function onSwitchDailyReminder(enabled: boolean) {
  store.notiConfig.dailyReminder.enabled = enabled
  handleDailyReminderChange()
}
// 设置提醒时间
async function onSetDailyReminderTime() {
  const time = await _presentContent<{
    hour: number
    minute: number
  }>(TimePickerSheet, {
    rootClass: 'min-w-400px max-w-600px',
    props: {
      title: _t('notification.time'),
      initialTime: store.notiConfig.dailyReminder.time,
    },
  })

  if (time) {
    store.notiConfig.dailyReminder.time = time
    handleDailyReminderChange()
  }
}

// 【排位赛状态更新】
function onSwitchRank(enabled: boolean) {
  store.notiConfig.rank.enabled = enabled
  handleRankChange()
}

const emit = defineEmits<{
  back: []
}>()

function onBack() {
  emit('back')
}
</script>

<style scoped></style>
