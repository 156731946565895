<template>
  <BossBattle
    :face="data.activeFrame?.face"
    :combo="combo"
    :npc="{
      hp: data.npcHp,
      avatar: npcAvatar,
      name: displayNpcName,
    }"
    :player="{
      hp: data.playerHp,
      avatar: store.user!.avatar,
      name: store.user?.nickname,
    }"
    @event="onEventCollect"
    @star="onStar"
    @next="onNext"
    @close="emit('close')"
  ></BossBattle>
</template>

<script lang="ts" setup>
import { UnitEventType, QueueType } from '@/types/core'
import { provide, reactive, computed } from 'vue'
import { useCommonStore } from '@/stores'
import { DuelQueue, type Frame } from './queue'
import { handleUnitCancelled, completeReport } from '../report'

import db from '@/db'
import { useRouter } from 'vue-router'
import BossBattle from '@/components/BossBattle.vue'
import {
  getBossAvatar,
  getBossDisplayName,
  vibrate,
  VibrateType,
} from '@/utils'
import { FeedbackStar } from '@/components/ConcreteCard/common/feedback'

const emit = defineEmits<{
  close: []
  leftBloodUpdate: [number]
}>()

const store = useCommonStore()
const router = useRouter()

let queue: DuelQueue

const data = reactive({
  activeFrame: null as Frame | null,
  npcHp: 100,
  playerHp: 100,
})

const combo = computed(() => store.stageUnit?.currentComboCount ?? 0)

const npcName = computed(() => {
  return store.stageUnit?.challenge?.npcName
})

const npcAvatar = computed(() => {
  if (npcName.value) {
    return getBossAvatar(npcName.value)
  }
  return undefined
})

const displayNpcName = computed(() => {
  if (!npcName.value) return ''
  return getBossDisplayName(npcName.value)
})

// 传入 boss name
// boss 阶段/挑战模式 问题顶部的间距改为 8px
provide('bossName', store.stageUnit?.challenge?.npcName)

function loadUnit() {
  if (!store.checkStageUnit()) {
    _global.unreachable(store.setStageUnit)
  }

  queue = new DuelQueue(store.stageUnit!.schedules)
  const progressCache = db.unitProgressCacheV2[QueueType.Duel]

  if (progressCache) {
    queue.setState(progressCache)
    onTick()
  }

  data.activeFrame = queue.currentFrame()
}

loadUnit()

function onEventCollect(cardId: number | undefined, event: UnitEventType) {
  // 答对答错时震动
  if (event === UnitEventType.CORRECT) {
    vibrate(VibrateType.Success)
  }
  if (event === UnitEventType.WRONG) {
    vibrate(VibrateType.Warning)
  }
  if (!data.activeFrame || cardId == null) return

  store.insertLearningEvent(cardId, {
    event,
    timestamp: Date.now(),
  })
  queue.tick(event)
  onTick()
}

function onStar(star: FeedbackStar) {
  if (star === FeedbackStar.Three) {
    const newCombo = combo.value + 1
    store.setComboCount(newCombo)
  } else {
    store.setComboCount(0)
  }
}

function onTick() {
  data.npcHp = queue.npcHpPercent
  data.playerHp = queue.playerHpPercent

  const state = queue.getState()
  db.unitProgressCacheV2[QueueType.Duel] = state

  if (queue.isVictory) {
    onVictory()
  }

  if (queue.isDefeated) {
    onDefeated()
  }
}

function onNext() {
  data.activeFrame = queue.currentFrame()
}

async function onDefeated() {
  _confirm({
    scene: 'warn',
    icon: {
      name: 'ld-cry',
      type: 'img',
    },
    content: _t('learn.duel_failed'),
    primaryText: 'duel.again',
    secondaryText: 'duel.quit',
    async onPrimaryClick(resolve) {
      resolve(true)
      handleUnitCancelled()

      router.push({
        name: 'atlas',
        query: {
          challengeStage: store.stageUnit?.challenge?.index,
          preStart: 'true',
        },
      })
    },
    async onSecondaryClick(resolve) {
      resolve(true)
      handleUnitCancelled()

      router.push({
        name: 'atlas',
      })
    },
  })
}

function onVictory() {
  const cardIds = (store.stageUnit?.schedules ?? []).map(item => item.cardId)

  for (const id of cardIds) {
    store.completeCard(id)
  }
  completeReport(queue.leftBlood)
  emit('leftBloodUpdate', queue.leftBlood)

  delete db.unitProgressCacheV2[QueueType.Duel]
}
</script>

<style scoped></style>
