<template>
  <div class="header">
    <div>{{ _t('atlas.level', { index: props.stage.level }) }}</div>

    <Icon
      name="close"
      class="cursor-pointer absolute right-0 w-20px"
      @click="onClose"
    ></Icon>
  </div>

  <div class="vstack">
    <PkgIcon
      :style="props.stage.pkgInfo.style"
      class="w-78px h-78px mt-24px text-66px"
    />

    <div class="break-all font-bold text-xl">
      {{ props.stage.pkgInfo.name }}
    </div>
    <div class="text-[var(--text-color-secondary)]">
      {{
        _t('atlas.stage_finish_time', {
          time: $f.date(props.stage.completedAt, 'YYYY-MM-DD'),
        })
      }}
    </div>
  </div>

  <Button
    class="button w-full mt-4"
    scene="secondary"
    :label="_t('atlas.review')"
    @click="onUnitReview"
  ></Button>
</template>
<script setup lang="ts">
import PkgIcon from './PkgIcon.vue'
import { type Stage } from '@/api/atlas'
import UnitReview from '@/pc/components/UnitReview.vue'
import MobileUnitReview from '@/mobile/pages/UnitReview.vue'

const props = defineProps<{
  stage: Stage
}>()

const emit = defineEmits<{
  done: []
}>()

function onClose() {
  emit('done')
}

function onUnitReview() {
  emit('done')

  if (_global.isPcMode) {
    _openDialog(UnitReview, {
      rootClass: 'min-w-600px max-w-900px min-h-600px',
      props: {
        stageId: props.stage.stageId,
      },
      dialog: {
        showHeader: false,
        pt: {
          content: {
            style: 'flex: 1; padding: 0px;',
          },
        },
      },
    })
  } else {
    _openDialog(MobileUnitReview, {
      fullscreenInMobile: true,
      props: {
        stageId: props.stage.stageId,
      },
      dialog: {
        showHeader: false,
        pt: {
          content: {
            style: 'padding: 0px;',
          },
        },
      },
    })
  }
}
</script>
<style scoped>
.button :deep(button) {
  white-space: nowrap;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 19px;
  font-weight: 600;
  line-height: 24px;
  position: relative;
}
</style>
