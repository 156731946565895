<template>
  <div
    class="bg-[var(--primary-100)] flex flex-col items-center justify-center gap-2px rounded-8px"
  >
    <span class="title">
      {{ _t('cardview.ai_loading') }}
      <span class="dots">{{ '.'.repeat(loadingDotCount) }}</span>
    </span>
    <span
      class="cancel"
      @click="emit('cancel')"
    >
      {{ _t('cardview.ai_cancel') }}
    </span>
  </div>
</template>
<script setup lang="ts">
import { onMounted, onUnmounted, ref } from 'vue'

const emit = defineEmits<{
  cancel: []
}>()

const MIN_DOTS_COUNT = 1
const MAX_DOTS_COUNT = 6

const loadingDotCount = ref(MIN_DOTS_COUNT)
let loadingTimer: number

onMounted(() => {
  loadingTimer = setInterval(() => {
    loadingDotCount.value++

    if (loadingDotCount.value > MAX_DOTS_COUNT) {
      loadingDotCount.value = MIN_DOTS_COUNT
    }
  }, 400)
})

onUnmounted(() => {
  clearInterval(loadingTimer)
})
</script>
<style scoped>
.title {
  font-size: 21px;
  font-weight: 600;
  line-height: 29.4px;
  text-align: center;
  color: var(--primary-color);
  position: relative;
}

.dots {
  position: absolute;
  left: calc(100% + 4px);
  top: 0px;
}

.cancel {
  color: var(--text-color-secondary);
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
  cursor: pointer;
}
</style>
