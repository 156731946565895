<template>
  <Loading
    v-if="loading"
    class="h-full"
  />

  <div
    v-else-if="rankHome != null"
    class="bg-ld-background h-full"
  >
    <Guide
      v-if="showGuide"
      @start="onRankStart"
    />

    <FeatureDisabled
      v-else-if="rankHome.type === RankHomeStatus.RankFeatureDisabled"
      :rankHome="rankHome"
    />

    <NotClaimed
      v-else-if="rankHome.type === RankHomeStatus.NotClaimed"
      :rankHome="rankHome"
      @rewardClaimed="onRewardClaimed"
      @rankKeeped="onRewardClaimed"
    />

    <InGroup
      v-else-if="rankHome.type === RankHomeStatus.InGroup"
      :rankHome="rankHome"
    />

    <FreezeTime
      v-else-if="rankHome.type === RankHomeStatus.FreezeTime"
      :rankHome="rankHome"
    />

    <BreakTime
      v-else-if="rankHome.type === RankHomeStatus.BreakTime"
      :rankHome="rankHome"
    />

    <NotQualified
      v-else-if="rankHome.type === RankHomeStatus.NotQualified"
      :rankHome="rankHome"
    />
  </div>
</template>
<script lang="ts" setup>
import { computed, ref } from 'vue'
import FeatureDisabled from './FeatureDisabled.vue'
import NotClaimed from './NotClaimed.vue'
import InGroup from './InGroup.vue'
import { RankHomeStatus, type RankHome } from '@/types/rank'
import { fetchRankHome } from '@/api/rank'
import FreezeTime from './FreezeTime.vue'
import BreakTime from './BreakTime.vue'
import NotQualified from './NotQualified.vue'
import Guide from './Guide.vue'
import { useCommonStore } from '@/stores'
import { setUserFlag, UserFlag } from '@/api/user'

const store = useCommonStore()

const rankHome = ref<RankHome>()
const loading = ref(true)

const showGuide = computed(() => store.showRankGuide)

function onRewardClaimed() {
  fetchRankData().then(() => {
    if (rankHome.value?.type === RankHomeStatus.InGroup) {
      _message.info(_t('rank.msg12'))
    }
  })
}

function fetchRankData() {
  loading.value = true

  return fetchRankHome()
    .then(res => {
      rankHome.value = res
    })
    .finally(() => {
      loading.value = false
    })
}

function onRankStart() {
  setUserFlag(UserFlag.RankGuideSeen).then(res => {
    store.user!.flag = res.flag
  })

  // 先重新拿一次最新的排位赛信息
  fetchRankData().then(() => {
    store.completeRankGuide()

    if (rankHome.value?.type === RankHomeStatus.InGroup) {
      _message.info(_t('rank.msg13'))
    }
  })
}

onInit(() => {
  fetchRankData()
})
</script>
<style scoped></style>
