<template>
  <div class="h-full overflow-auto flex flex-col px-4 bg-ld-background">
    <RatioSpacedContainer
      class="flex-1 w-full g-body-width"
      :top="4"
      :bottom="6"
    >
      <div class="flex flex-col w-full">
        <AuthForm
          class="px-4 mx-auto py-10 w-full"
          :agreement-checked="agreementChecked"
          @handle-login-without-agreement-checked="
            handleLoginWithoutAgreementChecked
          "
          @done="onAuthDone"
        />
        <AuthFooter
          v-model:isAgree="isAgree"
          :action="_t('auth.login')"
          :showAgree="isAndroid"
          :showBubble="showBubble"
          @update:is-agree="onIsAgree"
        />
      </div>
    </RatioSpacedContainer>

    <MoreLoginMethods
      v-if="showAppleLogin || isWeChatInstalled"
      class="mb-84px g-body-width"
      :apple-login="showAppleLogin"
      :user-login="false"
      :we-chat-login="isWeChatInstalled"
      @apple-login="onAppleLogin"
      @wechat-login="onWechatLogin"
    />
  </div>
</template>

<script lang="ts" setup>
import { appleLogin, type UserInfo } from '@/api/auth'
import AuthFooter from '@/components/AuthFooter.vue'
import AuthForm from '@/components/AuthForm/AuthForm.vue'
import MoreLoginMethods from '@/components/inapp/MoreLoginMethods.vue'
import { afterLogin } from '@/shared/landing'
import { useCommonStore } from '@/stores'
import { Platform } from '@/utils/bridge'
import { computed, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

// query:
//  - redirect?: redirect url

const route = useRoute()
const router = useRouter()
const store = useCommonStore()
const isAgree = ref(false)
const isWeChatInstalled = ref<boolean>()
const isAndroid = computed(() => store.appInfo?.platform === Platform.Android)
const showAppleLogin = computed(() => store.isIOSReviewing)

const showBubble = ref(false)

const agreementChecked = computed(() => {
  if (isAndroid.value) {
    return isAgree.value
  } else {
    return true
  }
})

onInit(() => {
  _bridge.isWeChatInstalled().then(res => {
    isWeChatInstalled.value = res
  })
})

async function onAuthDone(token: string, user: UserInfo) {
  store.login(token, user)
  afterLogin(router, route)
}

function handleLoginWithoutAgreementChecked() {
  showBubble.value = true
}

async function onWechatLogin() {
  if (isAndroid.value && !isAgree.value) {
    handleLoginWithoutAgreementChecked()
    return
  }

  const code = await _bridge.wechatAuth()
  if (!code) {
    return
  }

  router.replace({
    name: 'wxcallback',
    query: { code, wechatType: 'APP' },
  })
}

async function onAppleLogin() {
  const identityToken = await _bridge.appleAuth()
  if (!identityToken) {
    return
  }
  const res = await appleLogin({ identityToken })

  if (res.code !== 0) {
    _message.info(res.message)
    return
  }

  if (res.data) {
    onAuthDone(res.data.token, res.data.user)
  } else {
    _message.info('登录失败')
  }
}
function onIsAgree(value: boolean) {
  if (value) {
    showBubble.value = false
  }
}
</script>

<style scoped></style>
