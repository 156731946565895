<template>
  <div class="flex flex-col justify-center items-center min-w-300px gap-4">
    <div class="w-full flex items-center relative">
      <span class="mx-auto">{{ _t('rcode.title') }}</span>

      <Icon
        name="close-circle"
        class="cursor-pointer absolute right-0 w-22px"
        @click="emit('done')"
      ></Icon>
    </div>

    <div class="w-full flex items-center gap-2">
      <InputText
        v-model="rcode"
        class="flex-1"
        :placeholder="_t('rcode.input_placeholder')"
      />

      <Button
        scene="secondary"
        size="small"
        :label="_t('common.paste')"
        @click="onPaste"
      ></Button>
    </div>

    <Button
      class="w-full"
      :label="_t('rcode.receive')"
      :loading="isConsuming"
      @click="onRcodeConsume"
    ></Button>
  </div>
</template>
<script setup lang="ts">
import { ref } from 'vue'
import InputText from 'primevue/inputtext'
import { consumeRcode } from '@/api/rcode'
import { Code } from '@/api/code'
import { RewardType } from '@/api/task'

const emit = defineEmits<{
  done: []
}>()

const rcode = ref('')
const isConsuming = ref(false)

async function onPaste() {
  const clipboardContentArray = await navigator.clipboard.read()

  if (clipboardContentArray.length == 0) {
    return
  }

  const clipboardContent = clipboardContentArray[0]

  const text = await (await clipboardContent.getType('text/plain')).text()
  rcode.value = text
}

function onRcodeConsume() {
  isConsuming.value = true

  consumeRcode(rcode.value)
    .then(res => {
      if (res.code !== Code.Ok) {
        _message.info(res.message)
        return
      }

      _showRewards([
        {
          type: RewardType.DIAMOND,
          value: res.data.diamonds,
        },
      ])
    })
    .finally(() => {
      isConsuming.value = false
    })
}
</script>
<style scoped></style>
