<template>
  <RatioSpacedContainer v-if="props.packages.length === 0">
    <Empty
      :text="props.emptyHint"
      :desc="_t('package.create_tip7')"
    ></Empty>
  </RatioSpacedContainer>

  <!--
    这里加一层的目的是当外层设置了高度时，如果高度超出了一个元素的高度
    会导致 grid 容器里面的元素（元素较少时）在垂直方向上会拉伸
  -->
  <div v-else>
    <div
      ref="gridList"
      :class="['grid gap-x-3 gap-y-4', gridListClass]"
    >
      <PkgCard
        v-for="pkg in props.packages"
        :key="pkg.id"
        :pkg="pkg"
        class="cursor-pointer"
        @edit="emit('edit', pkg.id)"
        @share-setting="emit('shareSetting', pkg.id)"
        @delete="emit('delete', pkg.id)"
        @remove-shelf="emit('removeShelf', pkg.id)"
        @click="onClick(pkg)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import Empty from '@/components/Empty.vue'
import PkgCard from './PkgCard.vue'
import { onMounted, onUnmounted, ref } from 'vue'

import type { PackageBasic } from '@/api/package-source'
import { computed } from 'vue'

const props = defineProps<{
  packages: PackageBasic[]
  emptyHint: string
}>()

const emit = defineEmits<{
  edit: [number]
  shareSetting: [number]
  delete: [number]
  removeShelf: [number]
  click: [number]
}>()

const gridList = ref<HTMLDivElement>()

function onClick(pkg: PackageBasic) {
  emit('click', pkg.id)
}

const rows = ref(2)

const gridListClass = computed(() => {
  return (
    {
      2: 'grid-cols-2',
      3: 'grid-cols-3',
      4: 'grid-cols-4',
      5: 'grid-cols-5',
    }[rows.value] ?? ''
  )
})

function getGridRows() {
  const gridListWidth = gridList.value?.clientWidth ?? 0
  if (gridListWidth < 530) {
    rows.value = 2
  } else if (gridListWidth < 740) {
    rows.value = 3
  } else if (gridListWidth < 1200) {
    rows.value = 4
  } else {
    rows.value = 5
  }
}

onMounted(() => {
  getGridRows()
  window.addEventListener('resize', getGridRows)
})

onUnmounted(() => {
  window.removeEventListener('resize', getGridRows)
})
</script>

<style scoped></style>
