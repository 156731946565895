<template>
  <div
    ref="padEl"
    :class="['card-pad g-ld-shadow']"
  >
    <div class="px-3 py-4">
      <div class="flex items-center gap-2 h-24px mb-2">
        <div
          :class="[
            'flex items-center text-[var(--gray-400)]',
            {
              'cursor-pointer': canSwitchCardType,
            },
          ]"
          @click="onCardTypeSwitch"
        >
          <span>{{ cardNameMap[card.type] }}</span>

          <i
            v-if="canSwitchCardType"
            class="pi pi-chevron-down ml-1 text-14px"
          ></i>
        </div>

        <div class="ml-auto flex gap-2 items-center">
          <Icon
            v-if="props.showFullEdit"
            name="fullscreen"
            class="cursor-pointer fullscreen w-20px"
            :draggable="false"
            @click.stop="onFullscreenEdit()"
          />

          <DragHandle class="h-24px">
            <Icon
              name="note-order"
              class="cursor-pointer order-drag-handle w-24px"
              :draggable="false"
            />
          </DragHandle>

          <CardMenu
            :can-edit="props.showFullEdit"
            :can-delete="props.canDelete"
            @edit="onFullscreenEdit()"
            @create-after="emit('create-after')"
            @create-before="emit('create-before')"
            @copy="emit('copy')"
            @delete="emit('delete')"
          />
        </div>
      </div>

      <ClozeCardPad
        v-if="card.type === CardType.CLOZE"
        :cardDraft="props.cardDraft"
        :card="card"
        :editorKey="editorKey"
        :illustration-uploading="illustrationUploading"
        @prev="emit('prev')"
        @next="emit('next')"
        @focus="emit('focus', 0)"
        @create-after="emit('create-after')"
        @add-illustration="onAddIllustration"
        @remove-illustration="onRemoveIllustration"
        @update="onClozeCardUpdate"
      ></ClozeCardPad>

      <EnWordCardPad
        v-else-if="card.type === CardType.EN_WORD"
        :cardRes="props.cardDraft"
        :card="card"
        :illustration-uploading="illustrationUploading"
        @focus="emit('focus', 0)"
        @add-illustration="onAddIllustration"
        @remove-illustration="onRemoveIllustration"
        @update="onEnWordCardUpdate"
      ></EnWordCardPad>

      <MCQCardPad
        v-else-if="card.type === CardType.MCQ"
        :cardRes="props.cardDraft"
        :card="card"
        :illustration-uploading="illustrationUploading"
        @focus="emit('focus', 0)"
        @add-illustration="onAddIllustration"
        @remove-illustration="onRemoveIllustration"
        @update="onMCQCardUpdate"
      ></MCQCardPad>
    </div>

    <slot name="footer"></slot>
  </div>
</template>
<script setup lang="ts">
import { updatePackage } from '@/api/package-source'
import CardEdit from '@/pc/pages/Package/CardEdit.vue'
import CardMenu from '@/components/package/CardMenu.vue'
import {
  CardType,
  type Card,
  type ClozeCard,
  type EnWordCard,
  type MCQCard,
} from '@/types/core'
import { ref, watch, computed } from 'vue'
import { pickFile, resizeAndCompressImage } from '@/utils'
import { uploadImage } from '@/api/user'
import { DragHandle } from 'vue-slicksort'
import CardTypeSwitchForm from './CardTypeSwitchForm.vue'
import {
  newClozeCard,
  newMCQCard,
  newWordCard,
  type CardDraft,
} from '@/utils/card'
import ClozeCardPad from './ClozeCardPad.vue'
import EnWordCardPad from './EnWordCardPad.vue'
import MCQCardPad from './MCQCardPad.vue'
import { convertCardTypeToCardName } from '@/shared'

const cardNameMap = {
  [CardType.CLOZE]: _t('cardview.keypoint'),
  [CardType.EN_WORD]: _t('cardview.enword'),
  [CardType.MCQ]: _t('cardview.mcq'),
}

const props = withDefaults(
  defineProps<{
    canDelete: boolean
    packageId: number
    cardDraft: CardDraft
    cards: CardDraft[]
    showFullEdit?: boolean
    canSwitchCardType?: boolean
  }>(),
  {
    canSwitchCardType: false,
    showFullEdit: false,
  }
)

const emit = defineEmits<{
  'create-before': []
  'create-after': []
  copy: []
  delete: []
  focus: [offset: number]
  update: [Card]
  prev: []
  next: []
  'add-illustration': [assetId: string]
  'remove-illustration': []
  'change-card-type': [CardType]
}>()

const padEl = ref<HTMLDivElement>()
const editorKey = ref(0)

const card = computed(() => JSON.parse(props.cardDraft.content) as Card)

function onClozeCardUpdate(card: ClozeCard) {
  emit('update', card)
}

function onEnWordCardUpdate(card: EnWordCard) {
  emit('update', card)
}

function onMCQCardUpdate(card: MCQCard) {
  emit('update', card)
}

function onFullscreenEdit() {
  if (props.cardDraft.id == null) {
    _message.info(_t('package.create_tip5'))
    return
  }

  _openDialog(CardEdit, {
    title: _t('common.edit'),
    props: {
      card: props.cardDraft,
      cards: props.cards,
      onUpdate(newCard: CardDraft) {
        editorKey.value++
        emit('update', JSON.parse(newCard.content))
      },
    },
    rootClass: 'w-1000px',
  })
}
const IMG_FILES = '.jpg,.jpeg,.png,.svg,.bmp,.webp,.gif'
const illustrationUploading = ref(false)
async function onAddIllustration() {
  if (card.value.illustration) return

  const file = await pickFile(IMG_FILES)

  try {
    illustrationUploading.value = true
    const compressedFile = await resizeAndCompressImage(file, 2000, 2000)
    const res = await uploadImage(compressedFile)

    if (res.code != 0) {
      _message.info(res.message)
    } else {
      emit('add-illustration', res.data.assetId)
    }
  } finally {
    illustrationUploading.value = false
  }
}

function onCardTypeSwitch() {
  if (!props.canSwitchCardType) return

  _openDialog(CardTypeSwitchForm, {
    title: '切换卡片类型',
    rootClass: _global.isPcMode ? 'w-420px' : 'g-dialog',
    props: {
      card: card.value,
      packageId: props.packageId,
      onTypeSwitch(newType: CardType) {
        updatePackage(props.packageId, {
          defaultCardType: convertCardTypeToCardName(newType),
        })

        emit('change-card-type', newType)
        switch (newType) {
          case CardType.CLOZE:
            emit('update', newClozeCard())
            break
          case CardType.EN_WORD:
            emit('update', newWordCard())
            break
          case CardType.MCQ:
            emit('update', newMCQCard())
            break
        }
      },
    },
  })
}

function onRemoveIllustration() {
  emit('remove-illustration')
}

// 当 cardRes 变化时，需要更新编辑器
watch(
  () => props.cardDraft,
  () => {
    editorKey.value++
  }
)
</script>
<style scoped>
.card-pad {
  border-radius: 8px;
  width: 100%;
  min-height: 44px;
  border: 1px solid var(--ld-border);
  transition: all 0.3s;
  background-color: white;
}

.card-pad * {
  transition: all 0.3s;
}

.card-pad .fullscreen,
.card-pad .order-drag-handle {
  /* 当拖拽进行排序时，会选中输入框内的文本，所以这里需要禁止选中 */
  user-select: none;
  display: none;
}

.card-pad:hover .fullscreen,
.card-pad:hover .order-drag-handle {
  display: inline-block;
}

.illustration :deep(.p-image-preview-indicator) {
  opacity: 0;
}
</style>
