<template>
  <span
    ref="wordSpan"
    :class="[
      'cursor-pointer select-none',
      {
        'bg-ld-brand-100': showSearchPanel,
      },
    ]"
    @click.stop="onWordSearch"
  >
    <Tooltip
      :show="showSearchPanel"
      placement="bottom"
      trigger="manual"
      class="!rounded-8px word-tooltip"
      :showArrow="false"
      @clickoutside="showSearchPanel = false"
    >
      <template v-if="wordDict">
        <div
          class="cursor-pointer"
          @click="onWordPlay"
        >
          <div
            class="text-ld-brand-600 font-semibold text-24px leading-[1] mb-1"
          >
            {{ wordDict.spelling }}
          </div>

          <div class="flex items-center">
            <span
              v-if="usPron"
              class="text-17px text-ld-label-secondary mr-1"
            >
              {{ usPron.label }}
            </span>

            <Icon
              name="pron"
              class="w-16px text-ld-brand-600"
            />
          </div>
        </div>

        <div class="text-19px leading-[1.5] line-clamp-3 mt-2">
          {{ wordDict.explain }}
        </div>

        <div
          class="text-13px text-gray flex items-center cursor-pointer mt-2"
          @click="onWordSheetDisplay"
        >
          {{ _t('cardview.show_definition_detail') }}
          <i class="pi pi-angle-right text-20px"></i>
        </div>
      </template>

      <Loading v-else-if="loading" />

      <template v-else-if="failed">
        <div class="text-19px leading-[1.5] tracking-0.5px text-center">
          <div>
            {{ _t('common.network_timeout') }}
          </div>

          <div
            class="text-ld-brand-500 flex items-center justify-center gap-1 mt-1 cursor-pointer"
            @click="onWordSearch"
          >
            <Icon
              name="retry"
              class="w-25px"
            />
            {{ _t('common.retry') }}
          </div>
        </div>
      </template>

      <span
        v-else
        class="text-19px leading-[1.5]"
      >
        {{ _t('cardview.word_no_definition') }}
      </span>

      <template #trigger>
        <span>{{ word }}</span>
      </template>
    </Tooltip>
  </span>
</template>
<script lang="ts" setup>
import { searchEnWord, type DictEnWord } from '@/api/learn'
import { isChar, wait } from '@/utils'
import { computed, inject, onMounted, ref, type Ref } from 'vue'
import { showWordSearchSheet } from './util'

// 上层注入的容器，用来给 tooltip 定位，可能是卡片的容器，也可能是新知识点弹窗
const cardContainer = inject(
  'cardContainer',
  null
) as Ref<HTMLDivElement> | null

const props = defineProps<{
  word: string
}>()

const showSearchPanel = ref(false)
const wordDict = ref<DictEnWord>()
const loading = ref(false)
const failed = ref(false)

const isWord = computed(() => [...props.word].every(isChar))

const usPron = computed(() => {
  if (wordDict.value == null) return null

  return {
    label: wordDict.value.phoneticUs,
    language: 'en-US',
  }
})

function onWordSearch() {
  if (!isWord.value) return

  calcTooltipPosition()
  if (wordDict.value != null) {
    showSearchPanel.value = true
    return
  }

  failed.value = false
  loading.value = true

  wait(500).then(() => {
    // 如果接口超过 500ms 还没返回数据，此时再显示 loading
    if (loading.value) {
      showSearchPanel.value = true
    }
  })

  const keyword = props.word.toLocaleLowerCase()
  searchEnWord(keyword)
    .then(res => {
      showSearchPanel.value = true
      wordDict.value = res.dictEnList.find(
        item => item.spelling.toLowerCase() === keyword
      )

      if (wordDict.value) {
        _am.preload([{ type: 'pron', text: wordDict.value.spelling }])
      }
    })
    .catch(() => {
      failed.value = true
    })
    .finally(() => {
      loading.value = false
    })
}

/*
计算 tooltip 的位置，tooltip 自己的计算逻辑有问题
效果：tooltip 的位置在文本的 8px 下方，且宽度为容器的 100% - 16px（左右各 8px）
计算规则，首先拿到卡片的容器，其次是文本 span
tooltip 的 left 为容器的 left + 8，宽度为 100% - 16
tooltip 的 top 为 span 的 bottom + 8
*/
const wordSpan = ref<HTMLSpanElement>()
function calcTooltipPosition() {
  if (wordSpan.value == null || cardContainer?.value == null) return

  const containerRect = cardContainer.value.getBoundingClientRect()
  const rect = wordSpan.value.getBoundingClientRect()

  // tooltip 的 x 和 y 属性在 pc 上有点问题，所以这里我们自己
  // 通过样式来覆盖 tooltip 的位置
  document.documentElement.style.setProperty(
    '--ld-enword-tooltip-x',
    containerRect.left + 8 + 'px'
  )

  // 如果底部空间小于 150 ，则展示在单词上面否则展示在下面
  if (window.innerHeight - rect.bottom < 150) {
    document.documentElement.style.setProperty(
      '--ld-enword-tooltip-y',
      rect.top - 8 + 'px'
    )
    document.documentElement.style.setProperty(
      '--ld-enword-tooltip-selfy',
      '-100%'
    )
  } else {
    document.documentElement.style.setProperty(
      '--ld-enword-tooltip-y',
      rect.bottom + 8 + 'px'
    )
    document.documentElement.style.setProperty(
      '--ld-enword-tooltip-selfy',
      '0%'
    )
  }
}

function onWordPlay() {
  _am.playPron(props.word)
}

function onWordSheetDisplay() {
  if (wordDict.value == null) return

  showSearchPanel.value = false
  showWordSearchSheet(wordDict.value)
}

onMounted(() => {
  if (cardContainer?.value) {
    document.documentElement.style.setProperty(
      '--ld-card-width',
      cardContainer.value.clientWidth + 'px'
    )
  }
})
</script>
<style scoped>
:global(.v-binder-follower-content:has(.word-tooltip)) {
  width: calc(var(--ld-card-width) - 16px);
  transform: translateX(var(--ld-enword-tooltip-x))
    translateY(var(--ld-enword-tooltip-y))
    translateY(var(--ld-enword-tooltip-selfy)) !important;
}
</style>
