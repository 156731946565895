<template>
  <RatioSpacedContainer class="p-4 w-full h-full g-body-width">
    <Img
      name="ld-greet"
      class="w-100px mb-8"
    />

    <div class="text-21px leading-[1.8] my-8">
      <div class="font-semibold text-center">
        {{ _t('rank.msg1') }}
      </div>
      <div class="font-semibold text-center">
        {{ _t('rank.msg2') }}
      </div>
    </div>

    <div
      class="text-ld-brand-500 text-24px leading-[1.8] flex items-center gap-2"
    >
      <Icon
        name="clock"
        class="w-24px"
      />
      {{ _t('rank.wait', { time: countDownLabel }) }}
    </div>
  </RatioSpacedContainer>
</template>
<script lang="ts" setup>
import type { RankHomeBreakTime } from '@/types/rank'
import { computed, toRefs } from 'vue'

const props = defineProps<{
  rankHome: RankHomeBreakTime
}>()

const { nextSeasonStartTime } = toRefs(props.rankHome.data)

const countDownLabel = computed(() => {
  const seconds = Math.max(
    Math.floor(
      (new Date(nextSeasonStartTime.value).getTime() - Date.now()) / 1000
    ),
    0
  )
  const hoursLabel = Math.floor(seconds / 3600).toString()
  const minsLabel = Math.ceil((seconds % 3600) / 60).toString()
  return _t('common.count_down_time', { hours: hoursLabel, mins: minsLabel })
})
</script>
<style scoped></style>
