<template>
  <div
    :class="[
      'flex items-center px-1 py-2 user-item',
      {
        'bg-ld-brand-100 self': props.user.isSelf,
      },
    ]"
  >
    <div class="w-36px text-center shrink-0">
      <Icon
        v-if="medalIconName"
        :key="medalIconName"
        :name="medalIconName"
        class="w-32px"
      />
      <span
        v-else
        :class="[
          'font-[DIN] text-21px font-bold',
          {
            'text-green-500': props.user.status === 'up',
            'text-red': props.user.status === 'down',
          },
        ]"
      >
        {{ props.user.index + 1 }}
      </span>
    </div>

    <div class="px-2 flex-1 flex items-center gap-2 overflow-hidden">
      <Avatar
        :avatar="props.user.avatar"
        class="w-44px h-44px shrink-0"
      />

      <div
        :class="[
          'truncate text-17px font-semibold',
          {
            'text-ld-brand-500': props.user.isSelf,
          },
        ]"
      >
        {{ props.user.nickname }}
      </div>
    </div>

    <div
      :class="[
        'px-2 text-19px text-gray font-semibold',
        {
          'text-ld-brand-500': props.user.isSelf,
        },
      ]"
    >
      {{ _t('learn.exp_count', { count: props.user.exp }) }}
    </div>
  </div>
</template>
<script lang="ts" setup>
import type { RankUser } from '@/types/rank'
import { computed } from 'vue'

export type DisplayRankUser = RankUser & {
  // 0-base
  index: number
  isSelf: boolean
  status?: 'up' | 'down'
}

const props = defineProps<{
  user: DisplayRankUser
}>()

const medalIconName = computed(() => {
  if (props.user.index < 3) {
    return `rank-medal-${props.user.index + 1}`
  }

  return null
})
</script>
<style scoped></style>
