<template>
  <div
    :class="[
      'flex items-start',
      {
        'flex-col': !_global.isPcMode,
      },
    ]"
  >
    <div
      :class="[
        'flex-1 mr-2 flex flex-col gap-2',
        {
          'w-full': !_global.isPcMode,
        },
      ]"
    >
      <div class="flex flex-col gap-1 py-6px">
        <Textarea
          :modelValue="card.question"
          :placeholder="_t('cardview.mcq_question_placeholder')"
          class="g-underline-input"
          rows="1"
          autoResize
          @focus="onCardFocus"
          @blur="showInvalidTip = true"
          @update:modelValue="onCardUpdate({ question: $event })"
        ></Textarea>

        <div class="text-14px text-ld-label-secondary">
          {{ _t('cardview.mcq_question') }}

          <span
            v-if="showInvalidTip"
            class="text-red font-semibold ml-4"
          >
            {{ questionInvalidMessage }}
          </span>
        </div>
      </div>

      <div class="flex flex-col gap-2">
        <div
          v-for="(item, i) in card.options"
          :key="i"
          class="flex items-center"
        >
          <span class="text-17px font-[DIN] mr-2">
            {{ numberToLetter(i) }}
          </span>
          <div class="flex-1 flex flex-col gap-1 py-6px">
            <Textarea
              :modelValue="item.content"
              :placeholder="_t('cardview.mcq_option_placeholder')"
              rows="1"
              class="g-underline-input"
              autoResize
              @focus="onCardFocus"
              @blur="showInvalidTip = true"
              @update:model-value="onChoiceUpdate(i, { content: $event })"
            ></Textarea>

            <div class="flex items-center justify-between">
              <div class="flex items-center gap-2 text-13px leading-16px">
                <span
                  v-if="item.isAnswer"
                  class="text-emerald-500 font-semibold"
                >
                  {{ _t('cardview.mcq_correct_option') }}
                </span>
                <span
                  v-else
                  class="text-gray font-semibold"
                >
                  {{ _t('cardview.mcq_wrong_option') }}
                </span>

                <InputSwitch
                  :modelValue="item.isAnswer"
                  class="option-answer-switch"
                  @update:model-value="onChoiceUpdate(i, { isAnswer: $event })"
                />

                <template v-if="showInvalidTip">
                  <span
                    v-if="noAnswer"
                    class="text-red font-semibold ml-4"
                  >
                    {{ _t('cardview.validate_tip4') }}
                  </span>

                  <span
                    v-else-if="item.content.trim() === ''"
                    class="text-red font-semibold ml-4"
                  >
                    {{ _t('cardview.validate_tip5') }}
                  </span>
                </template>
              </div>

              <Icon
                name="trash"
                :class="[
                  'w-16px cursor-pointer',
                  {
                    'opacity-30': !isChoiceOptionCanDelete,
                  },
                ]"
                @click="onChoiceOptionDelete(i)"
              ></Icon>
            </div>
          </div>
        </div>
      </div>

      <div class="flex items-center justify-between">
        <div
          :class="[
            'bg-gray-100 rounded-22px px-4 py-6px text-17px cursor-pointer flex items-center',
            {
              'opacity-30': !isChoiceOptionCanAdd,
            },
          ]"
          @click="onChoiceOptionAdd"
        >
          <i class="pi pi-plus text-12px !font-bold mr-1"></i>
          {{ _t('cardview.mcq_option_add') }}
        </div>

        <Icon
          v-if="!_global.isPcMode"
          name="illustration"
          :class="[
            'w-23px',
            {
              'opacity-30': card.illustration,
            },
          ]"
          @click="emit('add-illustration')"
        />
      </div>
    </div>

    <div
      v-if="(!card.illustration && _global.isPcMode) || illustrationUploading"
      :class="[
        'h-78px w-78px flex items-center justify-center border border-dashed rounded bg-[var(--surface-100)] cursor-pointer',
        {
          'ml-auto': !_global.isPcMode,
        },
      ]"
      @click="emit('add-illustration')"
    >
      <ProgressSpinner
        v-if="illustrationUploading"
        class="w-36px h-36px p-11px"
      />

      <Icon
        v-else
        name="illustration"
        class="w-23px text-gray-600"
      />
    </div>

    <Image
      v-else-if="card.illustration"
      preview
      :class="[
        'illustration w-fit',
        {
          'mt-2 ml-auto': !_global.isPcMode,
        },
      ]"
    >
      <template #image>
        <div class="relative">
          <Img
            :assetId="card.illustration"
            class="h-78px w-78px rounded-8px object-cover"
            @click.stop
          />

          <Icon
            name="image-delete"
            class="absolute top-0 right-0 z-1 cursor-pointer w-18px"
            @click="emit('remove-illustration')"
          />
        </div>
      </template>
      <template #preview="slotProps">
        <img
          :src="_global.assetUrl(card.illustration)"
          class="object-cover"
          :style="slotProps.style"
          @click="slotProps.onClick"
        />
      </template>
    </Image>
  </div>
</template>
<script setup lang="ts">
import type { CardResponse } from '@/api/package-source'
import {
  MIN_CHOICE_OPTIONS,
  type ChoiceOption,
  type MCQCard,
  MAX_CHOICE_OPTIONS,
} from '@/types/core'
import { computed, ref } from 'vue'
import Image from 'primevue/image'
import Textarea from 'primevue/textarea'
import ProgressSpinner from 'primevue/progressspinner'
import InputSwitch from 'primevue/inputswitch'
import { numberToLetter } from '@/components/ConcreteCard/MCQCardFace/common'

const props = defineProps<{
  cardRes: CardResponse
  card: MCQCard
  illustrationUploading: boolean
}>()

const emit = defineEmits<{
  focus: []
  update: [MCQCard]
  'add-illustration': []
  'remove-illustration': []
}>()

const questionInvalidMessage = computed(() => {
  if (props.card.question.trim() === '') {
    return _t('cardview.validate_tip6')
  }
  return ''
})

const isChoiceOptionCanDelete = computed(() => {
  return props.card.options.length > MIN_CHOICE_OPTIONS
})

const isChoiceOptionCanAdd = computed(() => {
  return props.card.options.length <= MAX_CHOICE_OPTIONS
})

const noAnswer = computed(() => {
  return props.card.options.every(item => !item.isAnswer)
})

const showInvalidTip = ref(false)

function onCardFocus() {
  emit('focus')
  showInvalidTip.value = false
}

function onChoiceUpdate(
  index: number,
  { content, isAnswer }: Partial<ChoiceOption>
) {
  const item = props.card.options[index]

  if (item == null) return

  const newOptions = [...props.card.options]
  newOptions.splice(index, 1, {
    content: content == null ? item.content : content.trim(),
    isAnswer: isAnswer == null ? item.isAnswer : isAnswer,
  })

  onCardUpdate({
    options: newOptions,
  })
}

function onChoiceOptionDelete(index: number) {
  if (!isChoiceOptionCanDelete.value) return

  const newOptions = [...props.card.options]

  newOptions.splice(index, 1)

  onCardUpdate({
    options: newOptions,
  })
}

function onChoiceOptionAdd() {
  if (!isChoiceOptionCanAdd.value) return

  const newOptions = [...props.card.options]

  newOptions.push({
    content: '',
    isAnswer: false,
  })

  onCardUpdate({
    options: newOptions,
  })
}

function onCardUpdate({
  question,
  options,
}: {
  question?: string
  options?: ChoiceOption[]
}) {
  emit('update', {
    ...props.card,
    question: question == null ? props.card.question : question.trim(),
    options: options || props.card.options,
    analysis: props.card.analysis,
  })
}
</script>
<style scoped>
.option-answer-switch {
  width: 34px;
  height: 16px;
}

.option-answer-switch :deep(.p-inputswitch-slider::before) {
  width: 10px;
  height: 10px;
  margin-top: -5px;
}

.option-answer-switch[data-p-highlight='true'] :deep(.p-inputswitch-slider) {
  background-color: var(--emerald-500);
}

.option-answer-switch[data-p-highlight='false'] :deep(.p-inputswitch-slider) {
  background-color: var(--gray-500);
}
</style>
