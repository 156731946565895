<template>
  <MCQCardLayout>
    <template #content>
      <DebutCardBadge
        v-if="face.isFirstLearn"
        class="absolute top-0 left-0"
      />

      <WrongCardBadge
        v-if="face.isWrongCard"
        class="absolute top-0 left-0"
      />

      <CardQuestionLayout
        :assetId="face.card.illustration"
        :hide-role-image="face.style.hideRoleImage"
      >
        <template #text>
          {{ props.face.card.question }}
        </template>
      </CardQuestionLayout>
    </template>

    <template #options>
      <div
        v-if="answerOptionsCount > 1"
        class="mb-3 text-ld-label-secondary"
      >
        <template v-if="props.face.type === MCQCardFaceType.MinimalChoice">
          {{ _t('cardview.choice_tip') }}
          <span class="text-ld-brand-500 font-semibold">
            {{ _t('cardview.choice_count', { count: answerOptionsCount }) }}
          </span>
          {{ _t('cardview.choice_tip2') }}
        </template>

        <template v-else-if="props.face.type === MCQCardFaceType.Choice">
          {{ _t('cardview.choice_tip') }}
          <span class="text-ld-brand-500 font-semibold">
            {{ _t('cardview.choice_multi_count') }}
          </span>
          {{ _t('cardview.choice_tip2') }}
        </template>
      </div>

      <div class="flex flex-col items-stretch gap-3">
        <Button
          v-for="(op, i) in data.options"
          :key="i"
          :scene="getOptionScene(op)"
          :class="getOptionClass(op)"
          @click="onClickOption(op)"
        >
          <SparkleText
            :tag="op.selected"
            class="sparkle-text"
          >
            <AutoFontSizeByLine
              class="text-start"
              :font-size-list="[21, 19, 17, 15]"
            >
              {{ op.text }}
            </AutoFontSizeByLine>
          </SparkleText>
        </Button>
      </div>
    </template>
  </MCQCardLayout>
</template>
<script setup lang="ts">
import { MCQCardFaceType, type MCQCardFace, UnitEventType } from '@/types/core'
import MCQCardLayout from '../layout/MCQCardLayout.vue'
import CardQuestionLayout from '../layout/CardQuestionLayout.vue'
import SparkleText from '@/components/SparkleText.vue'
import AutoFontSizeByLine from '@/components/AutoFontSizeByLine.vue'
import { reactive, inject, computed } from 'vue'
import { type Option, genOptions } from './common'
import { FeedbackStar } from '../common/feedback'
import DebutCardBadge from '../common/DebutCardBadge.vue'
import { showCardDetail } from '../common'
import { wait } from '@/utils'
import WrongCardBadge from '../common/WrongCardBadge.vue'

const onEvent = inject<(event: UnitEventType) => void>('onEvent')
const onNext = inject<VoidFunction>('onNext')
const onStar = inject<(star: FeedbackStar) => void>('onStar')

const props = defineProps<{
  face: MCQCardFace
}>()

const data = reactive({
  passed: false,
  star: FeedbackStar.One,
  wrongTimes: 0,
  options: genOptions(
    props.face.card.options,
    props.face.type === MCQCardFaceType.MinimalChoice
  ),
})

const isAllAnswerSelected = computed(() =>
  data.options.every(
    item =>
      (item.isAnswer && item.selected) || (!item.isAnswer && !item.selected)
  )
)

const answerOptionsCount = computed(
  () => data.options.filter(item => item.isAnswer).length
)

function getOptionScene(
  op: Option
):
  | 'choice'
  | 'choiceSelected'
  | 'choiceCorrect'
  | 'choiceWrong'
  | 'choiceUsed' {
  let classList = getOptionClass(op)

  if (classList.includes('used')) {
    return 'choiceUsed'
  }
  if (classList.includes('correct')) {
    return 'choiceCorrect'
  }
  if (classList.includes('wrong')) {
    return 'choiceWrong'
  }

  return 'choice'
}

function getOptionClass(op: Option): string {
  const classList = ['option']

  if (op.wrong) {
    classList.push('wrong')
  }

  // 由于现在只有选对时选项才会被使用，所以这里直接展示答对的样式
  if (op.selected) {
    classList.push('correct')
  }

  return classList.join(' ')
}

function onClickOption(op: Option) {
  if (data.passed) return

  if (op.isAnswer) {
    op.selected = true

    if (isAllAnswerSelected.value) {
      data.passed = true
      data.star = data.wrongTimes > 0 ? FeedbackStar.Two : FeedbackStar.Three
      onEvent?.(UnitEventType.CORRECT)
      onAutoNext()
    }
    return
  }

  data.wrongTimes++
  animeOptionWrong(op)
  onEvent?.(UnitEventType.WRONG)
}

async function onAutoNext() {
  onStar?.(data.star)
  // 先等待按钮动画结束
  await wait(700)

  // 如果是新知识且设置的展示卡片详情，此时会先弹出卡片详情
  if (props.face.isFirstLearn && props.face.style.showCardDetail) {
    showCardDetail(props.face.card, props.face.isFirstLearn, onNext)
    return
  }

  onNext?.()
}

function animeOptionWrong(op: Option) {
  op.wrong = true

  setTimeout(() => {
    op.wrong = false
  }, 400)
}
</script>
<style scoped>
.option {
  cursor: pointer;
  position: relative;
  justify-content: center;
}
</style>
