<template>
  <RatioSpacedContainer v-if="isPackageEmpty">
    <Empty
      :text="_t('package.empty')"
      :desc="isOwner ? _t('package.create_tip4') : ''"
    ></Empty>
  </RatioSpacedContainer>

  <RatioSpacedContainer v-else-if="isChapterEmpty">
    <Empty
      :text="_t('package.no_card')"
      :desc="isOwner ? _t('package.create_tip4') : ''"
    ></Empty>
  </RatioSpacedContainer>

  <div
    v-else
    class="px-4 flex flex-col relative"
  >
    <div
      v-if="showTitle"
      class="my-2 text-13px text-[var(--text-color-secondary)]"
    >
      章节
    </div>

    <div class="grid-chapter">
      <div
        v-for="chapter in chapters"
        :key="chapter.id"
        class="p-4 rounded-lg space-y-1 bg-slate-100 chapter"
        @click="onChapterClick(chapter)"
      >
        <div class="text-17px line-clamp-1">{{ chapter.title }}</div>
        <div class="text-15px text-gray">
          {{
            _t('package.card_count2', { count: allChapterCountMap[chapter.id] })
          }}
        </div>
      </div>
    </div>

    <div
      v-if="showTitle"
      class="my-2 text-13px text-[var(--text-color-secondary)]"
    >
      卡片
    </div>

    <DynamicScroller
      ref="dynamicScroller"
      :items="props.cards"
      :min-item-size="100"
      key-field="renderId"
      class="py-4 g-safe-area"
      @scroll="onScroll"
    >
      <template #default="{ item, index, active }">
        <DynamicScrollerItem
          :item="item"
          :active="active"
          :size-dependencies="[item.content]"
          :data-index="index"
        >
          <slot
            name="card"
            :cardRes="item"
            :noteIndex="index"
          >
            <CardBrowserPad
              :key="item.id"
              :cardResponse="item"
              class="overflow-hidden"
              :class="{ 'mt-3': index !== 0 }"
              @click="onCardClick(item)"
            ></CardBrowserPad>
          </slot>

          <div
            v-if="isOwner && index === props.cards.length - 1"
            class="flex flex-col justify-center items-center mt-3 w-full gap-3"
          >
            <BottomButtons
              ref="inlineBottomButtons"
              :ai-button-disabled="isAiGenerateDisabled"
              @create-card="onCreateCard"
              @ai-generate="onAICardGenerate"
            ></BottomButtons>

            <div
              v-if="!_global.isPcMode"
              class="text-15px text-ld-label-secondary text-center leading-[1.4] font-600"
            >
              {{ _t('package.edit_tip1') }}
              <span class="text-ld-brand-500">
                {{ _global.pcLink }}
              </span>

              <br />
              {{ _t('package.edit_tip2') }}
            </div>

            <!-- 虚拟列表里面 padding 以及元素之间的 margin 没有效果，所以用一个固定高度的 div 来模拟 padding -->
            <div
              v-if="bottomPadding > 0"
              :style="{
                paddingBottom: bottomPadding + 'px',
              }"
            ></div>
            <div :class="[showFixedBottomButtons ? 'h-20' : 'h-4']"></div>
          </div>
        </DynamicScrollerItem>
      </template>
    </DynamicScroller>

    <div
      v-if="showFixedBottomButtons && isOwner"
      class="absolute bottom-0 left-0 w-full bg-ld-background"
    >
      <BottomButtons
        :ai-button-disabled="isAiGenerateDisabled"
        :class="[
          'p-4',
          {
            'g-safe-area': _global.isMobileMode,
          },
        ]"
        @create-card="onCreateCard"
        @ai-generate="onAICardGenerate"
      ></BottomButtons>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  type Package,
  type CardResponse,
  type ChapterItem,
  CardTypeName,
} from '@/api/package-source'

import Empty from '@/components/Empty.vue'
import CardBrowserPad from '@/components/package/CardBrowserPad.vue'
import BottomButtons from './CardListBottomButtons.vue'

import {
  getAllChapterTotalCardCount,
  getChapterChildren,
} from '@/utils/package'

import { computed, nextTick, onMounted, onUnmounted, ref } from 'vue'
import type { CardDraft } from '@/utils/card'

const props = defineProps<{
  chapterId: string
  package: Package
  cards: CardDraft[]
}>()

const emit = defineEmits<{
  chapterClick: [ChapterItem]
  cardClick: [CardResponse]
  createCard: []
  aiGenerate: []
  scroll: [Event]
}>()

const dynamicScroller = ref()

const chapters = computed(() => {
  if (props.package)
    return getChapterChildren(props.package.chapters, props.chapterId)
  return []
})

const allChapterCountMap = computed(() => {
  if (props.package) return getAllChapterTotalCardCount(props.package)
  return {}
})

const isOwner = computed(() => props.package?.owned != null)

const isAiGenerateDisabled = computed(
  () =>
    props.package.cardType != null &&
    props.package.cardType === CardTypeName.MCQ
)

// package 是空的
const isPackageEmpty = computed(() => {
  return (
    Object.values(props.package.chapters).length === 0 &&
    props.cards.length === 0
  )
})

// 当前章节是空的 (没有卡片也没有子章节)
const isChapterEmpty = computed(() => {
  return chapters.value.length === 0 && props.cards.length === 0
})

// 显示章节title
const showTitle = computed(() => {
  if (chapters.value.length === 0) {
    return false
  }
  if (props.cards.length === 0 && !isOwner.value) {
    return false
  }
  return true
})

function scrollToIndex(index: number) {
  if (dynamicScroller.value) {
    dynamicScroller.value.scrollToItem(index)
  }
}

function scrollToBottom() {
  if (dynamicScroller.value) {
    dynamicScroller.value.scrollToBottom()
  }
}

defineExpose({
  scrollToIndex,
  scrollToBottom,
})

function onChapterClick(chapter: ChapterItem) {
  emit('chapterClick', chapter)
}

function onCardClick(card: CardResponse) {
  emit('cardClick', card)
}

function onCreateCard() {
  emit('createCard')
}

function onAICardGenerate() {
  emit('aiGenerate')
}

const inlineBottomButtons = ref<any>()
const showFixedBottomButtons = ref(false)

function handleFixedBottomButtonsShow() {
  // 因为虚拟列表，所以最底部的按钮可能拿不到值
  if (inlineBottomButtons.value == null) {
    showFixedBottomButtons.value = true
    return
  }

  const rect = inlineBottomButtons.value.$el.getBoundingClientRect()
  if (rect.bottom < 0 || rect.top > _viewSize.value.height) {
    showFixedBottomButtons.value = true
  } else {
    showFixedBottomButtons.value = false
  }
}

function onScroll(event: Event) {
  handleFixedBottomButtonsShow()
  emit('scroll', event)
}

onMounted(() => {
  // 虚拟列表 mounted 时，这里还不能立马拿到底部按钮是否渲染
  // 所以使用 setTimeout 等待列表渲染后，再去判断底部的按钮有没有显示在列表中
  setTimeout(() => {
    handleFixedBottomButtonsShow()
  })
})

const bottomPadding = ref(0)

// 在 app 中键盘弹出时，需要检测一下当前的光标是否被键盘或者工具栏遮挡
// 如果被遮挡则需要滚动列表让光标可见
function handleSetBottomInsets(bottomInsets: number) {
  // ios 自动会处理键盘弹起的问题
  if (_store.appInfo?.platform === 'ios') return

  bottomPadding.value = bottomInsets

  // 键盘收起，不需要做处理
  if (bottomInsets === 0) {
    return
  }

  // 先让 padding 渲染出来（否则滚动时空间不够），然后再进行光标的滚动
  nextTick(() => {
    const selection = getSelection()
    const cardListRef = dynamicScroller.value?.$el as HTMLDivElement

    if (selection == null || cardListRef == null) return

    let focusElement: HTMLParagraphElement

    // 如果是 text 文本节点，则找出他的父元素，就是 p
    if (selection.focusNode?.nodeType === 3) {
      focusElement = selection.focusNode.parentElement as HTMLParagraphElement
    } else {
      focusElement = selection.focusNode as HTMLParagraphElement
    }

    const viewPortHeight = window.innerHeight - bottomInsets

    const rect = focusElement.getBoundingClientRect()

    // 如果光标在剩余视口的下面，则滚动这个差值就可以看到光标了
    if (rect.bottom > viewPortHeight) {
      cardListRef.scrollBy({
        top: rect.bottom - viewPortHeight,
      })
    }
  })
}

onMounted(() => {
  _bridge.editor.onSetBottomInsets(handleSetBottomInsets)
})

onUnmounted(() => {
  _bridge.editor.offSetBottomInsets(handleSetBottomInsets)
})
</script>

<style scoped>
.grid-chapter {
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
}
.chapter {
  box-shadow: 0px 0px 4px 0px var(--slate-300) inset;
}
</style>
