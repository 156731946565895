<template>
  <main class="w-xl max-w-full flex flex-col gap-4 p-4">
    <div class="flex items-center">当前版本: {{ gitCommit }}</div>

    <div class="flex items-center gap-4">
      <span>显示 Debug 信息/按钮</span>
      <InputSwitch v-model="db.debug.showDebugLabel" />
    </div>

    <div class="flex items-center gap-4">
      <span>显示悬浮球</span>
      <InputSwitch v-model="db.debug.showDebugFloatingBall" />
    </div>

    <div class="flex items-center gap-4">
      <span>连接生产API</span>
      <InputSwitch
        :modelValue="db.debug.apiEnv == 'prod'"
        @update:modelValue="onApiEnvChange"
      />
    </div>

    <hr />

    <div class="flex items-center gap-4 flex-wrap">
      <span>一键登录</span>
      <InputText
        v-model="userIdOrEmail"
        placeholder="用户LID/邮箱"
        @keydown.enter="onDebugLogin"
      />

      <Button
        label="登录"
        :disabled="userIdOrEmail.length === 0"
        @click="onDebugLogin"
      />
      <Button
        v-if="showBackToSelfButton"
        :label="`回到自己（${db.debug.initialUserId}）`"
        scene="secondary"
        @click="onBackToInitalUser"
      />
      <Button
        label="访客登录"
        scene="secondary"
        @click="debugGuestLogin(router, route)"
      />
    </div>

    <div class="flex items-center gap-4">
      <span>跳过验证码发送，验证码统一为 666666</span>
      <InputSwitch v-model="db.debug.skip" />
    </div>

    <template v-if="showDataRelatedFunctions">
      <div class="flex items-center gap-4">
        <span>设置订单为 0 元</span>
        <InputSwitch v-model="db.debug.forceOrderPriceToZero" />
      </div>

      <div class="flex items-center gap-4">
        <span>跳过面包扣除（开启后闯关时不会消耗面包）</span>
        <InputSwitch v-model="db.debug.skipEnergy" />
      </div>

      <div class="flex items-center gap-4">
        <Calendar
          v-model="dates"
          selectionMode="multiple"
          :manualInput="false"
          :max-date="new Date()"
        />

        <Checkbox
          v-model="checked"
          :binary="true"
        />
        <span>跳转到签到页面</span>
        <Button
          label="签到"
          @click="onCheckIn"
        />
      </div>

      <div class="flex items-center gap-2 flex-wrap">
        指定日期：
        <Calendar
          :model-value="getDebugTsDay(debugDate)"
          selectionMode="single"
          placeholder="选择一个日期"
          :manualInput="false"
          showTime
          hourFormat="24"
          @update:model-value="onDebugTsChange"
        />

        <Button
          label="设置"
          @click="onDebugTsSet"
        ></Button>

        <Button
          label="清除"
          scene="secondary"
          @click="onDebugTsClear"
        ></Button>

        <span class="text-yellow">(注意：页面刷新后该日期将失效)</span>
      </div>
    </template>

    <hr />

    <div class="flex items-center gap-4">
      <span>卡面难度配置</span>
      <TextButton
        :label="_t('编辑配置')"
        @click="onCardDifficulyEdit"
      />
    </div>

    <div class="flex items-center gap-4">
      <span>队列参数配置</span>
      <TextButton
        :label="_t('编辑配置')"
        @click="onInsertQueueParamsEdit"
      />
    </div>

    <div class="flex items-center gap-4">
      <span>队列 ABTest</span>
      <div class="flex align-items-center">
        <RadioButton
          v-model="db.debug.newQueue"
          name="newQueue"
          value="abtest"
        />
        <label class="ml-2">abtest计算</label>
      </div>
      <div class="flex align-items-center">
        <RadioButton
          v-model="db.debug.newQueue"
          name="newQueue"
          value="always"
        />
        <label class="ml-2">固定新队列</label>
      </div>
      <div class="flex align-items-center">
        <RadioButton
          v-model="db.debug.newQueue"
          name="newQueue"
          value="never"
        />
        <label class="ml-2">固定老队列</label>
      </div>
    </div>

    <hr />

    <span>调试入口</span>
    <div class="flex gap-4 flex-wrap">
      <Button
        label="Debug Components"
        @click="onDebugComponents"
      />
      <Button
        label="编辑器测试"
        @click="onDebugEditor"
      />

      <Button
        label="学习单元历史"
        @click="() => onUnitHistoryShow()"
      />
      <Button
        label="上传图片"
        @click="() => onImageUpload()"
      />
      <Button
        label="关卡结算 动画"
        @click="onTestAnimation"
      ></Button>

      <Button
        label="领奖UI"
        @click="openRewardDialog"
      ></Button>

      <Button
        label="Button 样式"
        @click="openButtonDebug"
      ></Button>

      <Button
        label="卡片布局模版"
        @click="openCardLayoutDebug"
      ></Button>

      <Button
        label="Boss 关卡"
        @click="openBossDebug"
      ></Button>

      <Button
        label="排位赛相关 UI"
        @click="openRankUIDebug"
      ></Button>

      <Button
        v-if="_global.isInsideApp"
        label="App Debug"
        @click="showAppDebugPage"
      ></Button>
    </div>
  </main>
</template>

<script lang="ts" setup>
import { checkIn, loginByUserIdOrEmail } from '@/api/debug'
import db from '@/db'
import { useCommonStore } from '@/stores'
import { useRoute, useRouter } from 'vue-router'

import { computed, ref } from 'vue'

import Calendar from 'primevue/calendar'
import Checkbox from 'primevue/checkbox'
import { formatDate, isEmail } from '@/utils'

import { RewardType } from '@/api/task'
import CardDifficultyConfig from './CardDifficultyConfig.vue'
import InsertQueueConfig from './InsertQueueConfig.vue'
import { afterLogin } from '@/shared/landing'
import { debugGuestLogin } from '@/debug'
import RadioButton from 'primevue/radiobutton'

const gitCommit = _global.gitCommit
const userIdOrEmail = ref('')
const router = useRouter()
const route = useRoute()

// 会影响数据写入的 debug 功能，链接 prod env 时不开放
const showDataRelatedFunctions = computed(() => {
  return db.debug.apiEnv !== 'prod'
})

const showBackToSelfButton = computed(() => {
  if (db.debug.initialUserId == null) return false

  // 如果记录的是 email，则对比不是当前用户的 email
  if (isEmail(db.debug.initialUserId)) {
    return db.debug.initialUserId !== store.user?.email
    // 否则对比 id 就可以了
  } else {
    return db.debug.initialUserId !== store.user?.id
  }
})

function onApiEnvChange(useProd: boolean) {
  db.debug.apiEnv = useProd ? 'prod' : 'staging'
  // 切换环境后清除一下「自己」的身份标识
  db.debug.initialUserId = undefined
  _refreshApp()
}

const store = useCommonStore()

const dates = ref<Date[]>([])
// 是否跳转到签到页面
const checked = ref(false)

const emit = defineEmits<{
  done: []
}>()

function openRewardDialog() {
  _showRewards([{ type: RewardType.DIAMOND, value: 20 }])
}
function openButtonDebug() {
  emit('done')
  router.push({
    name: 'debug/button',
  })
}
function openCardLayoutDebug() {
  emit('done')
  router.push({
    name: 'debug/card-layout',
  })
}

function openBossDebug() {
  emit('done')
  router.push({
    name: 'debug/boss',
  })
}

function openRankUIDebug() {
  emit('done')
  router.push({
    name: 'debug/rank',
  })
}

function showAppDebugPage() {
  _bridge.showDebugPage()
}

function onCheckIn() {
  let list = dates.value.map(date => formatDate(date, 'YYYY-MM-DD'))

  if (list.length > 0) {
    checkIn(list).then(res => {
      store.checkInCtRes = res
      if (store.statsOverview) {
        store.statsOverview.accumulatedCheckInDays = res.accumulatedDays
        store.statsOverview.maxCheckInCtDays = res.maxContinuousDays
      }
      _message.success('签到成功')

      if (checked.value) {
        emit('done')

        router.push({
          name: 'debug/check-in',
        })
      }
    })
  } else {
    if (checked.value) {
      emit('done')
      router.push({
        name: 'debug/check-in',
      })
    }
  }
}

function login(userIdOrEmail: string, successMsg: string) {
  loginByUserIdOrEmail(userIdOrEmail).then(res => {
    if (res.code !== 0) {
      _message.error(res.message)
      return
    }

    store.login(res.data.token, res.data.user, true)
    afterLogin(router, route)
    store.clearStageUnit()
    _refreshApp()
    setTimeout(() => {
      _message.success(successMsg)
    })
  })
}

function onDebugLogin() {
  login(userIdOrEmail.value, '一键登录成功')
}

function onBackToInitalUser() {
  if (db.debug.initialUserId == null) return
  login(db.debug.initialUserId, '已回到自己账户')
}

function onCardDifficulyEdit() {
  _openDialog(CardDifficultyConfig, {
    title: '卡面难度',
    rootClass: 'w-[60vw] min-w-360px',
  })
}

function onInsertQueueParamsEdit() {
  _openDialog(InsertQueueConfig, {
    title: '参数配置',
    rootClass: 'w-[40vw] min-w-360px',
  })
}

function onTestAnimation() {
  emit('done')
  router.push({
    name: 'debug/lesson-end',
  })
}

function getDebugTsDay(debugDate: Date | null) {
  if (debugDate == null) return null

  return new Date(debugDate)
}

const debugDate = _store.debugTs
  ? ref(new Date(_store.debugTs))
  : ref<Date | null>(null)
function onDebugTsChange(val: string) {
  debugDate.value = new Date(val)
}

function onDebugTsSet() {
  if (debugDate.value) {
    _store.debugTs = debugDate.value.getTime()

    _refreshApp()
  }
}

function onDebugTsClear() {
  _store.debugTs = null

  _refreshApp()
}

function onDebugComponents() {
  emit('done')
  router.push({
    path: '/debug',
  })
}

function onDebugEditor() {
  emit('done')
  router.push({ name: 'debug/editor' })
}

async function onUnitHistoryShow() {
  const UnitHistory = (await import('@/components/UnitHistory.vue')).default

  _openDialog(UnitHistory, {
    title: '学习单元历史',
    rootClass: 'w-[80vw]',
  })
}

async function onImageUpload() {
  const ImageUpload = (await import('@/components/ImageUpload.vue')).default

  _openDialog(ImageUpload, {
    title: '图片上传',
  })
}
</script>

<style scoped></style>
