<template>
  <RatioSpacedContainer class="p-4 w-full h-full g-body-width">
    <Img
      name="ld-look"
      class="w-100px mb-8"
    />

    <div class="text-21px font-semibold">
      {{ _t('rank.msg3') }}

      <span class="text-ld-brand-500">
        {{ formatDeadline(props.rankHome.data.nextSeasonStartTime) }}
      </span>

      {{ _t('rank.msg4') }}
    </div>
  </RatioSpacedContainer>
</template>
<script lang="ts" setup>
import type { RankHomeFreezeTime } from '@/types/rank'
import { formatDeadline } from '@/utils'

const props = defineProps<{
  rankHome: RankHomeFreezeTime
}>()
</script>
<style scoped></style>
