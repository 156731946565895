<template>
  <div class="flex flex-col gap-4">
    <div class="text-17px leading[1.4] self-center">
      {{ _t('learn.notify') }}
    </div>
    <div class="flex flex-col gap-6">
      <div class="flex flex-col gap-3 items-center">
        <Img
          name="ld-greet"
          class="w-97px"
        />
        <div class="text-19px leading[1.4] font-bold">
          {{ _t('learn.notify_tip1') }}
        </div>
        <TimePicker
          :hour="hour"
          :minute="minute"
          class="w-full"
          @update="onUpdateTime"
        />
      </div>
      <div class="flex flex-col gap-2 py-1">
        <Button
          class="w-full"
          :label="_t('learn.notify_on')"
          @click="onOpenStudyReminder"
        />
        <Button
          class="w-full"
          scene="secondary"
          :label="_t('learn.notify_off')"
          @click="onCloseStudyReminder"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import TimePicker from '@/components/TimePicker.vue'
import { ref } from 'vue'

const emit = defineEmits<{
  done: [
    time?: {
      hour: number
      minute: number
    },
  ]
}>()

const props = defineProps<{
  initialTime: {
    hour: number
    minute: number
  }
}>()
const hour = ref(props.initialTime.hour)
const minute = ref(props.initialTime.minute)

function onUpdateTime(h: number, m: number) {
  hour.value = h
  minute.value = m
}
// 开启学习提醒
function onOpenStudyReminder() {
  emit('done', { hour: hour.value, minute: minute.value })
}
// 关闭学习提醒
function onCloseStudyReminder() {
  _confirm({
    content: _t('learn.notify_tip2'),
    scene: 'confirm',
    primaryText: _t('learn.notify_tip3'),
    secondaryText: _t('learn.notify_tip4'),
    icon: {
      name: 'ld-cry',
      type: 'img',
    },
    onPrimaryClick(resolve) {
      resolve(true)
      onOpenStudyReminder()
    },
    onSecondaryClick(resolve) {
      resolve(true)
      emit('done')
    },
  })
}
</script>

<style scoped></style>
