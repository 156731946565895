<template>
  <div class="flex mb-4 gap-4">
    <div
      :class="[
        'cardtype gap-4',
        {
          selected: type === CardType.CLOZE,
        },
      ]"
      @click="type = CardType.CLOZE"
    >
      <Icon
        name="cardtype-cloze"
        class="w-48px"
      />
      <span>{{ _t('cardview.keypoint') }}</span>
    </div>

    <div
      :class="[
        'cardtype gap-4',
        {
          selected: type === CardType.EN_WORD,
        },
      ]"
      @click="type = CardType.EN_WORD"
    >
      <Icon
        name="cardtype-enword"
        class="w-48px"
      />
      <span>{{ _t('cardview.enword2') }}</span>
    </div>

    <div
      v-if="!_global.isProd"
      :class="[
        'cardtype gap-4',
        {
          selected: type === CardType.MCQ,
        },
      ]"
      @click="type = CardType.MCQ"
    >
      <Icon
        name="cardtype-mcq"
        class="w-48px"
      />
      <span>{{ _t('cardview.mcq') }}</span>
    </div>
  </div>
  <Button
    :disabled="props.card.type === type"
    :label="_t('cardview.switch_card_type')"
    @click="onCardTypeSwitch"
  ></Button>
</template>
<script setup lang="ts">
import { type Card, CardType } from '@/types/core'
import { stringifyContent } from '@/utils/card'
import { ref } from 'vue'

const props = defineProps<{
  card: Card
  packageId: number
}>()

const emit = defineEmits<{
  done: []
  typeSwitch: [CardType]
}>()

const type = ref(props.card.type)

function isEmptyCard(card: Card): boolean {
  switch (card.type) {
    case CardType.CLOZE:
      const contentText = stringifyContent(card.content)
      const analysisText = stringifyContent(card.analysis ?? [])

      return contentText.trim() === '' && analysisText.trim() === ''
    case CardType.EN_WORD:
      return card.word.trim() === '' && card.definition.trim() === ''

    case CardType.MCQ:
      return (
        card.question.trim() === '' &&
        card.options.every(op => op.content.trim() === '')
      )
  }
}

async function onCardTypeSwitch() {
  if (!isEmptyCard(props.card)) {
    _confirm({
      scene: 'warn',
      icon: {
        name: 'ld-cry',
        type: 'img',
      },
      content: _t('cardview.switch_card_tip1'),
      primaryText: _t('cardview.switch_card_tip2'),
      secondaryText: _t('cardview.switch_card_tip3'),
      onSecondaryClick(resolve) {
        resolve(true)
        emit('typeSwitch', type.value)
        emit('done')
      },
    })
  } else {
    emit('typeSwitch', type.value)
    emit('done')
  }
}
</script>
<style scoped>
.cardtype {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  background-color: white;
  border-radius: 8px;
  border: 1px solid var(--ld-border);
  cursor: pointer;
}

.cardtype.selected {
  border: 1px solid var(--primary-color);
  background-color: var(--primary-100);
}
</style>
