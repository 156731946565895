<template>
  <div class="h-full flex-1 flex flex-col bg-ld-background overflow-hidden">
    <div class="bg-ld-brand-100">
      <SafeAreaTopSpacer v-if="!isPcMode" />
      <div class="header">
        <div
          v-if="!isPcMode"
          class="w-20px relative cursor-pointer"
          @click="onClickMessage"
        >
          <Badge
            class="top--8px left-10px"
            :value="store.unreadMsgCount"
          />
          <Icon
            name="remind"
            class="w-full h-full"
          />
        </div>
        <div v-else></div>
        <Icon
          name="setting"
          class="w-20px cursor-pointer"
          @click="onSetting"
        />
      </div>
    </div>

    <div class="flex-1 overflow-y-auto relative flex justify-center">
      <Icon
        name="arch"
        preserveAspectRatio="none"
        class="absolute top-0 w-full h-120px text-ld-brand-100"
      ></Icon>
      <div class="absolute w-full max-w-632px px-4 py-2">
        <div class="vstack items-center mb-6 gap-3">
          <Avatar
            :avatar="user.avatar"
            class="w-90px h-90px"
          />

          <div class="text-21px leading-21px font-medium">
            {{ user.nickname }}
          </div>
          <div
            class="text-15px leading-15px font-medium text-[var(--text-color-secondary)]"
          >
            {{
              _t('user.join', {
                time: $f.date(user.createdAt, 'YYYY 年 MM 月 DD 日'),
              })
            }}
          </div>
        </div>

        <div class="title">
          {{ _t('user.stats') }}
        </div>

        <div class="mt-3 grid grid-cols-2 gap-3">
          <div
            v-for="item in items"
            :key="item.title"
            class="stats-item"
          >
            <div
              class="flex gap-1 items-center"
              :style="{ color: `var(--${item.color}-500)` }"
            >
              <Icon
                :name="item.icon"
                class="w-24px h-24px"
              />
              <span class="text-16px font-semibold">
                {{ item.title }}
              </span>
            </div>
            <div class="flex items-baseline gap-1">
              <span class="font-[DIN] text-28px leading-none font-bold">
                {{ item.value }}
              </span>
              <span
                v-if="item.unit"
                class="text-12px"
              >
                {{ item.unit }}
              </span>
            </div>
          </div>
        </div>

        <div class="title">
          {{ _t('user.support') }}
        </div>
        <div class="mt-3">
          <ListCell
            :title="_t('user.cell1')"
            @click="openContactUs"
          ></ListCell>
          <ListCell
            v-if="_global.isInsideApp"
            :title="_t('user.cell2')"
            @click="onAboutUs"
          >
            <template #action>
              <div class="text-ld-label-secondary">v{{ version }}</div>
              <Icon
                v-if="hasUpdate"
                name="upgrade"
                class="w-20px h-20px ml-4px"
              ></Icon>
              <i class="pi pi-angle-right text-20px ml-8px"></i>
            </template>
          </ListCell>
        </div>

        <div class="title">
          {{ _t('user.follow') }}
        </div>
        <div class="mt-3">
          <ListCell
            :title="_t('user.cell3')"
            img="xhs"
            @click="onXhsLinkOpen"
          ></ListCell>
          <ListCell
            :title="_t('user.cell4')"
            img="bilibili"
            @click="onBiliLinkOpen"
          ></ListCell>
        </div>

        <template v-if="!_global.isProd && !_global.hideDebug">
          <div class="title flex">测试</div>

          <div class="mt-3">
            <ListCell
              title="调试面板"
              debug
              @click="onDebugPanelShow"
            ></ListCell>
          </div>
        </template>

        <div class="flex flex-col gap-1 justify-center items-center py-2 mt-6">
          <Icon
            name="logo"
            class="w-32px"
          />

          <div class="text-ld-brand-500 font-600 text-15px">
            {{ _t('common.footer') }}
          </div>

          <div class="text-ld-label-secondary text-13px">
            {{ _t('landing.msg1') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useCommonStore } from '@/stores'

import Icon from '@/components/Icon.vue'
import ListCell from '@/components/ListCell/ListCell.vue'

import { useRouter } from 'vue-router'

import Badge from '@/components/Badge.vue'
import { StatsIcons } from '@/shared/icons'
import UserSetting from '@/pc/components/UserSetting.vue'

import { openContactUs, openLink } from '@/utils'

const store = useCommonStore()
const router = useRouter()
const user = store.user!

const isPcMode = _global.isPcMode

interface StatsItem {
  icon: string
  color: string
  title: string
  value: string
  unit?: string
}
const items = computed<StatsItem[]>(() => [
  {
    icon: StatsIcons.stageCount.icon,
    color: StatsIcons.stageCount.color,
    title: StatsIcons.stageCount.label,
    value: store.statsOverview?.stageCount?.toString() ?? '',
  },
  {
    icon: StatsIcons.exp.icon,
    color: StatsIcons.exp.color,
    title: StatsIcons.exp.label,
    value: store.statsOverview?.exp?.toString() ?? '',
  },
  {
    icon: StatsIcons.accumulatedCheckInDays.icon,
    color: StatsIcons.accumulatedCheckInDays.color,
    title: StatsIcons.accumulatedCheckInDays.label,
    value: store.statsOverview?.accumulatedCheckInDays?.toString() ?? '',
    unit: _t('common.day'),
  },
  {
    icon: StatsIcons.maxCheckInCtDays.icon,
    color: StatsIcons.maxCheckInCtDays.color,
    title: StatsIcons.maxCheckInCtDays.label,
    value: store.statsOverview?.maxCheckInCtDays?.toString() ?? '',
    unit: _t('common.day'),
  },
])
// 要显示的版本号 prod 显示简化版本号 x.y.z
const version = computed(() => {
  const fullVersion = _store.appInfo?.fullVersion ?? ''
  const version = fullVersion.split('.').slice(0, 3).join('.')
  return _global.isProd ? version : fullVersion
})

// 有更新
const hasUpdate = computed(() => {
  return _store.appInfo?.updateConfig
})

function onDebugPanelShow() {
  _openDebugPanel()
}

// 关于我们
function onAboutUs() {
  router.push({
    name: 'about-us',
  })
}

function onBiliLinkOpen() {
  openLink(_global.bilibiliLink)
}

function onXhsLinkOpen() {
  openLink(_global.xhsLink)
}

function onSetting() {
  if (isPcMode) {
    _openDialog(UserSetting, {
      rootClass: 'min-w-600px min-h-600px bg-ld-background',
      dialog: {
        showHeader: false,
        pt: {
          content: {
            class: 'flex-1',
            style: 'padding: 0px;',
          },
        },
      },
    })
  } else {
    router.push({ name: 'user-setting' })
  }
}

function onClickMessage() {
  router.push({
    name: 'message',
  })
}
</script>

<style scoped>
.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
}

.vip {
  display: flex;
  color: var(--ld-label-white);
  position: relative;
  background: var(--slate-800);
  overflow: hidden;
  cursor: pointer;
}

.vip-bg {
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  background: linear-gradient(
    315deg,
    rgba(252, 85, 255, 0.4) 0%,
    rgba(38, 138, 255, 0.4) 48%,
    rgba(38, 246, 99, 0.3) 100%
  );
}

.vip.unavaiable {
  padding: 20px 16px;
  border-radius: 16px;
}

.vip.avaiable {
  padding: 12px;
  border-radius: 12px;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
}

.vip-btn {
  color: var(--primary-color);
  padding: 6px 16px;
  border-radius: 16px;
  background-color: white;
  width: fit-content;
  display: flex;
  align-items: center;
  box-shadow: 0px 4px 0px 0px var(--slate-300);
}

.title {
  font-size: 15px;
  line-height: 22px;
  color: var(--text-color-secondary);
  margin-top: 24px;
  font-weight: 600;
}

.stats-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px;
  box-shadow: 0px 2px 8px 0px var(--slate-300);
  background-color: white;
  border-radius: 12px;
  color: var(--ld-label-primay);
  gap: 12px;
}
</style>
