<template>
  <div class="flex items-center gap-2">
    <div
      class="bg-ld-brand-200 text-ld-brand font-[DIN] text-11px w-4 h-4 rounded-100% flex items-center justify-center"
    >
      {{ props.index + 1 }}
    </div>

    <div
      :class="[
        'flex-1 rounded-8px py-3 px-4 border border-ld-border border-solid bg-white',
        borderClass,
      ]"
    >
      <Textarea
        :modelValue="term.term"
        class="g-underline-input w-100%"
        rows="1"
        autoResize
        placeholder="单词"
        maxlength="1000"
        @focus="emit('focus')"
        @update:modelValue="onTermUpdate($event)"
      />

      <Textarea
        :modelValue="term.definition"
        class="g-underline-input w-100%"
        rows="1"
        autoResize
        placeholder="释义"
        maxlength="1000"
        @focus="emit('focus')"
        @update:modelValue="onDefinitionUpdate($event)"
      />
    </div>

    <div class="flex items-center justify-center select-none gap-2px">
      <slot name="action"></slot>
    </div>
  </div>
</template>
<script setup lang="ts">
import type { AiEnWordTerm } from '@/api/package-source'
import { computed } from 'vue'

const props = defineProps<{
  index: number
  term: AiEnWordTerm
  focused: boolean
}>()
const emit = defineEmits<{
  update: [AiEnWordTerm]
  focus: []
}>()

const isInvalid = computed(() => {
  return props.term.term.trim() === '' || props.term.definition.trim() === ''
})

const borderClass = computed(() => {
  if (isInvalid.value) return 'border-red-500'

  return props.focused ? 'border-ld-brand-500' : 'hover:border-gray'
})

function onTermUpdate(newTerm: string) {
  emit('update', {
    term: newTerm,
    definition: props.term.definition,
  })
}

function onDefinitionUpdate(newDefinition: string) {
  emit('update', {
    term: props.term.term,
    definition: newDefinition,
  })
}
</script>
<style scoped></style>
