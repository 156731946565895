<template>
  <div class="w-[60vw] min-w-[400px]">
    <FileUpload
      accept="image/*"
      multiple
      customUpload
      @select="onFileSelect"
    >
      <template #header="{ chooseCallback, clearCallback }">
        <div
          class="flex flex-wrap justify-content-between align-items-center flex-1 gap-2"
        >
          <div class="flex gap-2">
            <Button
              label="选择图片"
              scene="secondary"
              @click="chooseCallback()"
            ></Button>

            <Button
              scene="success"
              label="上传图片"
              :disabled="unUploadFiles.length === 0"
              @click="upload"
            ></Button>

            <Button
              scene="danger"
              label="清除"
              :disabled="images.length === 0"
              @click="onClear(clearCallback)"
            ></Button>
          </div>
        </div>
      </template>

      <template #content="{ removeFileCallback, files }">
        <div class="grid grid-cols-3 p-0 gap-4 mt-4">
          <div
            v-for="(item, index) of images"
            :key="index"
            class="p-4 flex flex-col border-solid border-1 items-center gap-3 overflow-hidden"
          >
            <div class="flex-1 flex items-center">
              <img
                :alt="item.file.name"
                :src="item.objectURL"
                class="h-fit w-fit max-w-200px"
                @load="onImageLoaded(item.objectURL)"
              />
            </div>

            <div class="w-full font-semibold text-center mt-auto">
              {{ formatFileName(item.originalFileName) }}
            </div>

            <div>{{ formatSize(item.file.size) }}</div>

            <div
              v-if="item.uploaded"
              class="cursor-pointer mb-3 max-w-full break-words"
              @click="onAssetIdCopy(_global.assetUrl(item.assetId))"
            >
              assetId: {{ formatFileName(item.assetId) }}
            </div>

            <Badge
              v-if="item.uploaded"
              value="上传成功"
              severity="success"
            />

            <div
              v-else-if="item.file.size > MAX_FILE_SIZE"
              class="flex items-center"
            >
              <Badge
                value="图片过大"
                severity="danger"
              />

              <Button
                text
                severity="danger"
                label="移除"
                class="p-0 ml-2"
                @click="onRemove(removeFileCallback, files, index)"
              ></Button>
            </div>

            <Badge
              v-else
              value="等待上传"
              severity="warning"
            />
          </div>
        </div>
      </template>

      <template #empty>
        <div class="flex flex-col items-center py-4">
          <i class="pi pi-cloud-upload border-circle text-8xl text-400" />
          <p class="mt-4 mb-0">拖拽文件到此处上传</p>
        </div>
      </template>
    </FileUpload>
  </div>
</template>

<script lang="ts" setup>
import FileUpload from 'primevue/fileupload'
import Badge from 'primevue/badge'
import { ref } from 'vue'
import { MB, formatSize, resizeAndCompressImage } from '@/utils/index'
import { uploadImage } from '@/api/user'
import { computed } from 'vue'

import type { FileUploadSelectEvent } from 'primevue/fileupload'
import { useCopy } from '@/hooks'

const MAX_FILE_SIZE = 2 * MB

interface UploadImage {
  file: File
  originalFileName: string
  uploaded: boolean
  objectURL: string
  assetId: string
}

const copy = useCopy()

defineEmits<{
  done: []
}>()

const images = ref<UploadImage[]>([])

const unUploadFiles = computed(() =>
  images.value.filter(file => !file.uploaded && file.file.size <= MAX_FILE_SIZE)
)

function onImageLoaded(url: string) {
  URL.revokeObjectURL(url)
}

function onAssetIdCopy(assetId: string) {
  copy(assetId).then(() => {
    _message.success(_t('common.copy_success'))
  })
}

function onRemove(
  removeCallback: (index: number) => void,
  files: File[],
  index: number
) {
  const file = images.value[index].file

  // FileUpload 组件内部会维护 files，这里需要找出来删掉
  const uploadIndex = files.findIndex(
    item => item.name === file.name && item.size === file.size
  )

  images.value.splice(index, 1)
  removeCallback(uploadIndex)
}

async function onFileSelect(event: FileUploadSelectEvent) {
  for (const file of event.files) {
    const compressedFile = await resizeAndCompressImage(file, 2000, 2000)

    if (images.value.some(item => item.originalFileName === file.name)) continue

    images.value.push({
      file: compressedFile,
      originalFileName: file.name,
      objectURL: URL.createObjectURL(compressedFile),
      uploaded: false,
      assetId: '',
    })
  }
}

function formatFileName(filename: string) {
  const parts = filename.split('.')

  if (parts.length < 2) return parts[0].slice(0, 30)

  const name = parts.slice(0, -1).join('.')
  const ext = parts[parts.length - 1]

  if (name.length > 30) {
    return `${name.slice(0, 15)}...${name.slice(-15)}.${ext}`
  }

  return filename
}

function onClear(clear: VoidFunction) {
  images.value.length = 0
  clear()
}

async function upload() {
  if (unUploadFiles.value.length === 0) return

  for (const item of unUploadFiles.value) {
    const res = await uploadImage(item.file)

    if (res.code !== 0) {
      _message.error(res.message)
    } else {
      item.uploaded = true
      item.assetId = res.data.assetId
    }
  }
}
</script>

<style scoped></style>
