<template>
  <div class="flex flex-col flex-1 px-4 g-safe-area">
    <SafeAreaTopSpacer />
    <div class="flex h-44px g-header-width items-center">
      <Icon
        name="close"
        class="cursor-pointer w-20px"
        @click="emit('close')"
      ></Icon>
    </div>

    <RatioSpacedContainer class="flex-1">
      <Img
        :name="props.name"
        class="w-128px"
      />

      <div class="mt-5 text-22px leading-[1.4] text-ld-brand-900">
        {{ _t('learn.boss_tip') }}
      </div>
    </RatioSpacedContainer>

    <Button
      class="bottom-button mb-4"
      :label="_t('common.continue')"
      @click="emit('next')"
    ></Button>
  </div>
</template>
<script setup lang="ts">
const props = defineProps<{
  name: string
}>()

const emit = defineEmits<{
  close: []
  next: []
}>()
</script>
<style scoped>
.bottom-button {
  width: 100%;
  max-width: 700px;
  white-space: nowrap;
  margin-left: auto;
  margin-right: auto;
}
</style>
