<template>
  <div class="flex items-center text-ld-brand-500">
    <Icon
      name="exp"
      class="w-24px"
    />

    <span class="text-19px font-semibold ml-1">
      {{ _t('learn.exp_add', { exp: props.exp }) }}
    </span>
  </div>
</template>
<script setup lang="ts">
const props = defineProps<{
  exp: number
}>()
</script>

<style scoped></style>
