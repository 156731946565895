<template>
  <div
    class="text-13px text-ld-label-secondary self-center leading-none flex items-center justify-center gap-1 relative"
    @click="emit('update:isAgree', !props.isAgree)"
  >
    <div
      v-if="props.showAgree"
      class="w-16px h-16px"
    >
      <Icon
        v-if="props.isAgree"
        name="checkbox-on"
      />
      <Icon
        v-else
        name="checkbox-off"
      />
    </div>

    <div
      v-if="props.showBubble && !props.isAgree"
      class="bubble"
    >
      {{ _t('auth.agreement_1') }}
    </div>
    <span>
      {{
        props.showAgree
          ? _t('auth.agreement_2')
          : _t('auth.agreement_3', { action: props.action })
      }}
      <span
        class="cursor-pointer font-semibold"
        @click="onClickUserAgreement"
      >
        {{ _t('auth.agreement_4') }}
      </span>
      {{ _t('common.and') }}
      <span
        class="cursor-pointer font-semibold"
        @click="onClickPrivacyPolicy"
      >
        {{ _t('auth.agreement_5') }}
      </span>
    </span>
  </div>
</template>
<script setup lang="ts">
import { openPrivacyPolicy, openUserAgreement } from '@/utils'

const props = defineProps<{
  action: string
  // 是否显示同意协议按钮
  showAgree?: boolean

  isAgree: boolean

  // 是否显示气泡提示
  showBubble?: boolean
}>()
const emit = defineEmits(['update:isAgree'])
function onClickUserAgreement(e: MouseEvent) {
  e.stopPropagation()
  openUserAgreement()
}
function onClickPrivacyPolicy(e: MouseEvent) {
  e.stopPropagation()
  openPrivacyPolicy()
}
</script>
<style scoped>
/* 气泡 显示在进度条下面 20 px */
.bubble {
  background-color: var(--ld-brand-500);
  border-radius: 8px;
  padding: 12px;
  position: absolute;
  top: calc(100% + 10px);
  left: -20px;

  color: white;
  font-size: 13px;
  font-weight: 500;
  z-index: 1;
}
.bubble::after {
  content: '';
  position: absolute;
  top: -15px;
  left: 30px;
  transform: translateX(-50%);
  border: 8px solid transparent;
  border-bottom-color: var(--ld-brand-500);
}
</style>
