<template>
  <div
    class="h-full text-center g-safe-area px-4 flex flex-col bg-ld-background"
  >
    <div
      class="flex-1 g-body-width overflow-hidden flex flex-col items-center mt-8"
    >
      <Icon
        name="rank-up"
        :class="['mx-auto h-42px']"
      />

      <RankIcon
        :rank="groupRank"
        class="w-90px h-90px mt-8 mb-4"
      />

      <div class="text-21px font-semibold">
        <template v-if="afterRankPosition > 3">
          {{ _t('rank.msg14') }}

          <span class="text-ld-brand-500">
            {{
              _t('rank.msg15', {
                count: beforeRankPosition - afterRankPosition,
              })
            }}
          </span>
        </template>

        <template v-else>
          {{ _t('rank.msg16') }}
          <span class="text-ld-brand-500">
            {{ _t('rank.mag17', { rank: afterRankPosition }) }}
          </span>
        </template>
      </div>

      <div class="flex-1 w-full mt-8 overflow-y-hidden">
        <TransitionGroup
          ref="groupListRef"
          name="group"
          tag="ul"
          class="group w-full overflow-y-auto max-h-full p-0 m-0"
        >
          <div
            v-for="user in groupUsers"
            :key="user.userId"
            :class="[
              'relative',
              {
                'self-user': user.isSelf,
              },
            ]"
          >
            <GroupUserItem
              :key="user.userId"
              :user="user"
            ></GroupUserItem>

            <div
              v-if="user.isSelf && isAnimationEnd"
              class="lottie-sparkle w-full"
            >
              <LottieBox
                name="sparkle-brand-0"
                class="sparkle-left"
                autoplay
                :loop="false"
              />

              <LottieBox
                name="sparkle-brand-1"
                class="sparkle-right"
                autoplay
                :loop="false"
              />
            </div>
          </div>
        </TransitionGroup>
      </div>
    </div>

    <div class="h-53px mb-4 mt-4">
      <Button
        v-if="isAnimationEnd"
        class="w-full g-footer-width g-fade-in"
        label="继续"
        @click="onContinue"
      ></Button>
    </div>
  </div>
</template>
<script lang="ts" setup>
import type { RankHomeInGroup } from '@/types/rank'
import { computed, nextTick, ref, TransitionGroup } from 'vue'
import { parseRank } from '../Rank/util'
import RankIcon from '@/components/RankIcon.vue'
import GroupUserItem, {
  type DisplayRankUser,
} from '@/components/RankGroupUserItem.vue'
import LottieBox from '@/components/LottieBox.vue'
import { vibrate, VibrateType } from '@/utils'

const props = defineProps<{
  beforeRank?: RankHomeInGroup
  afterRank: RankHomeInGroup
}>()

const emit = defineEmits<{
  done: []
}>()

const isAnimationEnd = ref(false)
const groupUsers = ref<DisplayRankUser[]>([])
const groupListRef = ref<any>()

const groupRank = computed(() => parseRank(props.afterRank.data.groupRank))

const beforeRankPosition = computed(() => {
  if (props.beforeRank == null) {
    return props.afterRank.data.users.length
  }

  return props.beforeRank.data.position
})

const afterRankPosition = computed(() => props.afterRank.data.position)

const beforeRankIndex = computed(() => beforeRankPosition.value - 1)
const afterRankIndex = computed(() => afterRankPosition.value - 1)

onInit(() => {
  groupUsers.value = getGroupUsers()

  setTimeout(() => {
    setUserPosition()
    nextTick(changeUserPosition)
  }, 50)
})

function changeUserPosition() {
  const meIndex = groupUsers.value.findIndex(item => item.isSelf)

  const me = groupUsers.value[meIndex]
  groupUsers.value.splice(meIndex, 1)
  groupUsers.value.splice(afterRankIndex.value, 0, me)
  groupUsers.value.forEach((item, i) => {
    item.index = i
  })

  // 动画结束后显示继续按钮
  // TODO(buding): TransitionGroup 事件都没有效果
  // 优化一下动画结束时的判断
  setTimeout(() => {
    isAnimationEnd.value = true
    vibrate(VibrateType.Success)
  }, 1000)
}

// 先把列表视口滚动到当前用户的位置
function setUserPosition() {
  if (groupListRef.value) {
    const currentUser = groupListRef.value.$el.childNodes[afterRankIndex.value]
    currentUser.scrollIntoView({
      block: 'center',
      inline: 'center',
      behavior: 'auto',
    })
  }
}

function getGroupUsers(): DisplayRankUser[] {
  const users = [...props.afterRank.data.users]

  const me = users[afterRankIndex.value]

  // 先把自己从列表中移除
  users.splice(afterRankIndex.value, 1)

  if (props.beforeRank == null) {
    users.push(me)
  } else {
    users.splice(beforeRankIndex.value, 0, me)
  }

  return users.map((user, i) => {
    return {
      ...user,
      index: i,
      isSelf: user.userId === me.userId,
    }
  })
}

function onContinue() {
  emit('done')
}
</script>
<style scoped>
.group {
  background-color: white;
  border: 2px solid var(--gray-300);
  border-radius: 12px;
}

.group-move, /* apply transition to moving elements */
.group-enter-active,
.group-leave-active {
  transition: all 1s ease;
}

/* 这里需要设置当前用户的 position 和 z-index，否则会从其他的元素中穿过 */
.group-move.self-user {
  position: relative;
  z-index: 1;
}

.lottie-sparkle {
  position: absolute;
  bottom: -6px;
  left: 0;
  right: 0;
  top: -6px;
  transform: scale(1, -1);
}

.sparkle-left {
  left: 0;
  top: 8px;
  width: 40px;
  position: absolute;
}
.sparkle-right {
  bottom: 0;
  right: 0;
  width: 30px;
  position: absolute;
}
</style>
