import type {
  Card,
  CardType,
  ClozeCard,
  EnWordCard,
  Content,
  UserAssetId,
  MCQCard,
} from './model'

export type { Card, ClozeCard, EnWordCard, MCQCard }

export { CardType } from './model'
export type {
  InlineNode,
  Text,
  Cloze,
  Content,
  PronunciationLanguage,
  ChoiceOption,
} from './model'

export type ParsedClozeCard = {
  type: CardType.CLOZE
  content: Content
  altContents: Content[]
  analysis: Content

  // 配图 assetId
  illustration?: UserAssetId
}

export type ParsedCard = ParsedClozeCard | EnWordCard | MCQCard

export enum QuizStage {
  // 作答中
  Answering,

  // 查看提交的答案
  ViewSubmittedAnswer,

  // 查看正确答案
  ViewCorrectAnswer,
}

// 卡片操作区样式布局
export type OperationLayout = 'horizontal' | 'vertical' | 'grid'

export type CardFace = ClozeCardFace | EnWordCardFace | MCQCardFace

export type ClozeCardFace = ClozeCardChoiceFace | ClozeCardJudgementFace

export type MCQCardFace = MinimalChoiceMCQFace | ChoiceMCQFace

export type EnWordCardFace =
  | WordChoiceEnWordFace
  | ExampleChoiceEnWordFace
  | ExplainChoiceEnWordFace
  | PickImageEnWordFace
  | VoiceChoiceEnWordFace
  | SpellEnWordFace

export enum ClozeCardFaceType {
  // 选择题
  Choice = 'Choice',

  // 极简选择题
  MinimalChoice = 'MinimalChoice',

  // 送分选择题
  GiveAwayChoice = 'GiveAwayChoice',

  // 判断题
  Judgement = 'Judgement',

  // 送分判断题
  GiveAwayJudgement = 'GiveAwayJudgement',
}

type CommonChoiceFaceProps = {
  cardId: number
  card: ParsedClozeCard
  altCards: ParsedClozeCard[]
  isFirstLearn: boolean
  isWrongCard?: boolean
  style: {
    // 是否在新知识/答错的时候展示卡片详情
    showCardDetail: boolean
    hideRoleImage: boolean
    hideTip: boolean
    operationLayout: OperationLayout
  }
}

// 知识点选择题
export type ChoiceFace = CommonChoiceFaceProps & {
  type: ClozeCardFaceType.Choice
}

export type MinimalChoiceFace = CommonChoiceFaceProps & {
  type: ClozeCardFaceType.MinimalChoice
}

export type GiveAwayChoiceFace = CommonChoiceFaceProps & {
  type: ClozeCardFaceType.GiveAwayChoice
}

export type ClozeCardChoiceFace =
  | ChoiceFace
  | MinimalChoiceFace
  | GiveAwayChoiceFace

// 知识点判断题

type CommonJudgementFaceProps = {
  cardId: number
  card: ParsedClozeCard
  altCards: ParsedClozeCard[]
  isFirstLearn: boolean
  isWrongCard?: boolean
  style: {
    // 是否在新知识/答错的时候展示卡片详情
    showCardDetail: boolean
    hideRoleImage: boolean
    hideTip: boolean
  }
}

export type JudgementFace = CommonJudgementFaceProps & {
  type: ClozeCardFaceType.Judgement
}

export type GivieAwayJudgementFace = CommonJudgementFaceProps & {
  type: ClozeCardFaceType.GiveAwayJudgement
}

export type ClozeCardJudgementFace = JudgementFace | GivieAwayJudgementFace

export enum EnWordCardFaceType {
  // 单词选释义
  WordChoice = 'WordChoice',

  // 例句选释义
  ExampleChoice = 'ExampleChoice',

  // 释义选单词
  ExplainChoice = 'ExplainChoice',

  // 选图
  PickImage = 'PickImage',

  // 听音选词
  VoiceChoice = 'VoiceChoice',

  // 拼写
  Spell = 'Spell',
}

type CommonEnWordFaceProps = {
  cardId: number
  card: EnWordCard
  altCards: EnWordCard[]
  isFirstLearn: boolean
  isWrongCard?: boolean
  style: {
    // 是否在新知识/答错的时候展示卡片详情
    showCardDetail: boolean
    hideTip: boolean
    hideRoleImage: boolean
  }
}

export type WordChoiceEnWordFace = CommonEnWordFaceProps & {
  type: EnWordCardFaceType.WordChoice
}

export type ExampleChoiceEnWordFace = CommonEnWordFaceProps & {
  type: EnWordCardFaceType.ExampleChoice
}

export type ExplainChoiceEnWordFace = CommonEnWordFaceProps & {
  type: EnWordCardFaceType.ExplainChoice
}

export type PickImageEnWordFace = CommonEnWordFaceProps & {
  type: EnWordCardFaceType.PickImage
}

export type VoiceChoiceEnWordFace = CommonEnWordFaceProps & {
  type: EnWordCardFaceType.VoiceChoice
}

export type SpellEnWordFace = CommonEnWordFaceProps & {
  type: EnWordCardFaceType.Spell
}

export enum MCQCardFaceType {
  MinimalChoice = 'MinimalMCQChoice',

  Choice = 'MCQChoice',
}

type CommonMCQFaceProps = {
  cardId: number
  card: MCQCard
  isFirstLearn: boolean
  isWrongCard?: boolean
  style: {
    // 是否在新知识/答错的时候展示卡片详情
    showCardDetail: boolean
    hideRoleImage: boolean
  }
}

export type MinimalChoiceMCQFace = CommonMCQFaceProps & {
  type: MCQCardFaceType.MinimalChoice
}

export type ChoiceMCQFace = CommonMCQFaceProps & {
  type: MCQCardFaceType.Choice
}

export enum VirtualCardType {
  // boss 卡
  Boss = 'boss',

  // 配对卡
  Match = 'match',
}

export type VirtualCardBoss = {
  type: VirtualCardType.Boss
  // 当 boss 血量为 0 时，此时没有卡片渲染，所以这里 cardFace 为可选
  cardFace?: CardFace | VirtualCardMatch
  name: string
  hp: number

  // 每次 tick 会掉多少血
  tickHp: number
}

export type VirtualCardMatch = {
  type: VirtualCardType.Match
  cards: EnWordCard[]
}

//
// Basic Structure
//

export enum LearnStatus {
  // 新学
  DEBUT = 'DEBUT',
  // 复习
  REVIEW = 'REVIEW',
}

// EASY 简单
// GOOD 记得
// HARD 模糊
// AGAIN 忘记
export enum CardRating {
  // 简单
  EASY = 'EASY',
  // 记得
  GOOD = 'GOOD',
  // 模糊
  HARD = 'HARD',
  // 忘记
  AGAIN = 'AGAIN',
}

export enum UIMode {
  K12 = 'K12',
  KaoYan = 'KaoYan',
  LocationNormal = 'LocationNormal',
  ExamNormal = 'ExamNormal',
  Language = 'Language',
}

export type IdentityTag = {
  key: string
  name: string
  aliases: string[]
  hidden: boolean
  children?: IdentityTag[]
  order?: number
  hot?: number
  uiMode?: UIMode
  code?: string
}

export interface ContentTag {
  key: string
  name: string
  // 叶子节点会有这个字段
  uniqueName?: string
  aliases: string[]
  hidden: boolean
  children?: ContentTag[]
}

export const ROOT_CHAPTER_KEY = 'root'

export enum UnitEventType {
  WRONG = 'WRONG', // 做错
  CORRECT = 'CORRECT', // 做对
  TIP = 'TIP', // 提示
  LAPSE = 'LAPSE', // 记错了
}

export enum QueueType {
  // 决斗队列
  Duel = 'Duel',

  // 插入队列
  Insert = 'Insert',

  // 错题队列
  WrongCards = 'WrongCards',
}

export const MAX_CHOICE_OPTIONS = 9
export const MIN_CHOICE_OPTIONS = 2
