<template>
  <div
    class="h-full text-center g-safe-area px-4 flex flex-col bg-ld-background"
  >
    <RatioSpacedContainer class="flex-1 g-body-width">
      <div class="flex flex-col justify-center items-center w-full">
        <div
          class="h-124px w-full flex items-end justify-center m-b--12px scale-70"
        >
          <div class="relative h-460px w-150px flex items-end justify-center">
            <LottieBox
              class="w-126px h-250px absolute z--1"
              :autoplay="true"
              :loop="true"
              name="flame0"
              :start="35"
            />
          </div>
        </div>
        <NumberImage
          :number="checkData.checkInCtRes.continuousDays"
          class="z-1"
        />
        <div class="text-24px text-orange-400 font-bold m-t-12px">
          {{ _t('learn.checkin_msg3') }}
        </div>
      </div>

      <CheckInWeekCalendar
        class="m-t-24px w-full"
        :checkInDates="checkData.stats"
      ></CheckInWeekCalendar>

      <div
        :class="[
          'mt-4 text-19px tracking-0.5px',
          showInfoText ? 'fade-in' : 'opacity-0',
        ]"
      >
        {{ _t('learn.checkin_msg2') }}
      </div>
    </RatioSpacedContainer>

    <div class="g-footer-width mb-4">
      <Button
        class="w-full"
        :label="_t('learn.checkin_msg4')"
        @click="onBack"
      ></Button>
    </div>
  </div>
</template>

<script setup lang="ts">
import NumberImage from './NumberImage.vue'
import type { CheckInCtRes, CheckInStatsItem } from '@/api/user'
import LottieBox from './LottieBox.vue'
import CheckInWeekCalendar from './CheckInWeekCalendar.vue'
import { ref } from 'vue'

defineProps<{
  checkData: {
    checkInCtRes: CheckInCtRes
    stats: CheckInStatsItem[]
  }
}>()

const showInfoText = ref(false)

onInit(() => {
  // 等待文字动画结束
  setTimeout(() => {
    showInfoText.value = true
  }, 1200)
})

const emit = defineEmits<{
  done: []
}>()

function onBack() {
  emit('done')
}
</script>

<style scoped>
.fade-in {
  opacity: 0;
  animation: g-fade-in 0.2s;
  animation-fill-mode: forwards;
}
</style>
