<template>
  <div class="h-full flex flex-col">
    <SafeAreaTopSpacer
      v-if="_global.isMobileMode"
      class="bg-ld-brand-100"
    />

    <RankHeader
      :rank="currentRank"
      class="w-full"
    />

    <div class="bg-ld-brand-100 py-10px">
      <div class="mb-1 text-center text-21px font-semibold">
        {{ rankTitle }}
      </div>

      <div class="font-semibold text-15px text-center">
        {{ _t('rank.msg5') }}
        <span class="text-ld-brand-500">{{ leftTimeLabel }}</span>
        {{ _t('rank.msg6') }}
        <span class="text-ld-brand-500">{{ upCount }}</span>
        {{ _t('rank.msg7') }}
      </div>
    </div>

    <RatioSpacedContainer
      class="flex-1 p-4 w-full px-7 g-body-width"
      :top="1"
      :bottom="1"
    >
      <Img
        name="ld-greet"
        class="w-100px mb-8"
      />

      <div class="text-21px mt-8 mb-6 text-center">
        {{ _t('rank.msg11') }}
      </div>

      <Button
        scene="secondary"
        class="w-full"
        @click="onChallenge"
      >
        <div
          class="flex-1 text-center text-21px font-semibold text-ld-brand-500"
        >
          {{ _t('learn.to_atlas') }}
        </div>
      </Button>
    </RatioSpacedContainer>
  </div>
</template>
<script lang="ts" setup>
import { type RankHomeNotQualified } from '@/types/rank'
import { computed, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import RankHeader from './RankHeader.vue'
import { getRankTitle, parseRank } from './util'
import { formatDeadline } from '@/utils'

const router = useRouter()

const props = defineProps<{
  rankHome: RankHomeNotQualified
}>()

const { seasonEndTime, upCount } = toRefs(props.rankHome.data)

const currentRank = computed(() => parseRank(props.rankHome.data.currentRank))

const leftTimeLabel = computed(() => formatDeadline(seasonEndTime.value))

const rankTitle = computed(() => getRankTitle(currentRank.value))

function onChallenge() {
  router.replace({ name: 'atlas' })
}
</script>
<style scoped></style>
