<template>
  <div
    class="py-48px px-4 flex flex-col items-center w-full h-full g-body-width"
  >
    <RankIcon
      :rank="afterRank"
      :class="[
        'w-175px h-175px mb-8',
        {
          'fade-in': !isRankDown,
        },
      ]"
      :starFontWidth="18"
      :style="{ animationDelay: getFadeInDelay() }"
    />

    <div
      :class="[
        'text-21px font-semibold',
        {
          'fade-in': !isRankDown,
        },
      ]"
      :style="{ animationDelay: getFadeInDelay() }"
    >
      {{ title }}
    </div>

    <div
      :class="[
        'text-36px font-semibold mb-4',
        {
          'fade-in': !isRankDown,
        },
      ]"
      :style="{ animationDelay: getFadeInDelay() }"
    >
      {{ afterRankTitle }}
    </div>

    <div
      :class="[
        'text-ld-label-secondary text-21px mb-8',
        {
          'fade-in': !isRankDown,
        },
      ]"
      :style="{ animationDelay: getFadeInDelay() }"
    >
      <template v-if="result === SeasonResult.Missed">
        {{ _t('rank.miss', { title: beforeRankTitle }) }}
      </template>

      <template v-else>
        {{ _t('rank.last_rank', { rank: beforeRankTitle }) }}

        <span class="text-ld-brand-500 font-semibold">
          {{ _t('rank.position', { index: groupPosition }) }}
        </span>
      </template>
    </div>

    <div class="w-full mt-auto">
      <template v-if="isRankDown">
        <Button
          twoLines
          :class="[
            'w-full',
            {
              'fade-in': !isRankDown,
            },
          ]"
          :loading="isRankKeeping"
          :style="{ animationDelay: getFadeInDelay() }"
          @click="onRankKeepByDiamond"
        >
          <div class="flex-1 flex flex-col items-center font-semibold">
            <div class="mx-auto whitespace-nowrap">
              {{ _t('rank.keep', { rankTitle: beforeRankTitle }) }}
            </div>

            <div class="mx-auto flex items-center">
              <Icon
                name="diamond"
                class="w-14px mr-1"
              />

              <span class="text-13px leading-14px">
                {{ keepRankDiamond }}
              </span>
            </div>
          </div>
        </Button>

        <Button
          scene="secondary"
          :class="[
            'w-full mt-3',
            {
              'fade-in': !isRankDown,
            },
          ]"
          :loading="isRankClaiming"
          :style="{ animationDelay: getFadeInDelay() }"
          @click="onRankRewardClaimed"
        >
          <span class="flex-1 text-center text-ld-brand-500 font-semibold">
            {{ _t('rank.challenge', { rankTitle: afterRankTitle }) }}
          </span>
        </Button>
      </template>

      <Button
        v-else
        :label="_t('rank.start_challenge', { rankTitle: afterRankTitle })"
        :class="[
          'w-full',
          {
            'fade-in': !isRankDown,
          },
        ]"
        :style="{ animationDelay: getFadeInDelay() }"
        :loading="isRankClaiming"
        @click="onRankRewardClaimed"
      ></Button>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { SeasonResult, type RankHomeNotClaimed } from '@/types/rank'
import { getRankTitle, parseRank } from './util'
import { computed, ref, toRefs } from 'vue'
import RankIcon from '@/components/RankIcon.vue'
import { claimRankReward, keepRankByDiamond } from '@/api/rank'
import { Code } from '@/api/code'

const props = defineProps<{
  rankHome: RankHomeNotClaimed
}>()

const emit = defineEmits<{
  rewardClaimed: []
  rankKeeped: []
}>()

const { result, keepRankDiamond, groupPosition, id } = toRefs(
  props.rankHome.data
)

const isRankDown = computed(() => {
  return [SeasonResult.Down1, SeasonResult.Missed].includes(result.value)
})

const title = computed(() => {
  switch (result.value) {
    case SeasonResult.Up1:
    case SeasonResult.Up2:
      return _t('rank.msg8')
    case SeasonResult.Keep:
      return _t('rank.msg9')
    case SeasonResult.Down1:
    case SeasonResult.Missed:
      return _t('rank.msg10')
    default:
      return ''
  }
})

const beforeRank = computed(() => parseRank(props.rankHome.data.beforeRank))
const afterRank = computed(() => parseRank(props.rankHome.data.afterRank))

const afterRankTitle = computed(() => getRankTitle(afterRank.value))
const beforeRankTitle = computed(() => getRankTitle(beforeRank.value))

let delay = 0
function getFadeInDelay() {
  const delayStyle = `${delay}s`

  delay++

  return delayStyle
}

const isRankClaiming = ref(false)
function onRankRewardClaimed() {
  if (isRankClaiming.value) return

  isRankClaiming.value = true
  claimRankReward(id.value)
    .then(res => {
      if (res.code !== Code.Ok) {
        _message.info(res.message)
        return
      }

      emit('rewardClaimed')
    })
    .finally(() => {
      isRankClaiming.value = false
    })
}

const isRankKeeping = ref(false)
function onRankKeepByDiamond() {
  if (isRankKeeping.value) return

  isRankKeeping.value = true
  keepRankByDiamond(id.value)
    .then(res => {
      if (res.code !== Code.Ok) {
        _message.info(res.message)

        if (res.code === Code.NotEnoughDiamonds) {
          _openDiamondGetDialog()
        }
        return
      }

      // 重新获取一下钻石数量
      _store.fetchUserAssets()

      emit('rankKeeped')
    })
    .finally(() => {
      isRankKeeping.value = false
    })
}
</script>
<style scoped>
.fade-in {
  opacity: 0;
  animation: g-fade-in 0.5s;
  animation-fill-mode: forwards;
}
</style>
