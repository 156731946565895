<template>
  <div class="w-full flex flex-col items-center bg-ld-background p-4">
    <div class="w-full flex items-enter h-24px mb-4">
      <div class="text-17px mx-auto">{{ _t('package.add') }}</div>

      <Icon
        name="close-circle"
        class="cursor-pointer absolute right-4 z-1 w-25px"
        @click="emit('done')"
      ></Icon>
    </div>

    <div class="flex flex-col w-full gap-2">
      <div
        class="option bg-cyan-50 border-1px border-solid border-cyan-100"
        @click="onPkgSearch"
      >
        <Icon
          name="search"
          class="w-32px text-cyan-500"
        />

        <div class="flex flex-col gap-1">
          <div class="text-17px text-cyan-900 font-semibold">
            {{ _t('package.explore') }}
          </div>
          <div class="text-15px text-cyan-700">
            {{ _t('package.search') }}
          </div>
        </div>
      </div>

      <div
        class="option bg-violet-50 border-1px border-solid border-violet-100"
        @click="onPkgCreate"
      >
        <Icon
          name="pkg-create"
          class="w-32px text-violet-500"
        />

        <div class="flex flex-col gap-1">
          <div class="text-17px text-violet-900 font-semibold">
            {{ _t('package.create') }}
          </div>
          <div class="text-15px text-violet-700">
            {{ _t('package.create_tip') }}
          </div>
        </div>
      </div>

      <div
        class="option bg-ld-premium-50 border-1px border-solid border-ld-premium-100"
        @click="onDocImport"
      >
        <Icon
          name="pkg-import"
          class="w-32px text-ld-premium-500"
        />

        <div class="flex flex-col gap-1">
          <div
            class="text-17px text-ld-premium-900 font-semibold flex items-center"
          >
            {{ _t('package.import') }}
            <div
              class="text-13px text-white leading-[1.5] font-semibold px-6px bg-gray-300 rounded-4px ml-1"
            >
              Beta
            </div>
          </div>
          <div class="text-15px text-ld-premium-700">
            {{ _t('package.import_tip') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { openLink } from '@/utils'

const emit = defineEmits<{
  done: []
  search: []
  create: []
}>()

function onPkgSearch() {
  emit('done')
  emit('search')
}

function onPkgCreate() {
  emit('done')
  emit('create')
}

// 现在确认弹窗的逻辑先放在这里，未来做具体的导入逻辑时
// 还是需要由外层来处理具体的业务逻辑

function onDocImport() {
  _confirm({
    content: _t(
      '导入功能尚未开放\n你可以先提交你需要导入的文档\n如果有其他用户共享了相关卡包，我们将第一时间通知你'
    ),
    scene: 'confirm',
    primaryText: 'package.submit_doc',
    secondaryText: _t('common.not_yet'),
    onPrimaryClick(resolve) {
      const SURVERY_BASE_LINK =
        'https://qianmojiaoyu.feishu.cn/share/base/form/shrcnh8V43DR5EATcl3aqb81B8Y'
      const surveryLink =
        SURVERY_BASE_LINK + `?prefill_lid=${_store.user!.id}&hide_lid=1`
      openLink(surveryLink)
      resolve(true)
    },
  })
}
</script>
<style scoped>
.option {
  width: 100%;
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  cursor: pointer;
}
</style>
