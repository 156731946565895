<template>
  <Tooltip
    :show="props.showGuide"
    trigger="manual"
    placement="bottom"
  >
    <span>{{ _t('learn.show_last_card') }}</span>

    <template #trigger>
      <Icon
        name="last-card"
        :class="[
          'w-20px text-ld-text cursor-pointer shrink-0',
          {
            'opacity-30': props.disabled,
          },
        ]"
        @click="onClick"
      />
    </template>
  </Tooltip>
</template>
<script setup lang="ts">
const props = defineProps<{
  disabled: boolean
  showGuide: boolean
}>()

const emit = defineEmits<{
  click: []
}>()

function onClick() {
  if (props.disabled) {
    _message.info(_t('learn.first_card'))
    return
  }

  emit('click')
}
</script>
<style scoped></style>
