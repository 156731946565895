<template>
  <div v-if="parsed.error">
    {{ _t('cardview.parse_failed') }}
  </div>

  <template v-else>
    <div class="bg-white p-3 rounded-8px">
      <Img
        v-if="card.illustration"
        :assetId="card.illustration"
        :class="[
          'rounded-4px mb-2 cursor-pointer transition-width select-none',
          showLargeIllustration ? 'w-full' : 'w-100px',
        ]"
        @click.stop="toggleIllustrationSize"
      />

      <Content :content="parsed.card.content">
        <template #text="{ node }">
          <Text
            class="content"
            :node="node"
            :show-dot-style="false"
          ></Text>
        </template>

        <template #cloze="{ node }">
          <span class="font-bold g-cloze">
            {{ node.text }}
          </span>
        </template>
      </Content>
    </div>

    <div
      v-if="!isCardAnalysisEmpty"
      class="bg-white p-3 rounded-8px mt-4"
    >
      <div
        class="text-gray-600 bg-gray-200 rounded-4px text-13px leading-[1.5] px-2 w-fit mb-2"
      >
        {{ _t('cardview.analysis') }}
      </div>
      <Content :content="parsed.card.analysis"></Content>
    </div>
  </template>
</template>
<script setup lang="ts">
import { parseClozeCard, stringifyContent } from '@/utils/card'
import Text from '../card-render/Text.vue'
import Content from '../card-render/Content.vue'
import { computed, ref } from 'vue'

import type { ClozeCard } from '@/types/core'

const props = defineProps<{
  card: ClozeCard
}>()

const parsed = parseClozeCard(props.card)

const showLargeIllustration = ref(false)

const isCardAnalysisEmpty = computed(() => {
  return stringifyContent(parsed.card.analysis).trim().length === 0
})

function toggleIllustrationSize() {
  showLargeIllustration.value = !showLargeIllustration.value
}
</script>
<style scoped></style>
