<template>
  <div class="g-body-width py-6 overflow-y-auto flex-1 min-h-0">
    <div class="flex gap-2 flex-1 px-4">
      <div class="flex-1 flex flex-col gap-4 overflow-hidden">
        <Button
          v-for="(item, i) in definations"
          :key="i"
          :scene="getButtonScene(i, selectedDefinationIndex)"
          :disabled="completedDefination.has(i)"
          :class="['option']"
          @click="onDefinationSelect(i)"
        >
          <AutoFontSizeByLine
            class="flex-1 line-clamp-3"
            :font-size-list="[21, 19, 17, 15]"
          >
            {{ item }}
          </AutoFontSizeByLine>
        </Button>
      </div>

      <div class="flex-1 flex flex-col gap-4 overflow-hidden">
        <Button
          v-for="(item, i) in words"
          :key="i"
          :scene="getButtonScene(i, selectedWordIndex)"
          :disabled="completedWord.has(i)"
          :class="['option']"
          @click="onWordSelect(i)"
        >
          <AutoFontSizeByLine
            class="flex-1 line-clamp-3"
            :font-size-list="[19, 17, 15]"
          >
            {{ item }}
          </AutoFontSizeByLine>
        </Button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { type VirtualCardMatch } from '@/types/core'
import { FeedbackStar } from '@/components/ConcreteCard/common/feedback'
import { ref } from 'vue'
import { UnitEventType } from '@/types/core'
import { shuffle } from 'lodash-es'
import type { ButtonScene } from '../Button.vue'
import { vibrate, VibrateType } from '@/utils'
import AutoFontSizeByLine from '../AutoFontSizeByLine.vue'

const props = defineProps<{
  match: VirtualCardMatch
}>()

const emit = defineEmits<{
  close: []
  star: [star: FeedbackStar]
  event: [undefined, UnitEventType]
  correct: []
  done: []
}>()

const definations = ref<string[]>([])
const words = ref<string[]>([])

const selectedDefinationIndex = ref<number>()
const selectedWordIndex = ref<number>()
const completedDefination = ref<Set<number>>(new Set())
const completedWord = ref<Set<number>>(new Set())
const result = ref<'correct' | 'wrong'>()
const passed = ref(false)
const wrongTimes = ref(0)

function onWordSelect(index: number) {
  if (selectedWordIndex.value != null) return
  selectedWordIndex.value = index

  checkAnswer()
}

function onDefinationSelect(index: number) {
  if (selectedDefinationIndex.value != null) return

  selectedDefinationIndex.value = index
  checkAnswer()
}

function checkAnswer() {
  if (selectedDefinationIndex.value == null || selectedWordIndex.value == null)
    return
  const defination = definations.value[selectedDefinationIndex.value]
  const word = words.value[selectedWordIndex.value]

  // 选中的单词的卡片
  const wordCard = props.match.cards.find(item => item.word === word)!

  if (wordCard.definition.trim() === defination.trim()) {
    result.value = 'correct'
    completedDefination.value.add(selectedDefinationIndex.value)
    completedWord.value.add(selectedWordIndex.value)
    emit('correct')
    vibrate(VibrateType.Success)
  } else {
    result.value = 'wrong'
    wrongTimes.value++
    vibrate(VibrateType.Warning)
  }

  setTimeout(() => {
    result.value = undefined
    selectedDefinationIndex.value = undefined
    selectedWordIndex.value = undefined
  }, 200)

  const allDone = completedDefination.value.size === props.match.cards.length

  if (allDone) {
    passed.value = true
    const star = wrongTimes.value > 0 ? FeedbackStar.Two : FeedbackStar.Three
    emit('star', star)
    emit('event', undefined, UnitEventType.CORRECT)
  }
}

function getButtonScene(index: number, selectedIndex?: number): ButtonScene {
  if (selectedIndex === index) {
    if (result.value === 'correct') {
      return 'choiceCorrect'
    } else if (result.value === 'wrong') {
      return 'choiceWrong'
    }

    return 'choiceSelected'
  }

  return 'choice'
}

function generateOptions() {
  words.value = shuffle(props.match.cards.map(item => item.word))
  definations.value = shuffle(props.match.cards.map(item => item.definition))
}

onInit(generateOptions)
</script>

<style scoped>
.option {
  height: 100px;
}
</style>
