<template>
  <div
    :style="style"
    class="image-container"
  >
    <div
      v-if="isLoading"
      class="placeholder"
    ></div>

    <img
      v-if="src != null"
      :src="src"
      alt=""
      class="w-full h-full object-contain"
      @load="handleLoad"
      @error="handleError"
    />

    <div
      v-if="isError || src == null"
      class="error-ui"
    >
      <div style="flex: 30"></div>

      <Icon
        name="img-error"
        style="flex: 80"
      />

      <div
        style="flex: 15"
        class="text-ld-label-secondary"
      >
        {{ _t('common.load_failed') }}
      </div>
      <div style="flex: 46"></div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { parseImgSize, resolveImgPath } from '@/utils/img'
import { computed, ref } from 'vue'

// name 或 assetId 必定要传一个
const props = defineProps<{
  name?: string

  assetId?: string
  imgStyle?: string
}>()

const src = computed(() => {
  if (props.assetId) {
    return (
      _am.getImageAssetId(props.assetId, props.imgStyle) ??
      _global.assetUrl(props.assetId, props.imgStyle)
    )
  }

  if (props.name) {
    return _am.getImageName(props.name) ?? resolveImgPath(props.name)
  }

  return null
})

const ratio = computed<number | null>(() => {
  let pathOrAssetId: string | undefined

  if (props.assetId) {
    pathOrAssetId = props.assetId
  } else if (props.name) {
    pathOrAssetId = resolveImgPath(props.name)
  }

  if (pathOrAssetId == null) return null

  const size = parseImgSize(pathOrAssetId)

  if (size) {
    return size.width / size.height
  }

  return null
})

const style = computed(() => {
  if (ratio.value == null) return {}

  return {
    aspectRatio: ratio.value,
  }
})
// 定义状态
const isLoading = ref(true)
const isError = ref(false)
function handleLoad() {
  isError.value = false
  isLoading.value = false
}
function handleError() {
  isError.value = true
  isLoading.value = false
}
</script>

<style scoped>
.image-container {
  position: relative;
  overflow: hidden;
}

.placeholder,
.error-ui {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.placeholder {
  background: linear-gradient(
    270deg,
    var(--gray-50) 18.75%,
    var(--gray-200) 179.69%
  );
}

.error-ui {
  background: var(--ld-background);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
