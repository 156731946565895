import type { CardSchedule } from '@/api/learn'
import { ceil, cloneDeep, merge, sortBy } from 'lodash-es'
import {
  type CardFace,
  LearnStatus,
  CardType,
  ClozeCardFaceType,
  EnWordCardFaceType,
  type VirtualCardBoss,
  VirtualCardType,
  MCQCardFaceType,
  type Card,
  type VirtualCardMatch,
  type EnWordCard,
  UnitEventType,
} from '@/types/core'
import { getContentClozes } from '@/utils/card'
import { randomPick, randomPickBoss } from '@/utils'
import { shuffle } from 'lodash-es'
import {
  parseCardSchedule,
  getAltCards,
  type QueueCard,
  orderCards,
} from '@/shared/queue'
import { FeedbackStar } from '@/components/ConcreteCard/common/feedback'
import {
  EnWordCardDifficulty,
  MCQCardDifficulty,
  MultiClozeCardDifficulty,
  SingleClozeCardDifficulty,
} from '../config'
import { CardTypeName } from '@/api/package-source'

// 生成配对卡需要的卡片数
const MATCH_CARD_COUNT = 5

// 队列学习阶段
export enum QueueStage {
  // 练习
  Practice = 'Practice',

  // 错题
  WrongCards = 'WrongCards',

  // boss
  Boss = 'Boss',

  // 完成
  Done = 'Done',
}

export type Frame =
  | PracticeFrame
  | BossStartFrame
  | BossFrame
  | DoneFrame
  | WrongCardsStartFrame
  | WrongCardFrame

export enum FrameType {
  Practice = 'practice',
  Done = 'done',
  Boss = 'boss',
  BossStart = 'bossStart',
  WrongCardsStart = 'WrongCardsStart',
  WrongCards = 'WrongCards',
}

type PracticeFrame = {
  type: FrameType.Practice
  face: CardFace
  cardId: number
}

type WrongCardFrame = {
  type: FrameType.WrongCards
  face: CardFace
  cardId: number
}

type BossStartFrame = {
  type: FrameType.BossStart
  name: string
}

type WrongCardsStartFrame = {
  type: FrameType.WrongCardsStart
}

type BossFrame = {
  type: FrameType.Boss
  boss: VirtualCardBoss
}

type DoneFrame = {
  type: FrameType.Done
}

export interface WrongCardsQueueState {
  frames: Frame[]
  cardDifficulty: Record<number, number>
  cardPracticed: Record<number, number>
  cardWillPricticeCount: Record<number, number>
  wrongCardsCompleted: number[]
  wrongCardsFailed: Record<number, number>
  wrongCards: {
    cardId: number
    faceType: ClozeCardFaceType | EnWordCardFaceType | MCQCardFaceType
  }[]
  lastCard: Card | null
}

export type WrongCardsQueueConfig = {
  cardType: CardTypeName
  // 知识点卡片单个挖空的卡面难度配置
  singleClozeCardDifficulty: typeof SingleClozeCardDifficulty
  // 知识点卡片多个挖空的卡面难度配置
  multiClozeCardDifficulty: typeof MultiClozeCardDifficulty
  // 单词卡的卡面难度配置
  enWordCardDifficulty: typeof EnWordCardDifficulty
  // MCQ 卡的卡面难度配置
  mcqCardDifficulty: typeof MCQCardDifficulty
}

function getDifficultyByR(r: number) {
  if (r >= 95) {
    return 3
  } else if (r >= 90) {
    return 2
  } else {
    return 1
  }
}

// 队列详细文档：https://qianmo.atlassian.net/browse/LD-1495
export class WrongCardsQueue {
  constructor(cards: CardSchedule[], config: Partial<WrongCardsQueueConfig>) {
    this.cards = cards.map(parseCardSchedule)
    this.config = merge({}, defaultConfig, config ?? {})
    this.debugInfo = {
      config: this.config,
      cards: [...cards],
      logs: [],
    }
    this.init()
    this.frames = this.genPracticeFrames()
    this.debugLog({
      msg: 'practice frames generated',
      frames: this.frames,
    })
  }

  setState(state: WrongCardsQueueState) {
    this.debugLog({ msg: 'setState', state })

    this.frames = state.frames
    this.cardDifficulty = state.cardDifficulty
    this.cardPracticed = state.cardPracticed
    this.cardWillPricticeCount = state.cardWillPricticeCount
    this.wrongCardsCompleted = new Set(state.wrongCardsCompleted)
    this.wrongCards = state.wrongCards
    this.wrongCardsFailed = state.wrongCardsFailed
    this.lastCard = state.lastCard
  }

  getState(): WrongCardsQueueState {
    return {
      frames: this.frames,
      cardDifficulty: this.cardDifficulty,
      cardPracticed: this.cardPracticed,
      cardWillPricticeCount: this.cardWillPricticeCount,
      wrongCardsCompleted: [...this.wrongCardsCompleted],
      wrongCards: this.wrongCards,
      wrongCardsFailed: this.wrongCardsFailed,
      lastCard: this.lastCard,
    }
  }

  // 始终会保证有值
  currentFrame(): Frame {
    const frame = this.frames[0]!
    this.debugLog({ msg: 'get current frame', frame })
    return frame
  }

  getLastCard(): Card | null {
    return this.lastCard
  }

  tick(star?: FeedbackStar) {
    this.tickCount++
    const idx = this.tickCount

    this.debugLog({
      msg: `tick[${idx}]: start to tick`,
      star,
      frames: this.frames,
    })

    const frame = this.currentFrame()

    _global.assert(frame != null, 'frame should never be null', this.debugInfo)

    if (frame.type === FrameType.Done) {
      this.debugLog({ msg: `tick[${idx}]: done frame` })
      return
    }

    const isCorrect = star === FeedbackStar.Three
    // 当前还处于练习阶段
    if (frame.type === FrameType.Practice) {
      this.practiceCard(frame.cardId)
      this.debugLog({ msg: `tick[${idx}]: practice stage` })
      this.nextFrame()

      switch (frame.face.card.type) {
        case CardType.CLOZE:
          this.increaseCardDifficulty(frame.cardId)
          break
        case CardType.EN_WORD:
          this.cardDifficulty[frame.cardId] = 2
          break
        // 由于 MCQ 卡片只会出现一次，所以不需要处理难度
      }

      // 如果答错，则放在错题队尾并保持卡面不变
      if (
        !isCorrect &&
        !this.wrongCards.some(item => item.cardId == frame.cardId)
      ) {
        this.wrongCards.push({
          cardId: frame.cardId,
          faceType: frame.face.type,
        })
      }

      // 如果练习次数未达到上限，继续学习
      const practiceCount = this.cardPracticed[frame.cardId]
      const willPracticeCount = this.cardWillPricticeCount[frame.cardId]

      if (practiceCount < willPracticeCount) {
        const difficulity = this.cardDifficulty[frame.cardId] as any

        this.frames.splice(this.frames.length > 2 ? 2 : this.frames.length, 0, {
          type: FrameType.Practice,
          cardId: frame.cardId,
          face: this.genCardFace(frame.cardId, difficulity, {
            hideRoleImage: false,
            isFirstLearn: false,
            showCardDetail: true,
          }),
        })
      }

      this.debugLog({ msg: `tick[${idx}]: exceeded practice times` })

      // 如果练习全部学完，则生成错题队列
      if (this.frames.length === 0) {
        this.frames = this.genWrongCardsFrames()

        this.debugLog({
          msg: `tick[${idx}]: wrongCards frames generated`,
          frames: this.frames,
        })
      }
    }

    if (frame.type === FrameType.WrongCards) {
      this.debugLog({ msg: `tick[${idx}]: wrongCards frame` })
      this.nextFrame()

      // 已经答错过的次数
      const failedTimes = this.wrongCardsFailed[frame.cardId] ?? 0

      // 如果答对，则结束错题
      if (isCorrect) {
        this.wrongCardsCompleted.add(frame.cardId)
        this.increaseCardDifficulty(frame.cardId)
      } else if (!isCorrect && failedTimes === 0) {
        // 如果是第一次答错，则难度不变并放在 2 张卡片之后
        this.wrongCardsFailed[frame.cardId] = failedTimes + 1
        this.frames.splice(this.frames.length > 2 ? 2 : this.frames.length, 0, {
          type: FrameType.WrongCards,
          cardId: frame.cardId,
          face: this.genCardFace(
            frame.cardId,
            this.cardDifficulty[frame.cardId] as any,
            {
              hideRoleImage: false,
              isFirstLearn: false,
              isWrongCard: true,
              showCardDetail: true,
            }
          ),
        })
      } else if (!isCorrect) {
        // 如果第二次答错，难度 -1
        this.decreaseCardDifficulty(frame.cardId)
        this.wrongCardsCompleted.add(frame.cardId)
      }

      // 如果错题全部学完，则生成 boss 卡面
      if (this.frames.length === 0) {
        this.frames = this.genBossFrames()
        this.debugLog({
          msg: `tick[${idx}]: boss frames generated`,
          frames: this.frames,
        })
      }
    }

    if (
      frame.type === FrameType.WrongCardsStart ||
      frame.type === FrameType.BossStart ||
      frame.type === FrameType.Boss
    ) {
      this.debugLog({ msg: `tick[${idx}]: boss frame` })
      this.nextFrame()
      return
    }
  }

  // 练习/错题阶段的进度
  get progress() {
    const currentFrame = this.currentFrame()
    if (
      currentFrame.type === FrameType.WrongCards ||
      this.wrongCardsCompleted.size > 0
    ) {
      return (this.wrongCardsCompleted.size / this.wrongCards.length) * 100
    }

    const practiced = Object.values(this.cardPracticed).reduce(
      (acc, cur) => acc + cur,
      0
    )
    const total = Object.values(this.cardWillPricticeCount).reduce(
      (acc, cur) => acc + cur,
      0
    )
    return (practiced / total) * 100
  }

  private cardPracticed: Record<number, number> = {}
  // 每张卡片练习阶段应该学多少次
  private cardWillPricticeCount: Record<number, number> = {}

  get isDone() {
    const frame = this.currentFrame()
    return frame == null || frame.type === FrameType.Done
  }

  private cards: QueueCard[] = []
  private config: WrongCardsQueueConfig
  debugInfo: {
    config: WrongCardsQueueConfig
    cards: CardSchedule[]
    logs: any[]
  }
  private tickCount = 0
  private debugLog(input: object) {
    const payload = cloneDeep(input) as any
    payload.ts = Date.now()
    this.debugInfo.logs.push(payload)
  }

  private frames: Frame[] = []

  private lastCard: Card | null = null

  // 每个卡片当前的难度
  private cardDifficulty: Record<number, number> = {}

  // 已完成的错题数量
  private wrongCardsCompleted: Set<number> = new Set()
  // 已经答错过的错题数量
  private wrongCardsFailed: Record<number, number> = {}

  // 错题阶段的卡片以及卡面
  private wrongCards: {
    cardId: number
    faceType: ClozeCardFaceType | EnWordCardFaceType | MCQCardFaceType
  }[] = []

  private practiceCard(cardId: number) {
    const learnedCount = this.cardPracticed[cardId] ?? 0
    this.cardPracticed[cardId] = learnedCount + 1
  }

  // 根据卡片+难度生成需要渲染的卡片
  private genCardFace(
    cardId: number,
    difficulity: 1 | 2 | 3,
    {
      hideRoleImage,
      isFirstLearn,
      isWrongCard,
      showCardDetail,
    }: {
      hideRoleImage: boolean
      isFirstLearn: boolean
      isWrongCard?: boolean
      showCardDetail: boolean
    }
  ): CardFace {
    const item = this.cards.find(item => item.cardId === cardId)
    if (item == null) {
      throw '[WrongCardsQueue] unkown card id when generate card face'
    }
    const altCards = getAltCards(this.cards, item.cardId, item.card)

    let faceType: ClozeCardFaceType | EnWordCardFaceType | MCQCardFaceType

    switch (item.card.type) {
      case CardType.CLOZE: {
        const isSingleCloze = getContentClozes(item.card.content).length === 1
        const config = isSingleCloze
          ? this.config.singleClozeCardDifficulty
          : this.config.multiClozeCardDifficulty
        faceType = randomPick(config[difficulity])
        break
      }
      case CardType.EN_WORD:
        faceType = randomPick(this.config.enWordCardDifficulty[difficulity])
        break
      case CardType.MCQ:
        faceType = randomPick(this.config.mcqCardDifficulty[difficulity])
        break
      default:
        _global.unreachable(item.card)
    }

    return {
      type: faceType!,
      cardId: item.cardId,
      card: item.card,
      altCards,
      isFirstLearn,
      isWrongCard,
      style: {
        showCardDetail,
        hideTip: false,
        hideRoleImage,
        operationLayout: 'horizontal',
      },
    } as CardFace
  }

  // 生成每张卡片的初始难度
  private init() {
    for (const card of this.cards) {
      const r = card.r * 100
      this.cardPracticed[card.cardId] = 0

      switch (card.card.type) {
        case CardType.CLOZE:
          this.cardWillPricticeCount[card.cardId] =
            card.cardStatus === LearnStatus.DEBUT ? 2 : 1
          this.cardDifficulty[card.cardId] =
            card.cardStatus === LearnStatus.DEBUT ? 2 : getDifficultyByR(r)
          break
        case CardType.EN_WORD:
          this.cardWillPricticeCount[card.cardId] = 2
          this.cardDifficulty[card.cardId] =
            card.cardStatus === LearnStatus.DEBUT ? 1 : getDifficultyByR(r)
          break
        case CardType.MCQ:
          this.cardWillPricticeCount[card.cardId] = 1
          this.cardDifficulty[card.cardId] =
            card.cardStatus === LearnStatus.DEBUT ? 2 : getDifficultyByR(r)
      }
    }
    this.debugLog({ msg: 'inited', cardDifficulty: this.cardDifficulty })
  }

  private genPracticeFrames(): PracticeFrame[] {
    const frames: PracticeFrame[] = []
    const orderedCards = orderCards(this.cards)

    for (const item of orderedCards) {
      const difficulity = this.cardDifficulty[item.cardId] as any

      frames.push({
        type: FrameType.Practice,
        face: this.genCardFace(item.cardId, difficulity, {
          hideRoleImage: false,
          isFirstLearn: item.cardStatus === LearnStatus.DEBUT,
          showCardDetail: true,
        }),
        cardId: item.cardId,
      })
    }

    return frames
  }

  private tryGenerateMatchCard(): VirtualCardMatch | undefined {
    const enCards: EnWordCard[] = []

    // 优先找做错过的
    const sortedCards = sortBy(this.cards, item => {
      const events = _store.stageUnit!.events[item.cardId] ?? []

      if (events.some(evt => evt.event === UnitEventType.WRONG)) {
        return -1
      }

      return 0
    })

    for (const card of sortedCards) {
      if (card.card.type !== CardType.EN_WORD) return

      if (enCards.length >= MATCH_CARD_COUNT) {
        break
      }

      enCards.push(card.card)
    }

    if (enCards.length >= MATCH_CARD_COUNT) {
      return {
        type: VirtualCardType.Match,
        cards: shuffle(enCards),
      }
    }
  }

  private genWrongCardsFrames(): Frame[] {
    const faces: CardFace[] = []

    for (const item of this.wrongCards) {
      const card = this.cards.find(e => e.cardId === item.cardId)!.card
      const altCards = getAltCards(this.cards, item.cardId, card)

      faces.push({
        type: item.faceType,
        cardId: item.cardId,
        card: card,
        altCards,
        isFirstLearn: false,
        isWrongCard: true,
        style: {
          showCardDetail: true,
          hideTip: false,
          hideRoleImage: false,
          operationLayout: 'horizontal',
        },
      } as CardFace)
    }

    // 如果没有答错的卡片，则直接生成 boss 即可
    if (faces.length === 0) {
      return this.genBossFrames()
    }

    return [
      {
        type: FrameType.WrongCardsStart,
      },
      ...faces.map(face => {
        return {
          type: FrameType.WrongCards,
          face,
          cardId: face.cardId,
        } as WrongCardFrame
      }),
    ]
  }

  private genBossFrames(): (BossStartFrame | BossFrame | DoneFrame)[] {
    const faces: CardFace[] = []

    for (const item of shuffle(this.cards)) {
      faces.push(
        this.genCardFace(item.cardId, this.cardDifficulty[item.cardId] as any, {
          hideRoleImage: true,
          isFirstLearn: false,
          showCardDetail: false,
        })
      )
    }

    const bossName = randomPickBoss()
    const matchCard = this.tryGenerateMatchCard()
    const totalHpLengths =
      faces.length + (matchCard != null ? MATCH_CARD_COUNT : 0)
    const tickHp = ceil((1 / totalHpLengths) * 100, 2)

    return [
      {
        type: FrameType.BossStart,
        name: bossName,
      },
      ...faces.map((face, i) => {
        return {
          type: FrameType.Boss,
          boss: {
            type: VirtualCardType.Boss,
            cardFace: face,
            name: bossName,
            tickHp,
            hp: (totalHpLengths - i) * tickHp,
          },
        } as BossFrame
      }),
      ...(matchCard == null
        ? [
            {
              type: FrameType.Boss,
              boss: {
                type: VirtualCardType.Boss,
                name: bossName,
                hp: 0,
                tickHp,
              },
            } as BossFrame,
          ]
        : [
            {
              type: FrameType.Boss,
              boss: {
                type: VirtualCardType.Boss,
                name: bossName,
                hp: (MATCH_CARD_COUNT / totalHpLengths) * 100,
                tickHp,
                cardFace: matchCard,
              },
            } as BossFrame,
          ]),

      {
        type: FrameType.Done,
      },
    ]
  }

  private increaseCardDifficulty(cardId: number) {
    const difficulity = this.cardDifficulty[cardId]

    if (difficulity >= 3) {
      this.cardDifficulty[cardId] = 3
    } else {
      this.cardDifficulty[cardId] = difficulity + 1
    }

    return this.cardDifficulty[cardId] as 1 | 2 | 3
  }

  private decreaseCardDifficulty(cardId: number) {
    const difficulity = this.cardDifficulty[cardId]

    if (difficulity <= 1) {
      this.cardDifficulty[cardId] = 1
    } else {
      this.cardDifficulty[cardId] = difficulity - 1
    }

    return this.cardDifficulty[cardId] as 1 | 2 | 3
  }

  private nextFrame() {
    const frame = this.currentFrame()

    if (frame.type === FrameType.Practice) {
      this.lastCard = frame.face.card
    } else if (
      frame.type === FrameType.Boss &&
      frame.boss.cardFace?.type !== VirtualCardType.Match
    ) {
      this.lastCard = frame.boss.cardFace?.card ?? null
    }
    this.frames.shift()
  }
}

export const defaultConfig: WrongCardsQueueConfig = {
  cardType: CardTypeName.CLOZE,
  singleClozeCardDifficulty: SingleClozeCardDifficulty,
  multiClozeCardDifficulty: MultiClozeCardDifficulty,
  enWordCardDifficulty: EnWordCardDifficulty,
  mcqCardDifficulty: MCQCardDifficulty,
}
