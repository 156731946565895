import {
  ClozeCardFaceType,
  EnWordCardFaceType,
  MCQCardFaceType,
} from '@/types/core'

// 卡面难度配置参考：https://qianmojiaoyu.feishu.cn/wiki/YuJ7wp6ZSiMvVok24CUcGfhYngh
export const SingleClozeCardDifficulty = {
  1: [ClozeCardFaceType.GiveAwayChoice, ClozeCardFaceType.GiveAwayJudgement],
  2: [ClozeCardFaceType.MinimalChoice],
  3: [ClozeCardFaceType.Choice, ClozeCardFaceType.Judgement],
}

export const MultiClozeCardDifficulty = {
  1: [ClozeCardFaceType.GiveAwayJudgement],
  2: [ClozeCardFaceType.GiveAwayChoice],
  3: [ClozeCardFaceType.Choice, ClozeCardFaceType.Judgement],
}

export const EnWordCardDifficulty = {
  1: [EnWordCardFaceType.ExampleChoice],
  2: [EnWordCardFaceType.PickImage, EnWordCardFaceType.VoiceChoice],
  3: [EnWordCardFaceType.ExplainChoice, EnWordCardFaceType.WordChoice],
}

// TMP: prod 环境下暂时隐藏单词拼写卡面
if (!_global.isProd) {
  EnWordCardDifficulty[3].push(EnWordCardFaceType.Spell)
}

export const MCQCardDifficulty = {
  1: [MCQCardFaceType.MinimalChoice],
  2: [MCQCardFaceType.Choice],
  3: [MCQCardFaceType.Choice],
}
