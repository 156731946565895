<template>
  <div class="g-body-width flex flex-col gap-4 h-full overflow-hidden">
    <div class="max-h-180px overflow-auto">
      <Textarea
        v-model="input"
        class="w-full border-none outline-none shadow-none p-3 bg-white"
        autoResize
        :placeholder="_t('cardview.ai_gen_tip4')"
        @update:modelValue="onInputChange"
      />
    </div>

    <div class="flex items-center">
      <div
        v-if="input.length > 0"
        :class="['text-17px', isInputOverflow ? 'text-red' : 'text-gray']"
      >
        {{ input.length }}/{{ INPUT_LIMIT }}
        <template v-if="isInputOverflow">
          {{ _t('cardview.overflow_text_limit') }}
        </template>
      </div>

      <Button
        v-if="isAlreadyGenerated"
        class="ml-auto"
        style="white-space: nowrap"
        :disabled="isInputOverflow || store.aiGenerate.loading"
        scene="secondary"
        @click="onAiGenerateAgain"
      >
        <div class="flex gap-1 text-ld-brand-500">
          <Icon
            name="ai-add-card"
            class="h-20px"
          />
          <div class="text-17px leading-20px">
            {{ _t('cardview.ai_regen') }}
          </div>
        </div>
      </Button>

      <Button
        v-else
        class="ml-auto"
        style="white-space: nowrap"
        :disabled="isInputOverflow || store.aiGenerate.loading"
        @click="onAiGenerate"
      >
        <div class="flex gap-1">
          <Icon
            name="ai-add-card"
            class="h-20px"
          />
          <div class="text-17px leading-20px">{{ _t('cardview.ai_gen') }}</div>
        </div>
      </Button>
    </div>

    <div
      ref="resultContainer"
      class="max-h-380px overflow-y-auto flex flex-col gap-4 flex-1"
    >
      <div
        :class="[
          'flex flex-col gap-2 relative shrink-0',
          {
            'min-h-84px': store.aiGenerate.loading,
          },
        ]"
      >
        <AICardKeypointItem
          v-for="(keypoint, index) in store.aiGenerate.keypoints"
          :key="keypoint.id"
          :index="index"
          :focused="focusedKeypointId === keypoint.id"
          :content="keypoint.content"
          @update="onKeypointChange(index, $event)"
          @focus="onKeypointFocus(keypoint.id)"
        >
          <template #action>
            <div class="p-1 cursor-pointer">
              <Icon
                name="trash"
                class="text-red w-24px"
                @click="onCardDelete(index)"
              />
            </div>
            <div class="p-1 cursor-pointer">
              <Icon
                name="ai-card-select"
                class="text-green w-24px cursor-pointer"
                @click="onCardSelect(index)"
              />
            </div>
          </template>
        </AICardKeypointItem>

        <AICardEnWordItem
          v-for="(term, index) in store.aiGenerate.enWordTerms"
          :key="index"
          :index="index"
          :focused="focusedEnWord === index"
          :term="term"
          @update="onEnWordTermChange(index, $event)"
          @focus="onEnWordTermFocus(index)"
        >
          <template #action>
            <div class="p-1 cursor-pointer">
              <Icon
                name="trash"
                class="text-red w-24px"
                @click="onCardDelete(index)"
              />
            </div>
          </template>
        </AICardEnWordItem>

        <AIGenLoading
          v-if="store.aiGenerate.loading"
          class="absolute top-0 left-0 w-full h-full"
          @cancel="onAiGenerateCancel"
        />
      </div>

      <div
        v-if="selectedCards.length > 0"
        class="flex flex-col gap-4"
      >
        <Divider
          class="m-0 text-ld-label-secondary"
          :pt="{ content: { class: '!bg-ld-background' } }"
        >
          {{ _t('cardview.card_seleted') }}
        </Divider>

        <div class="flex flex-col gap-2">
          <AICardKeypointItem
            v-for="(keypoint, index) in selectedCards"
            :id="`keypoint-${keypoint.id}`"
            :key="keypoint.id"
            :index="index"
            :focused="focusedKeypointId === keypoint.id"
            :content="keypoint.content"
            @update="onSelectedKeypointChange(index, $event)"
            @focus="onKeypointFocus(keypoint.id)"
          >
            <template #action>
              <div class="p-1 cursor-pointer">
                <Icon
                  name="ai-card-remove"
                  class="text-red w-24px"
                  @click="onCardUnSelect(index)"
                />
              </div>
            </template>
          </AICardKeypointItem>
        </div>
      </div>
    </div>

    <div
      v-if="isAlreadyGenerated"
      class="flex items-center justify-between gap-3"
    >
      <Button
        :label="_t('common.clear_all')"
        scene="danger"
        @click="onAiGenerateClear"
      ></Button>

      <Button
        v-if="selectedCards.length > 0 || !store.aiGenerate.loading"
        :loading="isCardsCreating"
        :disabled="!isAllEnWordCardValid"
        :disabledMsg="_t('cardview.validate_tip1')"
        :label="
          selectedCards.length > 0
            ? _t('cardview.ai_cards_create', { count: selectedCards.length })
            : _t('cardview.ai_all_cards_create')
        "
        @click="onCardsCreate"
      ></Button>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useCommonStore } from '@/stores'
import Textarea from 'primevue/textarea'
import Divider from 'primevue/divider'
import { ref, computed } from 'vue'
import AIGenLoading from './AIGenLoading.vue'
import AICardKeypointItem from './AICardKeypointItem.vue'
import AICardEnWordItem from './AICardEnWordItem.vue'
import {
  batchCreateCards,
  CardTypeName,
  type AiEnWordTerm,
} from '@/api/package-source'
import { CardCreatedType, type CardResponse } from '@/api/package-source'
import { toAiInput } from '@/utils/aiInput'
import { CardType, type Content, type EnWordCard } from '@/types/core'
import { nextTick } from 'vue'

const props = withDefaults(
  defineProps<{
    packageId: number
    chapterId: string
    cardType?: CardTypeName.CLOZE | CardTypeName.WORD
  }>(),
  {
    cardType: CardTypeName.CLOZE,
  }
)

const emit = defineEmits<{
  cardsCreated: [CardResponse[]]
  done: []
}>()

const resultContainer = ref<HTMLDivElement>()

const store = useCommonStore()

const INPUT_LIMIT = props.cardType === CardTypeName.CLOZE ? 500 : 2000
const input = ref(store.aiGenerate.content)
const isCardsCreating = ref(false)
const focusedKeypointId = ref<string>()
const focusedEnWord = ref<number>()

const isInputOverflow = computed(() => {
  return input.value.length > INPUT_LIMIT
})

const isAlreadyGenerated = computed(() => {
  const {
    keypoints: cards,
    selectedKeypoints: selectedCards,
    enWordTerms,
  } = store.aiGenerate
  return cards.length > 0 || selectedCards.length > 0 || enWordTerms.length > 0
})

const isAllEnWordCardValid = computed(() => {
  return store.aiGenerate.enWordTerms.every(item => {
    return item.term.trim() !== '' && item.definition.trim() !== ''
  })
})

const selectedCards = computed(() => {
  return store.aiGenerate.selectedKeypoints
})

async function onAiGenerate() {
  if (input.value.trim() === '') {
    _message.info(_t('cardview.ai_gen_tip5'))
    return
  }

  if (props.cardType === CardTypeName.CLOZE) {
    store.aiGenerateClozeCards(input.value).then(() => {
      if (store.aiGenerate.keypoints.length === 0) {
        _message.info(_t('cardview.ai_gen_tip6'))
      }
    })
  } else {
    store.aiGenerateEnwordCards(input.value).then(() => {
      if (store.aiGenerate.enWordTerms.length === 0) {
        _message.info(_t('cardview.ai_gen_tip7'))
      }
    })
  }
}

async function onAiGenerateAgain() {
  if (input.value.trim() === '') {
    _message.info(_t('cardview.ai_gen_tip5'))
    return
  }

  resultContainer.value?.scrollTo({ top: 0, behavior: 'smooth' })

  if (props.cardType === CardTypeName.CLOZE) {
    store.aiReGenerateClozeCards(input.value).then(() => {
      if (store.aiGenerate.keypoints.length === 0) {
        _message.info(_t('cardview.ai_gen_tip6'))
      }
    })
  } else {
    store.aiGenerateEnwordCards(input.value).then(() => {
      if (store.aiGenerate.enWordTerms.length === 0) {
        _message.info(_t('cardview.ai_gen_tip7'))
      }
    })
  }
}

function onCardSelect(index: number) {
  const card = store.aiGenerate.keypoints[index]
  store.selectAiCard(index)

  // 选择卡片后，由于卡片会进入的「已选择」的列表中，所以需要滚动到视口位置
  nextTick(() => {
    document.querySelector(`#keypoint-${card.id}`)?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    })
  })
}

function onEnWordTermChange(index: number, newTerm: AiEnWordTerm) {
  store.updateAiEnWordTerm(index, newTerm)
}

function onEnWordTermFocus(index: number) {
  focusedEnWord.value = index
}

function onCardDelete(index: number) {
  if (props.cardType === CardTypeName.CLOZE) {
    store.removeAiCard(index)
  } else {
    store.removeEnWordCard(index)
  }
}

function onCardUnSelect(index: number) {
  store.unselectAiCard(index)
}

function onAiGenerateCancel() {
  store.abortAiGenerate()
}

async function generateClozeCards() {
  const keypoints =
    store.aiGenerate.selectedKeypoints.length === 0
      ? store.aiGenerate.keypoints
      : store.aiGenerate.selectedKeypoints

  isCardsCreating.value = true
  try {
    const res = await batchCreateCards({
      packageId: props.packageId,
      chapterId: props.chapterId,
      createdType: CardCreatedType.AIGC,
      aigc: {
        outputs: keypoints.map(item => toAiInput(item.content)),
        sessionId: store.aiGenerate.sessionId,
      },
      cards: keypoints.map(item => {
        return {
          type: CardType.CLOZE,
          content: item.content,
        }
      }),
    })
    emit('cardsCreated', res.data.cards)
    emit('done')
    _message.info(_t('cardview.ai_all_cards_added'))
    store.resetAiGenerate()
  } finally {
    isCardsCreating.value = false
  }
}

async function generateEnWordCards() {
  isCardsCreating.value = true
  try {
    const enWordCards: EnWordCard[] = store.aiGenerate.enWordTerms.map(item => {
      return {
        type: CardType.EN_WORD,
        word: item.term,
        definition: item.definition,
        prons: [],
        examples: [],
        tabs: [],
        distrators: [],
      }
    })
    const res = await batchCreateCards({
      packageId: props.packageId,
      chapterId: props.chapterId,
      createdType: CardCreatedType.AIGC,
      aigc: {
        outputs: [],
        sessionId: store.aiGenerate.sessionId,
      },
      cards: enWordCards,
    })
    emit('cardsCreated', res.data.cards)
    emit('done')
    _message.info(_t('cardview.ai_all_cards_added'))
    store.resetAiGenerate()
  } finally {
    isCardsCreating.value = false
  }
}

async function onCardsCreate() {
  if (store.aiGenerate.loading) {
    _confirm({
      scene: 'warn',
      content: _t('cardview.ai_gen_tip8'),
      primaryText: _t('cardview.ai_gen_tip9'),
      secondaryText: _t('cardview.ai_gen_tip10'),
      onSecondaryClick(resolve) {
        resolve(true)
        store.abortAiGenerate()
        generateClozeCards()
      },
    })
    return
  }

  if (props.cardType === CardTypeName.CLOZE) {
    generateClozeCards()
  } else {
    generateEnWordCards()
  }
}

function onAiGenerateClear() {
  input.value = ''
  store.resetAiGenerate()
}

function onInputChange(input: string) {
  store.updateAiContent(input)
}

function onKeypointChange(index: number, newContent: Content) {
  store.updateAiCard(index, newContent)
}

function onSelectedKeypointChange(index: number, newContent: Content) {
  store.updateSelectedAiCard(index, newContent)
}

function onKeypointFocus(id: string) {
  focusedKeypointId.value = id
}
</script>
<style scoped></style>
