import { Code } from '@/api/code'
import {
  createAliPayment,
  pollOrder,
  type Payment_Vendor,
  OrderStatus,
  createWxJsApiPayment,
  createAliPaymentInApp,
} from '@/api/order'
import Alipay from '@/components/Alipay.vue'
import db from '@/db'
import type { OpenDialogPromise } from '@/types/global'
import { Platform } from '@/utils/bridge'
import { wechatRedirect } from '@/utils/wechat'
import type { VoidCallback } from '@rive-app/canvas'

// NOTE
// 1. 该方法内部会对订单进行轮询，所以调用该方法时，需要在页面卸载时注意
// 调用 cancelPoll 清除轮询
// 2. payPromise 结束时不代表支付完成，只是代表「支付动作」完成，此时可以做 loading 相关的操作
// 成功回调通过 onPaySuccess 参数传入
let alipayDialog = null as OpenDialogPromise<void> | null
export function pay(
  orderId: string,
  paymentVendor: Payment_Vendor,
  onPaySuccess: VoidCallback,
  appleProductId?: string
) {
  let pollTimer: number
  function poll(orderId: string) {
    pollOrder(orderId).then(res => {
      if (res.order!.status === OrderStatus.DELIVERED) {
        onPaySuccess()
        alipayDialog?.close()
      } else {
        pollTimer = setTimeout(poll, 1000, orderId)
      }
    })
  }

  function cancelPoll() {
    clearTimeout(pollTimer)
  }

  const payPromise = new Promise<void>(async (resolve, reject) => {
    switch (paymentVendor) {
      case 'ALIPAY_WEB':
        aliPay(orderId)
          .then(() => {
            poll(orderId)
            alipayDialog?.then(resolve)
          })
          .catch(reject)
        break
      case 'ALIPAY_APP':
        aliPayInApp(orderId)
          .then(() => {
            resolve()
            poll(orderId)
          })
          .catch(reject)
        break
      case 'WX_PAY_JSAPI':
        wxJsApiPay(orderId, _db.wxOpenId)
          .then(() => {
            resolve()
            poll(orderId)
          })
          .catch(reject)
        break
      case 'APPLE_PAY':
        if (appleProductId != null) {
          applePay(orderId, appleProductId)
            .then(res => {
              if (!res.ok) {
                const message = res.msg
                reject({
                  message: message ?? _t('pay.failed'),
                })
                return
              }
              resolve()
              poll(orderId)
            })
            .catch(reject)
        } else {
          reject({
            message: _t('pay.msg1'),
          })
        }

        break
    }
  })

  return {
    payPromise,
    cancelPoll,
  }
}

async function aliPay(orderId: string) {
  return new Promise<void>(async (resolve, reject) => {
    const paymentRes = await createAliPayment(orderId)

    if (paymentRes.code !== 0) {
      reject(paymentRes)
      return
    }

    resolve()
    alipayDialog = _openDialog(Alipay, {
      title: _t('pay.msg2'),
      props: {
        form: paymentRes.data.ALIPAY_WEB.form,
      },
    })
  })
}

async function aliPayInApp(orderId: string) {
  return new Promise<void>(async (resolve, reject) => {
    const paymentRes = await createAliPaymentInApp(orderId)

    if (paymentRes.code !== 0) {
      reject(paymentRes)
      return
    }

    const order = paymentRes.data.ALIPAY_APP.sign

    const isAlipayInstalled = await _bridge.isAlipayInstalled()
    if (!isAlipayInstalled) {
      reject({
        message: _t('pay.msg3'),
      })
      return
    }
    const res = await _bridge.aliPay({
      order,
    })
    if (res.resultStatus !== '9000') {
      reject({
        message: res.memo,
      })
      return
    }
    resolve()
  })
}

async function wxJsApiPay(orderId: string, openId: string) {
  return new Promise<void>(async (resolve, reject) => {
    const paymentRes = await createWxJsApiPayment(orderId, openId)

    // 当 openId 失效时，提示用户然后重新获取一次 openId
    // TODO(buding): 这里重新获取回来时只会回到页面级别，需要处理
    // 从弹窗离开的场景
    if (paymentRes.code === Code.WechatOpenIdInvalid) {
      reject(paymentRes)
      wechatRedirect()
      return
    } else if (paymentRes.code !== 0) {
      reject(paymentRes)
      return
    }

    if (typeof WeixinJSBridge == 'undefined') {
      reject({
        message: _t('pay.msg4'),
      })
      return
    }

    const wxReq = paymentRes.data.WX_PAY_JSAPI
    WeixinJSBridge.invoke(
      'getBrandWCPayRequest',
      {
        appId: wxReq.appId,
        timeStamp: wxReq.timeStamp,
        nonceStr: wxReq.nonceStr,
        package: wxReq.packageValue,
        signType: wxReq.signType,
        paySign: wxReq.paySign,
      },
      function (res: any) {
        if (res.err_msg == 'get_brand_wcpay_request:ok') {
          resolve()
        } else if (db.debug.showDebugLabel) {
          reject({
            message: res.err_msg,
          })
        } else {
          resolve()
        }
      }
    )
  })
}

async function applePay(
  orderId: string,
  appleProductId: string
): Promise<{ ok: boolean; msg: string }> {
  return _bridge.iapBuyProduct(appleProductId, orderId)
}

export async function payByEnv(
  orderId: string,
  onPaySuccess: VoidCallback,
  appleProductId?: string
) {
  if (_global.isInsideApp) {
    const platform = (await _bridge.getAppInfo()).platform
    if (platform === Platform.IOS) {
      return pay(orderId, 'APPLE_PAY', onPaySuccess, appleProductId)
    } else {
      return pay(orderId, 'ALIPAY_APP', onPaySuccess)
    }
  } else if (_global.isInsideWechat) {
    return pay(orderId, 'WX_PAY_JSAPI', onPaySuccess)
  } else {
    return pay(orderId, 'ALIPAY_WEB', onPaySuccess)
  }
}
